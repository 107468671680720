import { IComboBoxOption } from "@fluentui/react";
import { strings } from "../../localization/strings";
import { AllowedCurrencies } from "../store/typings/AllowedCurrencies";
import { CurrencyCodes } from "@piceasoft/core";
import { CurrencySigns } from "@piceasoft/core";

export const getLocalizedCurrencyName = (code: CurrencyCodes): string => strings.CURRENCY[code as unknown as AllowedCurrencies] as string

export const prepareCurrencyOptions = (): IComboBoxOption[] => {
    return [{ text: strings.COMMON.NOT_CHOSED, key: 'undefined' }, ...Object.entries(AllowedCurrencies).map(([currencyKey, currencyCode]) => {
        return {
            key: currencyCode,
            text: `${getLocalizedCurrencyName(currencyKey as CurrencyCodes)} - ${CurrencySigns[currencyKey as CurrencyCodes]}`,
        }
    })]
}

export const prepareCurrencyOptionsRequired = (): IComboBoxOption[] => {
    return [...Object.entries(AllowedCurrencies).map(([currencyKey, currencyCode]) => {
        return {
            key: currencyCode,
            text: `${getLocalizedCurrencyName(currencyKey as CurrencyCodes)} - ${CurrencySigns[currencyKey as CurrencyCodes]}`,
        }
    })]
}
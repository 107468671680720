import { getTheme, Icon, MessageBar, MessageBarType, SharedColors, Stack, Text } from "@fluentui/react"
import React from "react"
import {
    ISoftwareConfig, IWorkflow
} from "../../../../core/store"
import { IDiagnosticsConfig } from "../../../../core/store/typings/IDiagnosticsConfig"
import { strings } from "../../../../localization/strings"
import { FollowUpQuestionIds, FollowUpQuestionType, FollowUpResponseIds, SelfServiceRule } from "@piceasoft/core";
import { Tooltip } from "../../../../components/shared/help/Tooltip"
import { getValidtionErrorTitle, ValidationErrors } from "../../../../core/store/typings/ValidationErrors"
import { ISoftwareCheck } from "../../../../core/store/typings/ISoftwarePiceaConfig"
import {
    PhotographicModes, Inspections, ProcessStages, IAnswerOption, IDiagnosticsPiceaConfig, IDiagnosticsPiceaCaseConfig, IDiagnosticsPiceaSetConfig,
    IQuestion, Dependencies, OfferProviderTypes, IGrade, IInspectionModuleConfig,
    IDependencyRule, IInspectionConfig, IPhotographicCollectionConfig, 
    IDataCollectionConfig, IInterviewConfig, IInterviewWithQuestionsConfig, IPhotographicConfig,
    IDiagnosticsPiceaMobileCaseConfig, IDiagnosticsPiceaMobileSetConfig, IdentificationMethods, IConfigErrors,
    IInspectionConfigUI, IIdentificationConfig, IGradesCategory, IIdentificationUniversalConfig
} from "@piceasoft/core"

export const validationModule = (module: IInspectionModuleConfig<IInspectionConfig<IInspectionConfigUI>>, grades: IGrade[], gradesCategories?: IGradesCategory[], withoutGrades?: boolean) => {
    switch (module.type) {
        case Inspections.Interview: return validationInterviewModule(module, grades);
        case Inspections.Photographic: return validationPhotographicModule(module, grades);
        case Inspections.Diagnostics: return validationDiagnosticsModule(module, grades, gradesCategories);
        case Inspections.Software: return validationVerifyModule(module, grades, gradesCategories, withoutGrades);
        case Inspections.DataCollection: return validationDataCollectionEmailAddress(module)
        case Inspections.ManualGrading: return validationManualGrading(module, grades);
    }
    return module
}

export const validationDiagnosticsModule = (module: IInspectionModuleConfig<IInspectionConfig<IInspectionConfigUI>>, grades: IGrade[], gradesCategories?: IGradesCategory[]) => {
    const diagnosticsConfig = (module.config as IDiagnosticsConfig)
    validationDiagnosticFillingModule(diagnosticsConfig)
    validationDiagnosticsModulesetSuccessGrade(diagnosticsConfig, grades)
    const config = diagnosticsConfig.config as IDiagnosticsPiceaConfig
    config.tests.map((test) => {
        test.sets.map((set) => {
            const existingSetsGrade = grades.find((grade) => grade.code === set.grade)
            if (!existingSetsGrade && set.grade !== "" && set.grade) {
                set.grade = '!WASRESET'
                set.errors = []
                set.errors.push({ code: ValidationErrors.GradeWasReset, text: getValidtionErrorTitle(ValidationErrors.GradeWasReset) })
            }
            else if ((existingSetsGrade || set.grade === "" || !set.grade) && set.errors?.find((error) => error.code === ValidationErrors.GradeWasReset) && set.grade !== "!WASRESET") {
                set.errors = set.errors.filter((error) => error.code !== ValidationErrors.GradeWasReset)
            }
            if (set.cases.length > 0) {
                set.cases.map((caseItem) => {
                    if (caseItem.grade && caseItem.grade !== "") {
                        if (caseItem.grade === '!WASRESET') {
                            if (!caseItem.errors) {
                                caseItem.errors = [{ code: ValidationErrors.GradeWasReset, text: getValidtionErrorTitle(ValidationErrors.GradeWasReset) }]
                            } else {
                                if (!caseItem.errors.find((error) => error.code === ValidationErrors.GradeWasReset)) {
                                    caseItem.errors.push({ code: ValidationErrors.GradeWasReset, text: getValidtionErrorTitle(ValidationErrors.GradeWasReset) })

                                }
                            }
                        }
                        else {
                            let existingCasesGrade = undefined
                            if (gradesCategories) {
                                const gradeCategory = caseItem.grade.split('-')
                                const categoryCode = gradeCategory[0]
                                const gradeCode = gradeCategory[1]
                                existingCasesGrade = gradesCategories.find((category) => category.code === categoryCode)?.grades.find((grade) => grade.code === gradeCode)
                            }
                            else {
                                existingCasesGrade = grades.find((grade) => grade.code === caseItem.grade)
                            }
                            if (!existingCasesGrade) {
                                caseItem.grade = '!WASRESET'
                                caseItem.errors = [{ code: ValidationErrors.GradeWasReset, text: getValidtionErrorTitle(ValidationErrors.GradeWasReset) }]
                                if (!set.errors) {
                                    set.errors = [{ code: ValidationErrors.CaseError, text: getValidtionErrorTitle(ValidationErrors.CaseError) }]
                                }
                                else if (set.errors && !set.errors.find((error) => error.code === ValidationErrors.CaseError)) {
                                    set.errors.push({ code: ValidationErrors.CaseError, text: getValidtionErrorTitle(ValidationErrors.CaseError) })
                                }
                            } else if (existingCasesGrade && caseItem.errors) {
                                delete (caseItem.errors)
                            }
                        }

                    }
                    else if ((!caseItem.grade || caseItem.grade === "") && caseItem.errors) {
                        delete (caseItem.errors)
                    }
                })
                if ((!set.cases.find((caseItem) => caseItem.errors) && set.errors?.find((error) => error.code === ValidationErrors.CaseError))) {
                    set.errors = set.errors?.filter((error) => error.code !== ValidationErrors.CaseError)
                }
            }
            else if (set.cases.length === 0 && set.errors?.find((error) => error.code === ValidationErrors.GradeWasReset)) {
                set.errors = set.errors?.filter((error) => error.code !== ValidationErrors.CaseError)
            }

        })
        if (test.sets.find((set) => set.errors && set.errors.length > 0) && !test.errors?.find((error) => error.code === ValidationErrors.SetError)) {
            test.errors = [{ code: ValidationErrors.SetError, text: getValidtionErrorTitle(ValidationErrors.SetError) }]
        }
        else if ((!test.sets.find((set) => set.errors !== undefined && set.errors.length! > 0)) && test.errors?.find((error) => error.code === ValidationErrors.SetError)) {
            test.errors = test.errors.filter((error) => error.code !== ValidationErrors.SetError)
        }
    })
    if (config.tests.find((test) => test.errors?.find((error) => error.code === ValidationErrors.SetError)) && !module.config.errors?.find((error) => error.code === ValidationErrors.TestError)) {
        if (module.config.errors && !module.config.errors.find((error) => error.code === ValidationErrors.TestError)) {
            module.config.errors.push({ code: ValidationErrors.TestError, text: getValidtionErrorTitle(ValidationErrors.TestError) })
        } else {
            module.config.errors = [{ code: ValidationErrors.TestError, text: getValidtionErrorTitle(ValidationErrors.TestError) }]
        }
    }
    if (!config.tests.find((test) => test.errors?.find((error) => error.code === ValidationErrors.SetError)) && module.config.errors && module.config.errors.find((error) => error.code === ValidationErrors.TestError)) {
        module.config.errors = module.config.errors.filter((error) => error.code !== ValidationErrors.TestError)
    }
    return module
}

export const validationDiagnosticsModulesetSuccessGrade = (config: IDiagnosticsConfig, grades: IGrade[]) => {
    const diagnosticsConfig = config.config as IDiagnosticsPiceaConfig
    if (diagnosticsConfig.setSuccessGrade && diagnosticsConfig.setSuccessGrade !== "") {
        const existingGrade = grades.find((grade) => grade.code === diagnosticsConfig.setSuccessGrade)
        if (existingGrade && config.errors?.find((error) => error.code === ValidationErrors.SuccessGradeReset)) {
            config.errors = config.errors.filter((error) => error.code !== ValidationErrors.SuccessGradeReset)
        } if (!existingGrade && !config.errors?.find((error) => error.code === ValidationErrors.SuccessGradeReset)) {
            delete (diagnosticsConfig.setSuccessGrade)
            if (config.errors) {
                config.errors.push({ code: ValidationErrors.SuccessGradeReset, text: getValidtionErrorTitle(ValidationErrors.SuccessGradeReset) })
            } else {
                config.errors = [{ code: ValidationErrors.SuccessGradeReset, text: getValidtionErrorTitle(ValidationErrors.SuccessGradeReset) }]
            }
        }
    }
    else if ((!diagnosticsConfig.setSuccessGrade || diagnosticsConfig.setSuccessGrade === '') && config.errors?.find((error) => error.code === ValidationErrors.SuccessGradeReset)) {
        config.errors = config.errors.filter((error) => error.code !== ValidationErrors.SuccessGradeReset)
    }
    return config
}

export const validationDiagnosticFillingModule = (config: IDiagnosticsConfig) => {
    const diagnosticConfig = config.config as IDiagnosticsPiceaConfig
    if (diagnosticConfig.tests && diagnosticConfig.tests.length > 0) {
        if (config.errors?.find((error) => error.code === ValidationErrors.TestsEmpty)) {
            config.errors = config.errors.filter((error) => error.code !== ValidationErrors.TestsEmpty)
        }
        diagnosticConfig.tests.map((test) => {
            if (test.sets && test.sets.length > 0) {
                if (test.errors?.find((error) => error.code === ValidationErrors.TestSetsEmpty)) {
                    test.errors = test.errors.filter((error) => error.code !== ValidationErrors.TestSetsEmpty)
                }
                if (config.errors?.find((error) => error.code === ValidationErrors.SetsEmpty)) {
                    config.errors = config.errors.filter((error) => error.code !== ValidationErrors.SetsEmpty)
                }
                test.sets.forEach((set) => {
                    if (set.cases && set.cases.length > 0) {
                        if (test.errors?.find((error) => error.code === ValidationErrors.TestSetCasesEmpty)) {
                            test.errors = test.errors.filter((error) => error.code !== ValidationErrors.TestSetCasesEmpty)
                        }
                        if (config.errors?.find((error) => error.code === ValidationErrors.CasesEmpty)) {
                            config.errors = config.errors.filter((error) => error.code !== ValidationErrors.CasesEmpty)
                        }
                    } else {
                        if (!test.errors?.find((error) => error.code === ValidationErrors.TestSetCasesEmpty)) {
                            if (test.errors) {
                                test.errors.push({ code: ValidationErrors.TestSetCasesEmpty, text: getValidtionErrorTitle(ValidationErrors.TestSetCasesEmpty) })
                            }
                            else {
                                test.errors = [{ code: ValidationErrors.TestSetCasesEmpty, text: getValidtionErrorTitle(ValidationErrors.TestSetCasesEmpty) }]
                            }
                        }
                        if (!config.errors?.find((error) => error.code === ValidationErrors.CasesEmpty)) {
                            if (config.errors) {
                                config.errors.push({ code: ValidationErrors.CasesEmpty, text: getValidtionErrorTitle(ValidationErrors.CasesEmpty) })
                            } else {
                                config.errors = [{ code: ValidationErrors.CasesEmpty, text: getValidtionErrorTitle(ValidationErrors.CasesEmpty) }]
                            }
                        }
                    }
                })
            } else if (!test.sets || test.sets.length === 0) {
                if (!test.errors?.find((error) => error.code === ValidationErrors.TestSetsEmpty)) {
                    if (test.errors) {
                        test.errors.push({ code: ValidationErrors.TestSetsEmpty, text: getValidtionErrorTitle(ValidationErrors.TestSetsEmpty) })
                    } else {
                        test.errors = [{ code: ValidationErrors.TestSetsEmpty, text: getValidtionErrorTitle(ValidationErrors.TestSetsEmpty) }]
                    }
                    // console.log(test)
                }
                if (!config.errors?.find((error) => error.code === ValidationErrors.SetsEmpty)) {
                    if (config.errors) {
                        config.errors.push({ code: ValidationErrors.SetsEmpty, text: getValidtionErrorTitle(ValidationErrors.SetsEmpty) })
                    } else {
                        config.errors = [{ code: ValidationErrors.SetsEmpty, text: getValidtionErrorTitle(ValidationErrors.SetsEmpty) }]
                    }
                }
            }
            return test
        })
    } else if (!config.errors?.find((error) => error.code === ValidationErrors.TestsEmpty)) {
        if (config.errors) {
            config.errors.push({ code: ValidationErrors.TestsEmpty, text: getValidtionErrorTitle(ValidationErrors.TestsEmpty) })
        } else {
            config.errors = [{ code: ValidationErrors.TestsEmpty, text: getValidtionErrorTitle(ValidationErrors.TestsEmpty) }]
        }
    }
}

export const validationVerifyModule = (module: IInspectionModuleConfig<IInspectionConfig<IInspectionConfigUI>>, grades: IGrade[], gradesCategories?: IGradesCategory[], withoutGrades?: boolean) => {
    let verifyConfig = (module.config as ISoftwareConfig)
    if (!withoutGrades) {
        validationDownGradeVerifyModule(verifyConfig, grades, gradesCategories)
    }

    validationVerifyFillingModule(verifyConfig)
    if (verifyConfig.config.downGrade && verifyConfig.config.downGrade !== "") {
        const existingGrade = grades.find((grade) => grade.code === verifyConfig.config.downGrade)
        if (!existingGrade) {
            delete (verifyConfig.config.downGrade)
            if (module.config.errors && !module.config.errors.find((error) => error.code === ValidationErrors.VerifyError)) {
                module.config.errors.push({ code: ValidationErrors.VerifyError, text: getValidtionErrorTitle(ValidationErrors.VerifyError) })
            } else if (!module.config.errors) {
                module.config.errors = [{ code: ValidationErrors.VerifyError, text: getValidtionErrorTitle(ValidationErrors.VerifyError) }]
            }


        }
        if (existingGrade && module.config.errors && module.config.errors.find((error) => error.code === ValidationErrors.VerifyError)) {
            module.config.errors = module.config.errors.filter((error) => error.code !== ValidationErrors.VerifyError)
        }
    } else if (!verifyConfig.config.downGrade && module.config.errors?.find((error) => error.code === ValidationErrors.VerifyError)) {
        module.config.errors = module.config.errors.filter((error) => error.code !== ValidationErrors.VerifyError)
    }
    if (verifyConfig.config.checks?.find((check) => check.errors)?.errors?.find((error) => error.code === ValidationErrors.GradeWasReset)
        && !module.config.errors?.find((error) => error.code === ValidationErrors.CheckError)) {
        module.config.errors = module.config.errors ? [...module.config.errors, { code: ValidationErrors.CheckError, text: getValidtionErrorTitle(ValidationErrors.CheckError) }] : [{ code: ValidationErrors.CheckError, text: getValidtionErrorTitle(ValidationErrors.CheckError) }]
    } else if (!verifyConfig.config.checks?.find((check) => check.errors)?.errors?.find((error) => error.code === ValidationErrors.GradeWasReset)
        && module.config.errors?.find((error) => error.code === ValidationErrors.CheckError)) {
        module.config.errors = module.config.errors.filter((error) => error.code !== ValidationErrors.CheckError)
    }
    return module;
}

export const validationVerifyFillingModule = (config: ISoftwareConfig) => {
    if (config.config.checks && config.config.checks.length > 0) {
        if (config.errors?.find((error) => error.code === ValidationErrors.ChecksEmpty)) {
            config.errors = config.errors.filter((error) => error.code !== ValidationErrors.ChecksEmpty)
        }
    }
    else if (!config.errors?.find((error) => error.code === ValidationErrors.ChecksEmpty)) {
        if (config.errors) {
            config.errors.push({ code: ValidationErrors.ChecksEmpty, text: getValidtionErrorTitle(ValidationErrors.ChecksEmpty) })
        } else {
            config.errors = [{ code: ValidationErrors.ChecksEmpty, text: getValidtionErrorTitle(ValidationErrors.ChecksEmpty) }]
        }
    }
}

export const validationDownGradeVerifyModule = (config: ISoftwareConfig, grades: IGrade[], gradesCategories?: IGradesCategory[]) => {
    if (config.config.checks && config.config.checks.length > 0) {
        config.config.checks.map((check) => {
            if (check.grade && check.grade !== '') {
                if (check.grade === "!WASRESET" && !check.errors?.find((error) => error.code === ValidationErrors.GradeWasReset)) {
                    check.errors = check.errors ? [...check.errors, { code: ValidationErrors.GradeWasReset, text: getValidtionErrorTitle(ValidationErrors.GradeWasReset) }] : [{ code: 35, text: 'ошибка' }]
                } else {
                    let existingGrade = undefined
                    if (gradesCategories) {
                        const gradeCategory = check.grade.split('-')
                        const categoryCode = gradeCategory[0]
                        const gradeCode = gradeCategory[1]
                        existingGrade = gradesCategories.find((category) => category.code === categoryCode)?.grades.find((grade) => grade.code === gradeCode)
                    } else {
                        existingGrade = grades.find((grade) => grade.code === check.grade)
                    }
                    if (existingGrade && check.errors?.find((error) => error.code === ValidationErrors.GradeWasReset)) {
                        check.errors = check.errors.filter((error) => error.code !== ValidationErrors.GradeWasReset)
                    } else if (!existingGrade) {
                        check.grade = '!WASRESET'
                        if (!check.errors?.find((error) => error.code === ValidationErrors.GradeWasReset)) {
                            check.errors = check.errors ? [...check.errors, { code: ValidationErrors.GradeWasReset, text: getValidtionErrorTitle(ValidationErrors.GradeWasReset) }] : [{ code: ValidationErrors.GradeWasReset, text: getValidtionErrorTitle(ValidationErrors.GradeWasReset) }]
                        }
                    }
                }
            } else if ((!check.grade || check.grade === '') && check.errors?.find((error) => error.code === ValidationErrors.GradeWasReset)) {
                check.errors = check.errors.filter((error) => error.code !== ValidationErrors.GradeWasReset)
            }
        })
    }

    return config
}

export const validationPhotographicModule = (module: IInspectionModuleConfig<IInspectionConfig<IInspectionConfigUI>>, grades: IGrade[]) => {
    let photograficConfig = (module.config as IPhotographicConfig)
    if (photograficConfig.mode === PhotographicModes.Collection) {
        validationPhotographicCollectionModule(photograficConfig)
    }
    if (photograficConfig.autoApprove && photograficConfig.autoApprove.length > 0) {
        photograficConfig.autoApprove.forEach((autoGrade) => {
            const existingGrade: IGrade | undefined = grades.find((grade) => grade.code === autoGrade)
            if (!existingGrade) {
                const newAutoApproveGrades = photograficConfig.autoApprove?.filter((autoApproveGrade) => autoApproveGrade !== autoGrade)
                if (newAutoApproveGrades?.length === 0) {
                    delete (photograficConfig.autoApprove)
                    if (module.config.errors && !module.config.errors.find((error) => error.code === ValidationErrors.PhotograficError)) {
                        module.config.errors.push({ code: ValidationErrors.PhotograficError, text: getValidtionErrorTitle(ValidationErrors.PhotograficError) })
                    } else if (!module.config.errors) {
                        module.config.errors = [{ code: ValidationErrors.PhotograficError, text: getValidtionErrorTitle(ValidationErrors.PhotograficError) }]
                    }
                } else {
                    photograficConfig.autoApprove = newAutoApproveGrades
                    if (module.config.errors && !module.config.errors.find((error) => error.code === ValidationErrors.PhotograficError)) {
                        module.config.errors.push({ code: ValidationErrors.PhotograficError, text: getValidtionErrorTitle(ValidationErrors.PhotograficError) })
                    } else if (!module.config.errors) {
                        module.config.errors = [{ code: ValidationErrors.PhotograficError, text: getValidtionErrorTitle(ValidationErrors.PhotograficError) }]
                    }
                }

            }
            if (existingGrade && module.config.errors && module.config.errors.find((error) => error.code === ValidationErrors.PhotograficError)) {
                module.config.errors = module.config.errors.filter((error) => error.code !== ValidationErrors.PhotograficError)
            }
        })
    }
    else if ((!photograficConfig.autoApprove || photograficConfig.autoApprove.length === 0) && module.config.errors?.find((error) => error.code === ValidationErrors.PhotograficError)) {
        module.config.errors = module.config.errors.filter((error) => error.code !== ValidationErrors.PhotograficError)
    }
    return module;

}

export const validationPhotographicCollectionModule = (config: IPhotographicConfig) => {
    const configCollection = config.config as IPhotographicCollectionConfig
    if (config.mode !== 0) {
        if ((!configCollection || !configCollection.collection
            || configCollection.collection.length === 0)
            && !config.errors?.find((error) => error.code === ValidationErrors.CollectionEmpty)) {
            if (config.errors) {
                config.errors.push({ code: ValidationErrors.CollectionEmpty, text: getValidtionErrorTitle(ValidationErrors.CollectionEmpty) })
            } else {
                config.errors = [{ code: ValidationErrors.CollectionEmpty, text: getValidtionErrorTitle(ValidationErrors.CollectionEmpty) }]
            }
        } else if (configCollection.collection
            && configCollection.collection.length > 0
            && config.errors?.find((error) => error.code === ValidationErrors.CollectionEmpty
            )
        ) {
            config.errors = config.errors.filter((error) => error.code !== ValidationErrors.CollectionEmpty)
        }
    } else if (config.mode === 0 && config.errors?.find((error) => error.code === ValidationErrors.CollectionEmpty)) {
        config.errors = config.errors.filter((error) => error.code !== ValidationErrors.CollectionEmpty)
    }

    return config
}

export const validationInterviewModule = (module: IInspectionModuleConfig<IInspectionConfig<IInspectionConfigUI>>, grades: IGrade[]) => {
    const interviewConfig = (module.config as IInterviewConfig)
    validationFillingInterviewModule(interviewConfig)
    const config = interviewConfig.config as IInterviewWithQuestionsConfig
    const questions = config.questions
    questions.map((question) => {
        const answers = question.answers
        answers.map((answer) => {
            if (answer.grade && answer.grade !== "") {
                if (answer.grade === '!WASRESET') {
                    if (!answer.errors?.find((error) => error.code === ValidationErrors.GradeWasReset)) {
                        if (answer.errors) {
                            answer.errors.push({ code: ValidationErrors.GradeWasReset, text: getValidtionErrorTitle(ValidationErrors.GradeWasReset) })
                        } else {
                            answer.errors = [{ code: ValidationErrors.GradeWasReset, text: getValidtionErrorTitle(ValidationErrors.GradeWasReset) }]
                        }
                    } else {
                        delete (answer.grade)
                    }
                } else {
                    const existingGrade = grades.find((grade) => grade.code === answer.grade)
                    if (!existingGrade) {
                        answer.errors = [{ code: ValidationErrors.GradeWasReset, text: getValidtionErrorTitle(ValidationErrors.GradeWasReset) }]
                        answer.grade = "!WASRESET"
                    } else if (existingGrade && answer.errors?.find((error) => error.code === ValidationErrors.GradeWasReset)) {
                        answer.errors = answer.errors.filter((error) => error.code !== ValidationErrors.GradeWasReset)
                    }
                }
            } else if (answer.grade === '' && answer.errors?.find((error) => error.code === ValidationErrors.GradeWasReset)) {
                answer.errors = answer.errors.filter((error) => error.code !== ValidationErrors.GradeWasReset)
            }
            if (answer.grade === '!WASRESET' && answer.errors?.find((error) => error.code === ValidationErrors.GradeWasReset)) {
                delete (answer.grade)
            }
            if (answer.next) {
                if (answer.next && answer.next !== 0) {
                    const existingNextQuestion = config.questions.find((question) => question.index === answer.next)
                    if (!existingNextQuestion) {
                        answer.next = 0
                        console.log(answer)
                        if (!answer.errors?.find((error) => error.code === ValidationErrors.NextQuestionReset)) {
                            if (answer.errors) {
                                answer.errors.push({ code: ValidationErrors.NextQuestionReset, text: getValidtionErrorTitle(ValidationErrors.NextQuestionReset) })
                            } else {
                                answer.errors = [{ code: ValidationErrors.NextQuestionReset, text: getValidtionErrorTitle(ValidationErrors.NextQuestionReset) }]
                            }
                        }
                    } else {
                        if (answer.errors?.find((error) => error.code === ValidationErrors.NextQuestionReset)) {
                            answer.errors = answer.errors.filter((error) => error.code !== ValidationErrors.NextQuestionReset)
                        }
                    }
                }
            } else if (answer.next === 0 && !answer.errors?.find((error) => error.code === ValidationErrors.NextQuestionReset)) {
                if (answer.errors) {
                    answer.errors.push({ code: ValidationErrors.NextQuestionReset, text: getValidtionErrorTitle(ValidationErrors.NextQuestionReset) })
                } else {
                    answer.errors = [{ code: ValidationErrors.NextQuestionReset, text: getValidtionErrorTitle(ValidationErrors.NextQuestionReset) }]
                }
            }
            else if (!answer.next && answer.next !== 0 && answer.errors?.find((error) => error.code === ValidationErrors.NextQuestionReset)) {
                answer.errors = answer.errors.filter((error) => error.code !== ValidationErrors.NextQuestionReset)
            }
            return answer
        }
        )
        if (question.answers.find((answer) => answer.errors && answer.errors.length > 0)) {
            question.errors = [{ code: ValidationErrors.AnswerError, text: getValidtionErrorTitle(ValidationErrors.AnswerError) }]
        }
        else if (!question.answers.find((answer) => answer.errors && answer.errors.length > 0) && question.errors?.find((error) => error.code === ValidationErrors.AnswerError)) {
            question.errors = question.errors.filter((error) => error.code !== ValidationErrors.AnswerError)
        }
    })
    if (questions.find((question) => question.errors?.find((error) => error.code === ValidationErrors.AnswerError) && !module.config.errors?.find((error) => error.code === ValidationErrors.QuestionError))) {
        if (module.config.errors && !module.config.errors.find((error) => error.code === ValidationErrors.QuestionError)) {
            module.config.errors.push({ code: ValidationErrors.QuestionError, text: getValidtionErrorTitle(ValidationErrors.QuestionError) })
        } else if (!module.config.errors) {
            module.config.errors = [{ code: ValidationErrors.QuestionError, text: getValidtionErrorTitle(ValidationErrors.QuestionError) }]
        }
    } else if (!questions.find((question) => question.errors && question.errors.length > 0) && module.config.errors?.find((error) => error.code === ValidationErrors.QuestionError)) {
        module.config.errors = module.config.errors.filter((error) => error.code !== ValidationErrors.QuestionError)
    }
    return module
}

export const validationFillingInterviewModule = (config: IInterviewConfig) => {
    const configInterview = config.config as IInterviewWithQuestionsConfig
    if (configInterview.questions && configInterview.questions.length > 0) {
        if (config.errors?.find((error) => error.code === ValidationErrors.QuestionsEmpty)) {
            config.errors = config.errors.filter((error) => error.code !== ValidationErrors.QuestionsEmpty)
        }

        let isQuestionTitleEmpty = false;
        let isAnswersEmpty = false;
        
        configInterview.questions.map((question) => {
            if (question.errors?.find((error) => error.code === ValidationErrors.QuestionsTitleEmpty) && question.title.length > 0) {
                question.errors = question.errors.filter((error) => error.code !== ValidationErrors.QuestionsTitleEmpty)
            } 

            if (!question.title || question.title.length === 0) {
                if(!question.errors?.find((error) => error.code === ValidationErrors.QuestionsTitleEmpty)) {
                    if(question.errors){
                        question.errors.push({ code: ValidationErrors.QuestionsTitleEmpty, text: getValidtionErrorTitle(ValidationErrors.QuestionsTitleEmpty)})
                    } else {
                        question.errors = [{ code: ValidationErrors.QuestionsTitleEmpty, text: getValidtionErrorTitle(ValidationErrors.QuestionsTitleEmpty) }]
                    }
                }
                if(!config.errors?.find((error) => error.code === ValidationErrors.QuestionsTitleEmpty)) {
                    if(config.errors) {
                        config.errors.push({ code: ValidationErrors.QuestionsTitleEmpty, text: getValidtionErrorTitle(ValidationErrors.QuestionsTitleEmpty)})
                    } else {
                        config.errors =[{ code: ValidationErrors.QuestionsTitleEmpty, text: getValidtionErrorTitle(ValidationErrors.QuestionsTitleEmpty)}]
                    }
                }

                isQuestionTitleEmpty = true;
            }
            if (question.errors?.find((error) => error.code === ValidationErrors.QuestionAnswersEmpty) && question.answers || question.answers.length > 0) {
                    question.errors = question.errors?.filter((error) => error.code !== ValidationErrors.QuestionAnswersEmpty)
                }
            if (!question.answers || question.answers.length === 0) {
                if (!question.errors?.find((error) => error.code === ValidationErrors.QuestionAnswersEmpty)) {
                    if (question.errors) {
                        question.errors.push({ code: ValidationErrors.QuestionAnswersEmpty, text: getValidtionErrorTitle(ValidationErrors.QuestionAnswersEmpty) })
                    } else {
                        question.errors = [{ code: ValidationErrors.QuestionAnswersEmpty, text: getValidtionErrorTitle(ValidationErrors.QuestionAnswersEmpty) }]
                    }
                }
                if (!config.errors?.find((error) => error.code === ValidationErrors.AnswersEmpty)) {
                    if (config.errors) {
                        config.errors.push({ code: ValidationErrors.AnswersEmpty, text: getValidtionErrorTitle(ValidationErrors.AnswersEmpty) })
                    } else {
                        config.errors = [{ code: ValidationErrors.AnswersEmpty, text: getValidtionErrorTitle(ValidationErrors.AnswersEmpty) }]
                    }
                }

                isAnswersEmpty = true;
            }
            return question
        })

        if(!isQuestionTitleEmpty) {
            if(config.errors?.find((error) => error.code === ValidationErrors.QuestionsTitleEmpty)) {
                config.errors = config.errors?.filter((error) => error.code !== ValidationErrors.QuestionsTitleEmpty)
            }
        }
        if(!isAnswersEmpty) {
            if(config.errors?.find((error) => error.code === ValidationErrors.AnswersEmpty)) {
                config.errors = config.errors?.filter((error) => error.code !== ValidationErrors.AnswersEmpty)
            }
        }

    } else if (!config.errors?.find((error) => error.code === ValidationErrors.QuestionsEmpty)) {
        if (config.errors) {
            config.errors.push({ code: ValidationErrors.QuestionsEmpty, text: getValidtionErrorTitle(ValidationErrors.QuestionsEmpty) })
        } else {
            config.errors = [{ code: ValidationErrors.QuestionsEmpty, text: getValidtionErrorTitle(ValidationErrors.QuestionsEmpty) }]
        }

        if(configInterview.questions.length === 0) {
            config.errors = config.errors?.filter((error) => error.code !== ValidationErrors.QuestionsTitleEmpty && error.code !== ValidationErrors.AnswersEmpty)
        }
    }
    return config
}

export const validationSkips = (moduleSkip: IDependencyRule, workflow: IWorkflow) => {
    if (moduleSkip.and) {
        validationSkips(moduleSkip.and, workflow)
        if (moduleSkip.and.errors && moduleSkip.and.errors.length > 0) {
            if (moduleSkip.errors && !moduleSkip.errors.find((error) => error.code === ValidationErrors.GradeWasReset)) {
                moduleSkip.errors.push({ code: ValidationErrors.SkipError, text: getValidtionErrorTitle(ValidationErrors.SkipError) })
            } else {
                moduleSkip.errors = []
                moduleSkip.errors.push({ code: ValidationErrors.SkipError, text: getValidtionErrorTitle(ValidationErrors.SkipError) })
            }
        } else if ((!moduleSkip.and.errors || moduleSkip.and.errors.length === 0) && moduleSkip.errors?.find((error) => error.code === ValidationErrors.SkipError)) {
            moduleSkip.errors = moduleSkip.errors.filter((error) => error.code !== ValidationErrors.SkipError)
        }
    }
    if (moduleSkip.or) {
        validationSkips(moduleSkip.or, workflow)
        if (moduleSkip.or.errors && moduleSkip.or.errors.length > 0) {
            if (moduleSkip.errors) {
                moduleSkip.errors.push({ code: ValidationErrors.SkipError, text: getValidtionErrorTitle(ValidationErrors.SkipError) })
            } else {
                moduleSkip.errors = []
                moduleSkip.errors.push({ code: ValidationErrors.SkipError, text: getValidtionErrorTitle(ValidationErrors.SkipError) })
            }
        } else if ((!moduleSkip.or.errors || moduleSkip.or.errors.length === 0) && moduleSkip.errors?.find((error) => error.code === ValidationErrors.SkipError)) {
            moduleSkip.errors = moduleSkip.errors.filter((error) => error.code !== ValidationErrors.SkipError)
        }
    }
    switch (moduleSkip.dependency) {

        case Dependencies.ASSESSMENT_GRADE: return validationSkipsAssessmentGrade(moduleSkip, workflow.grades, workflow.gradesCategories);
        case Dependencies.ASSESSMENT_GRADE_INDEX: return validationSkipsAssessmentGradeIndex(moduleSkip, workflow.grades, workflow.temporaryData?.previousGrades, workflow.gradesCategories, workflow.temporaryData?.previousGradesCategories);
        case Dependencies.IDENTIFICATION_METHOD: return validationSkipsIdentificationMethod(moduleSkip, workflow.identification)
    }
}

export const validationSkipsAssessmentGrade = (moduleSkip: IDependencyRule, grades: IGrade[], gradesCategories?: IGradesCategory[]) => {
    if (moduleSkip.value) {
        if (moduleSkip.value === '!WASRESET') {
            if (!moduleSkip.errors?.find((error) => error.code === ValidationErrors.GradeWasReset)) {
                if (moduleSkip.errors) {
                    moduleSkip.errors.push({ code: ValidationErrors.GradeWasReset, text: getValidtionErrorTitle(ValidationErrors.GradeWasReset) })
                } else {
                    moduleSkip.errors = [{ code: ValidationErrors.GradeWasReset, text: getValidtionErrorTitle(ValidationErrors.GradeWasReset) }]
                }
            }
        } else {
            let existingGrade = undefined
            if (gradesCategories) {
                const gradeCategory = moduleSkip.value.split('-')
                const categoryCode = gradeCategory[0]
                const gradeCode = gradeCategory[1]
                existingGrade = gradesCategories.find((category) => category.code === categoryCode)?.grades.find((grade) => grade.code === gradeCode)
            } else {
                existingGrade = grades.find((grade) => grade.code === moduleSkip.value)
            }
            if (!existingGrade && !moduleSkip.errors?.find((error) => error.code === ValidationErrors.GradeWasReset)) {
                if (moduleSkip.errors) {
                    moduleSkip.errors.push({ code: ValidationErrors.GradeWasReset, text: getValidtionErrorTitle(ValidationErrors.GradeWasReset) })

                } else {
                    moduleSkip.errors = [{ code: ValidationErrors.GradeWasReset, text: getValidtionErrorTitle(ValidationErrors.GradeWasReset) }]
                }
                moduleSkip.value = '!WASRESET'
            }
            else if (existingGrade && moduleSkip.errors?.find((error) => error.code === ValidationErrors.GradeWasReset)) {
                moduleSkip.errors = moduleSkip.errors.filter((error) => error.code !== ValidationErrors.GradeWasReset)
            }
        }
    }
    return moduleSkip
}

export const validationSkipsAssessmentGradeIndex = (moduleSkip: IDependencyRule, grades: IGrade[], previousGrades?: IGrade[], gradesCategories?: IGradesCategory[], previousGradesCategories?: IGradesCategory[]) => {
    if (moduleSkip.value) {
        if (moduleSkip.value === '!WASRESET') {
            if (!moduleSkip.errors?.find((error) => error.code === ValidationErrors.GradeWasReset)) {
                if (moduleSkip.errors) {
                    moduleSkip.errors.push({ code: ValidationErrors.GradeWasReset, text: getValidtionErrorTitle(ValidationErrors.GradeWasReset) })
                } else {
                    moduleSkip.errors = [{ code: ValidationErrors.GradeWasReset, text: getValidtionErrorTitle(ValidationErrors.GradeWasReset) }]
                }
            }
        } else {
            let existingGrade = undefined
            if (gradesCategories) {
                const gradeCategory = moduleSkip.value.split('-')
                const categoryCode = gradeCategory[0]
                const gradeIndex = gradeCategory[1]
                const existingPreviouesGrade = previousGradesCategories?.find((category) => category === categoryCode)?.grades.find((grade) => grade.index == gradeIndex)
                if (existingPreviouesGrade) {
                    existingGrade = gradesCategories.find((category) => category.code === categoryCode)?.grades.find((grade) => grade.code === existingPreviouesGrade?.code)
                    if (existingGrade && existingGrade.index !== existingPreviouesGrade?.index) {
                        moduleSkip.value = ''
                        if (moduleSkip.errors) {
                            moduleSkip.errors.push({ code: ValidationErrors.GradeIndexChanged, text: getValidtionErrorTitle(ValidationErrors.GradeIndexChanged) })
                        } else {
                            moduleSkip.errors = [{ code: ValidationErrors.GradeIndexChanged, text: getValidtionErrorTitle(ValidationErrors.GradeIndexChanged) }]
                        }
                    } else if (existingGrade && existingGrade.index == existingPreviouesGrade?.index && moduleSkip.errors?.find((error) => error.code === ValidationErrors.GradeIndexChanged)) {
                        moduleSkip.errors = moduleSkip.errors.filter((error) => error.code !== ValidationErrors.GradeIndexChanged)

                    }
                } else {
                    existingGrade = gradesCategories.find((category) => category.code === categoryCode)?.grades.find((grade) => grade.index == gradeIndex)
                }
            } else {
                if (previousGrades && previousGrades.length > 0) {
                    const existingPreviouesGrade = previousGrades.find((pgrade) => pgrade.index == moduleSkip.value)
                    existingGrade = grades.find((grade) => grade.code === existingPreviouesGrade?.code)
                    if (existingGrade && existingGrade.index !== existingPreviouesGrade?.index) {
                        moduleSkip.value = ''
                        if (moduleSkip.errors) {
                            moduleSkip.errors.push({ code: ValidationErrors.GradeIndexChanged, text: getValidtionErrorTitle(ValidationErrors.GradeIndexChanged) })
                        } else {
                            moduleSkip.errors = [{ code: ValidationErrors.GradeIndexChanged, text: getValidtionErrorTitle(ValidationErrors.GradeIndexChanged) }]
                        }
                    } else if (existingGrade && existingGrade.index == existingPreviouesGrade?.index && moduleSkip.errors?.find((error) => error.code === ValidationErrors.GradeIndexChanged)) {
                        moduleSkip.errors = moduleSkip.errors.filter((error) => error.code !== ValidationErrors.GradeIndexChanged)
                    }
                }
                else {
                    existingGrade = grades.find((grade) => grade.index == moduleSkip.value)
                }
            }
            if (!existingGrade && !moduleSkip.errors?.find((error) => error.code === ValidationErrors.GradeWasReset)) {
                if (moduleSkip.errors) {
                    moduleSkip.errors.push({ code: ValidationErrors.GradeWasReset, text: getValidtionErrorTitle(ValidationErrors.GradeWasReset) })

                } else {
                    moduleSkip.errors = [{ code: ValidationErrors.GradeWasReset, text: getValidtionErrorTitle(ValidationErrors.GradeWasReset) }]
                }
                moduleSkip.value = '!WASRESET'
            }
            else if (existingGrade && moduleSkip.errors?.find((error) => error.code === ValidationErrors.GradeWasReset)) {
                moduleSkip.errors = moduleSkip.errors.filter((error) => error.code !== ValidationErrors.GradeWasReset)
            }
        }
    }
    return moduleSkip
}

export const validationSkipsIdentificationMethod = (moduleSkip: IDependencyRule, identification: IIdentificationConfig) => {
    if (moduleSkip.value) {
        const existingMethod = identification.items.find((item) => item.method == moduleSkip.value)
        if (!existingMethod) {
            moduleSkip.value = '!WASRESET'
            if (!moduleSkip.errors?.find((error) => error.code === ValidationErrors.IdentificationMethodReset)) {
                if (moduleSkip.errors) {
                    moduleSkip.errors.push({ code: ValidationErrors.IdentificationMethodReset, text: getValidtionErrorTitle(ValidationErrors.IdentificationMethodReset) })
                } else {
                    moduleSkip.errors = [{ code: ValidationErrors.IdentificationMethodReset, text: getValidtionErrorTitle(ValidationErrors.IdentificationMethodReset) }]
                }
            }
        } else {
            if (moduleSkip.errors?.find((error) => error.code === ValidationErrors.IdentificationMethodReset)) {
                moduleSkip.errors = moduleSkip.errors.filter((error) => error.code !== ValidationErrors.IdentificationMethodReset)
            }
        }
    }

}

export const validationModulesWithUseGradesCategories = (module: IInspectionModuleConfig<IInspectionConfig<IInspectionConfigUI>>, gradesCategories?: IGradesCategory[], withoutGrades?: boolean) => {
    let grades: IGrade[] = []
    if (module.config.gradesCategory) {
        if (module.config.errors?.find((error) => error.code === ValidationErrors.CategoryNotSelected)) {
            module.config.errors = module.config.errors.filter((error) => error.code !== ValidationErrors.CategoryNotSelected)
        }
        if (gradesCategories) {
            const currentGradesCategory = gradesCategories.find((category) => category.code === module.config.gradesCategory)
            console.log(currentGradesCategory)
            if (currentGradesCategory) {
                if (module.config.errors?.find((error) => error.code === ValidationErrors.CategoryWasReset)) {
                    module.config.errors = module.config.errors?.filter((error) => error.code !== ValidationErrors.CategoryWasReset)
                }
                grades = currentGradesCategory.grades
                return validationModule(module, grades, gradesCategories, withoutGrades)
            }
            else {
                if (module.config.errors && !module.config.errors.find((error) => error.code === ValidationErrors.CategoryWasReset)) {
                    module.config.errors.push({ code: ValidationErrors.CategoryWasReset, text: strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.GRADE_CATEGORY_WAS_RESET })
                } else if (!module.config.errors) {
                    module.config.errors = [{ code: ValidationErrors.CategoryWasReset, text: strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.GRADE_CATEGORY_WAS_RESET }]
                }
                module.config.gradesCategory = "!WASRESET"
                return validationModule(module, grades, gradesCategories, withoutGrades)
            }
        } else {
            if (module.config.errors && !module.config.errors.find((error) => error.code === ValidationErrors.CategoryWasReset)) {
                module.config.errors.push({ code: ValidationErrors.CategoryWasReset, text: getValidtionErrorTitle(ValidationErrors.CategoryWasReset) })
            } else if (!module.config.errors) {
                module.config.errors = [{ code: ValidationErrors.CategoryWasReset, text: getValidtionErrorTitle(ValidationErrors.CategoryWasReset) }]
            }
            return validationModule(module, grades, undefined, withoutGrades)
        }

    }
    else {
        if (!withoutGrades) {
            if (module.config.errors && !module.config.errors.find((error) => error.code === ValidationErrors.CategoryNotSelected)) {
                module.config.errors.push({ code: ValidationErrors.CategoryNotSelected, text: getValidtionErrorTitle(ValidationErrors.CategoryNotSelected) })
            } else if (!module.config.errors) {
                module.config.errors = [{ code: ValidationErrors.CategoryNotSelected, text: getValidtionErrorTitle(ValidationErrors.CategoryNotSelected) }]
            }
        }
        return validationModule(module, grades, gradesCategories, withoutGrades)
    }
}

export const validationModulesWithOutUseGradesCategories = (module: IInspectionModuleConfig<IInspectionConfig<IInspectionConfigUI>>, grades: IGrade[], withoutGrades?: boolean) => {
    if (module.config.errors?.find((error) => error.code === ValidationErrors.CategoryWasReset || error.code === ValidationErrors.CategoryNotSelected)) {
        module.config.errors = module.config.errors.filter((error) => error.code !== ValidationErrors.CategoryWasReset).filter((error) => error.code !== ValidationErrors.CategoryNotSelected)
    }
    delete (module.config.gradesCategory)
    return validationModule(module, grades, undefined, withoutGrades)
}

export const validationOfEmptyAssessment = (config: IWorkflow) => {
    if ((config.assessment?.modules && config.assessment?.modules.length === 0) && (!config.assessment.errors || config.assessment.errors.length === 0)) {
        console.log(config)
        if (config.assessment.errors) {
            config.assessment.errors.push({ code: ValidationErrors.AssessmentEmpty, text: getValidtionErrorTitle(ValidationErrors.AssessmentEmpty) })
        } else {
            config.assessment.errors = [{ code: ValidationErrors.AssessmentEmpty, text: getValidtionErrorTitle(ValidationErrors.AssessmentEmpty) }]
        }

    } if ((config.assessment?.modules && config.assessment?.modules.length > 0) && config.assessment.errors?.find((error) => error.code === ValidationErrors.AssessmentEmpty)) {
        config.assessment.errors = config.assessment.errors.filter((error) => error.code !== ValidationErrors.AssessmentEmpty)
    }
    return config
}

export const validationOfEmptyIdentification = (config: IWorkflow) => {
    if ((config.identification.items && config.identification.items.length === 0 && !config.identification.errors?.find((error) => error.code === ValidationErrors.IdentificationEmpty))) {
        if (config.identification.errors) {
            config.identification.errors.push({ code: ValidationErrors.IdentificationEmpty, text: getValidtionErrorTitle(ValidationErrors.IdentificationEmpty) })
        } else {
            config.identification.errors = [{ code: ValidationErrors.IdentificationEmpty, text: getValidtionErrorTitle(ValidationErrors.IdentificationEmpty) }]
        }
    }
    else if (config.identification.items && config.identification.items.length > 0 && config.identification.errors?.find((error) => error.code === ValidationErrors.IdentificationEmpty)) {
        config.identification.errors = config.identification.errors.filter((error) => error.code !== ValidationErrors.IdentificationEmpty)
    }
}

export const validateSerchInWorkflowCatalog = (workflow: IWorkflow) => {  
    if(!workflow.catalogId && workflow?.identification?.items && workflow.identification.items.find(o => o.method == IdentificationMethods.SearchForm)){
        if(!workflow.errors?.find((error) => error.code === ValidationErrors.NotSetCatalog)){
            if (workflow.errors) {
                workflow.errors.push({ code: ValidationErrors.NotSetCatalog, text: getValidtionErrorTitle(ValidationErrors.NotSetCatalog) })
            } else {
                workflow.errors = [{ code: ValidationErrors.NotSetCatalog, text: getValidtionErrorTitle(ValidationErrors.NotSetCatalog) }]
            }
        }        
    }
    else if (workflow.errors && workflow.errors?.find((error) => error.code === ValidationErrors.NotSetCatalog)) {
        workflow.errors = workflow.errors.filter((error) => error.code !== ValidationErrors.NotSetCatalog)
    }
}

export const validateCustomSearch = (workflow: IWorkflow) => {
    if(workflow?.identification?.items && workflow.identification.items.find(o => o.method === IdentificationMethods.Universal)) {
        const customSearch = workflow.identification.items.find(o => o.method === IdentificationMethods.Universal) as IIdentificationUniversalConfig
        const isUISettingsEnabled = (customSearch.useConfiguration || customSearch.useGroup || customSearch.useImei || 
            customSearch.useManufacturer || customSearch.useModel || customSearch.useSerial) ? true : false;

        if(!isUISettingsEnabled) {
            if(!workflow.errors?.find((error) => error.code === ValidationErrors.NoUISettingsEnabled)) {
                if(workflow.errors) {
                    workflow.errors.push({ code: ValidationErrors.NoUISettingsEnabled, text: getValidtionErrorTitle(ValidationErrors.NoUISettingsEnabled) })
                } else {
                    workflow.errors = [{ code: ValidationErrors.NoUISettingsEnabled, text: getValidtionErrorTitle(ValidationErrors.NoUISettingsEnabled) }]
                }
            }
        } else if (workflow.errors && workflow.errors.find((error) => error.code === ValidationErrors.NoUISettingsEnabled)) {
            workflow.errors = workflow.errors.filter((error) => error.code !== ValidationErrors.NoUISettingsEnabled)
        }
    } else if (workflow.errors && workflow.errors.find((error) => error.code === ValidationErrors.NoUISettingsEnabled)) {
        workflow.errors = workflow.errors.filter((error) => error.code !== ValidationErrors.NoUISettingsEnabled)
    }
}

export const validationOfEmptyControl = (config: IWorkflow) => {
    if ((config.control?.modules && config.control?.modules?.length === 0) && (!config.control.errors || config.control.errors.length === 0)) {
        if (config.control.errors) {
            config.control.errors.push({ code: ValidationErrors.ControlEmpty, text: getValidtionErrorTitle(ValidationErrors.ControlEmpty) })
        } else {
            config.control.errors = [{ code: ValidationErrors.ControlEmpty, text: getValidtionErrorTitle(ValidationErrors.ControlEmpty) }]
        }

    } if ((config.control?.modules && config.control?.modules?.length > 0) && config.control.errors?.find((error) => error.code === ValidationErrors.ControlEmpty)) {
        config.control.errors = config.control.errors.filter((error) => error.code !== ValidationErrors.ControlEmpty)
    }
    return config
}

export const checkSkipsErrors = (config: IWorkflow) => {
    config.stages?.forEach((stage) => {
        switch (stage.type) {
            case ProcessStages.Assessment:
                config.assessment?.modules?.map((module) => {
                    module.config.skip?.map((skipElement) => {
                        return validationSkips(skipElement, config)
                    })
                    validationSkipsModule(module)
                    if (config.assessment?.disabled) {
                        delete (module.config.errors)
                    } else {
                        if (
                            (module.config.skip?.find((skipElem) => skipElem.errors && skipElem.errors.length > 0))
                            && !module.config.errors?.find((error) => error.code === ValidationErrors.AnswerError)
                        ) {
                            if (module.config.errors && !module.config.errors.find((error) => error.code === ValidationErrors.SkipError)) {
                                module.config.errors.push({ code: ValidationErrors.SkipError, text: getValidtionErrorTitle(ValidationErrors.SkipError) })
                            } else {
                                module.config.errors = []
                                module.config.errors.push({ code: ValidationErrors.SkipError, text: getValidtionErrorTitle(ValidationErrors.SkipError) })
                            }
                        } else if ((!module.config.skip?.find((skipElem) => skipElem.errors && skipElem.errors.length > 0)) && module.config.errors?.find((error) => error.code === ValidationErrors.SkipError)) {
                            module.config.errors = module.config.errors.filter((error) => error.code !== ValidationErrors.SkipError)
                        }
                    }
                    return module
                })
                return config
            case ProcessStages.Control:
                config.control?.modules?.map((module) => {
                    module.config.skip?.map((skipElement) => {
                        return validationSkips(skipElement, config)
                    })
                    validationSkipsModule(module)
                    if (config.control?.disabled) {
                        delete (module.config.errors)
                    } else {
                        if (
                            (module.config.skip?.find((skipElem) => skipElem.errors && skipElem.errors.length > 0))
                            && !module.config.errors?.find((error) => error.code === ValidationErrors.AnswerError)
                        ) {
                            if (module.config.errors && !module.config.errors.find((error) => error.code === ValidationErrors.SkipError)) {
                                module.config.errors.push({ code: ValidationErrors.SkipError, text: getValidtionErrorTitle(ValidationErrors.SkipError) })
                            } else {
                                module.config.errors = []
                                module.config.errors.push({ code: ValidationErrors.SkipError, text: getValidtionErrorTitle(ValidationErrors.SkipError) })
                            }
                        } else if ((!module.config.skip?.find((skipElem) => skipElem.errors && skipElem.errors.length > 0)) && module.config.errors?.find((error) => error.code === ValidationErrors.SkipError)) {
                            module.config.errors = module.config.errors.filter((error) => error.code !== ValidationErrors.SkipError)
                        }
                    }
                    return module
                })
                return config
        }
    })
    return config
}

export const validationSkipsModule = (module: IInspectionModuleConfig<IInspectionConfig<IInspectionConfigUI>>) => {
    if (
        (module.config.skip?.find((skipElem) => skipElem.errors && skipElem.errors.length > 0))
        && !module.config.errors?.find((error) => error.code === ValidationErrors.AnswerError)
    ) {
        if (module.config.errors && !module.config.errors.find((error) => error.code === ValidationErrors.SkipError)) {
            module.config.errors.push({ code: ValidationErrors.SkipError, text: getValidtionErrorTitle(ValidationErrors.SkipError) })
        } else {
            module.config.errors = []
            module.config.errors.push({ code: ValidationErrors.SkipError, text: getValidtionErrorTitle(ValidationErrors.SkipError) })
        }
    } else if ((!module.config.skip?.find((skipElem) => skipElem.errors && skipElem.errors.length > 0)) && module.config.errors?.find((error) => error.code === ValidationErrors.SkipError)) {
        module.config.errors = module.config.errors.filter((error) => error.code !== ValidationErrors.SkipError)
    }
    return module
}

export const validationPreOfferDiscounts = (config: IWorkflow) => {
    if (!config.preOffer || config.preOffer?.disabled) {
        delete (config.preOffer?.errors)
        config.errors = config.errors?.filter((error) => error.code !== ValidationErrors.PreOfferDiscounts)
        return config
    }
    if (!config.postOffer || config.postOffer.disabled) {
        if (config.commonOffer?.promoProviders && config.commonOffer.promoProviders.length > 0) {
            if (config.commonOffer.allowDiscount && !config.preOffer.errors?.find((error) => error.code === ValidationErrors.PreOfferDiscounts)) {
                config.preOffer.errors = [{ code: ValidationErrors.PreOfferDiscounts, text: getValidtionErrorTitle(ValidationErrors.PreOfferDiscounts) }]
            }
        }
        if (config.commonOffer?.providers && config.commonOffer.providers.length > 0 && !config.errors?.find((error) => error.code === ValidationErrors.PreOfferDiscounts)) {
            config.preOffer.errors = [{ code: ValidationErrors.PreOfferDiscounts, text: getValidtionErrorTitle(ValidationErrors.PreOfferDiscounts) }]
        }
        if ((!config.commonOffer?.promoProviders || config.commonOffer.promoProviders.length === 0 || !config.commonOffer?.allowDiscount) && (!config.commonOffer?.providers || config.commonOffer.providers?.length === 0) && config.preOffer.errors?.find((error) => error.code === ValidationErrors.PreOfferDiscounts)) {
            config.preOffer.errors = config.preOffer.errors.filter((error) => error.code !== ValidationErrors.PreOfferDiscounts)
        }
    } if (config.postOffer && !config.postOffer.disabled && config.preOffer.errors?.find((error) => error.code === ValidationErrors.PreOfferDiscounts)) {
        config.preOffer.errors = config.preOffer.errors.filter((error) => error.code !== ValidationErrors.PreOfferDiscounts)
    }
    if (config.preOffer?.errors?.find((error) => error.code === ValidationErrors.PreOfferDiscounts && !config.errors?.find((error) => error.code === ValidationErrors.PreOfferDiscounts))) {
        if (config.errors) {
            config.errors.push({ code: ValidationErrors.PreOfferDiscounts, text: getValidtionErrorTitle(ValidationErrors.PreOfferDiscounts) })
        } else {
            config.errors = [{ code: ValidationErrors.PreOfferDiscounts, text: getValidtionErrorTitle(ValidationErrors.PreOfferDiscounts) }]
        }
    }
    else if (!config.preOffer?.errors?.find((error) => error.code === ValidationErrors.PreOfferDiscounts && config.errors?.find((error) => error.code === ValidationErrors.PreOfferDiscounts))) {
        config.errors = config.errors?.filter((error) => error.code !== ValidationErrors.PreOfferDiscounts)
    }

    return config
}
export const validationCommonOfferPromoProviders = (config: IWorkflow) => {
    if (config.temporaryData?.promotions && config.commonOffer?.promoProviders) {
        config.commonOffer.promoProviders = config.commonOffer?.promoProviders?.map((elem) => {
            if (elem.promotionId === null) {
                return ({ ...elem, errors: [{ code: ValidationErrors.NotSelectedPromo, text: getValidtionErrorTitle(ValidationErrors.NotSelectedPromo) }] })
            } else if (elem.promotionId !== null && elem.errors?.find((error) => error.code === ValidationErrors.NotSelectedPromo)) {
                elem.errors = elem.errors.filter((error) => error.code !== ValidationErrors.NotSelectedPromo)
            }
            if (!config.temporaryData?.promotions?.find((promotion) => promotion.id === elem.promotionId)) {
                return ({ ...elem, errors: [{ code: ValidationErrors.DeletedPromotion, text: getValidtionErrorTitle(ValidationErrors.DeletedPromotion) }] })
            } else if (elem.errors?.find((error) => error.code === ValidationErrors.DeletedPromotion)) {
                elem.errors = elem.errors.filter(e => e.code !== ValidationErrors.DeletedPromotion)
            }

            return elem

        })
    }
    return config
}

export const validationCommonOfferProviders = async (config: IWorkflow) => {
    if (config.temporaryData?.catalogs && config.commonOffer?.providers) {
        config.commonOffer?.providers?.map((elem) => {
            if (elem.type === OfferProviderTypes.Catalog) {
                if (elem.catalogId === null) {
                    return elem.errors = [{ code: ValidationErrors.NotSetCatalog, text: getValidtionErrorTitle(ValidationErrors.NotSetCatalog) }]
                }
                else if (elem.catalogId !== null && elem.errors?.find((error) => error.code === ValidationErrors.NotSetCatalog)) {
                    elem.errors = elem.errors.filter((error) => error.code !== ValidationErrors.NotSetCatalog)
                }

                if (!config.temporaryData?.catalogs?.find((provider) => provider.id === elem.catalogId)) {
                    elem.errors = [{ code: ValidationErrors.DeletedCatalog, text: getValidtionErrorTitle(ValidationErrors.DeletedCatalog) }]
                } 
                else if (elem.errors?.find((error) => error.code === ValidationErrors.DeletedCatalog)) {
                    elem.errors = elem.errors.filter(e => e.code !== ValidationErrors.DeletedCatalog)
                }
            }
            return elem
        })
    }

    return config
}
export const validationOfEmptyGradesOrCategories = (config: IWorkflow) => {
    if (config.useGradesCategories) {
        if (config.errors?.find((error) => error.code === ValidationErrors.GradesEmpty))
            config.errors = config.errors?.filter((error) => error.code !== ValidationErrors.GradesEmpty)
        emptyGradesOrCategories(config, config.gradesCategories, { code: ValidationErrors.GradeCategoriesEmpty, text: getValidtionErrorTitle(ValidationErrors.GradeCategoriesEmpty) })
    } else {
        config.errors = config.errors?.filter((error) => error.code !== ValidationErrors.GradeCategoriesEmpty)
        emptyGradesOrCategories(config, config.grades, { code: ValidationErrors.GradesEmpty, text: getValidtionErrorTitle(ValidationErrors.GradesEmpty) })
    }
    return config
}

export const validationContractEmailAddress = (config: IWorkflow) => {
    const contractConfig = config.contract
    const isIncludeToEmail = config.contract?.fields?.attachment?.some((attachment) => {
        return attachment.includeToEmail === true;
    })
    if (contractConfig && (contractConfig?.sendToEmail || isIncludeToEmail)) {
        if (contractConfig.fields?.email && contractConfig.errors?.find((error) => error.code === ValidationErrors.EmailAddress)) {
            contractConfig.errors = contractConfig.errors.filter((error) => error.code !== ValidationErrors.EmailAddress)
        } else if (!contractConfig.fields?.email && !contractConfig.errors?.find((error) => error.code === ValidationErrors.EmailAddress)) {
            contractConfig.errors = contractConfig.errors ? [...contractConfig.errors, { code: ValidationErrors.EmailAddress, text: getValidtionErrorTitle(ValidationErrors.EmailAddress) }] : [{ code: ValidationErrors.EmailAddress, text: getValidtionErrorTitle(ValidationErrors.EmailAddress) }]
        }
    }
    else {
        if (contractConfig?.errors?.find((error) => error.code === ValidationErrors.EmailAddress)) {
            contractConfig.errors = contractConfig.errors.filter((error) => error.code !== ValidationErrors.EmailAddress)
        }
    }
    return config
}

export const validationDataCollectionEmailAddress = (module: IInspectionModuleConfig<IInspectionConfig<IInspectionConfigUI>>) => {
    const dataCollectionConfig = module.config as IDataCollectionConfig
    if (dataCollectionConfig?.sendToEmail) {
        if (dataCollectionConfig.config.fields?.email && module.config.errors?.find((error) => error.code === ValidationErrors.DataCollectionEmail)) {
            module.config.errors = module.config.errors.filter((error) => error.code !== ValidationErrors.DataCollectionEmail)
        } else if (!dataCollectionConfig.config.fields?.email && !module.config.errors?.find((error) => error.code === ValidationErrors.DataCollectionEmail)) {
            module.config.errors = module.config.errors ? [...module.config.errors, { code: ValidationErrors.DataCollectionEmail, text: getValidtionErrorTitle(ValidationErrors.DataCollectionEmail) }] : [{ code: ValidationErrors.DataCollectionEmail, text: getValidtionErrorTitle(ValidationErrors.DataCollectionEmail) }]
        }
    }
    else {
        if (module?.config.errors?.find((error) => error.code === ValidationErrors.DataCollectionEmail)) {
            module.config.errors = module.config.errors.filter((error) => error.code !== ValidationErrors.DataCollectionEmail)
        }
    }
    return module
}

export const validationManualGrading = (module: IInspectionModuleConfig<IInspectionConfig<IInspectionConfigUI>>, grades: IGrade[]) => {
    if(!grades.length) {
        if(!module.config.errors || !module.config.errors.length) {
            module.config.errors = [{ code: ValidationErrors.GradesEmpty, text: strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.MANUAL_GRADING_EMPTY_GRADES }]
        } else {
            if (module.config.errors.find((error) => error.code !== ValidationErrors.GradesEmpty)) {
                module.config.errors = [ ...module.config.errors, { code: ValidationErrors.GradesEmpty, text: strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.MANUAL_GRADING_EMPTY_GRADES }]
            }
        }   
    } else {
        module.config.errors = module.config.errors?.filter((error) => error.code !== ValidationErrors.GradesEmpty)
    }
    return module
}

export const emptyGradesOrCategories = (config: IWorkflow, gradesOrCategories: IGrade[] | IGradesCategory[] | undefined, errorValue: IConfigErrors) => {
    if (!gradesOrCategories || gradesOrCategories.length === 0) {
        if (((config.preOffer && !config.preOffer.disabled) || (config.postOffer && !config.preOffer?.disabled)) && !config.errors?.find((error) => error.code === errorValue.code)) {
            if (config.errors && config.errors.length > 0) {
                config.errors.push(errorValue)
            } else {
                config.errors = [errorValue]
            }
        } else if (((!config.preOffer || config.preOffer.disabled) && (!config.postOffer || config.postOffer.disabled)) && config.errors?.find((error) => error.code === errorValue.code)) {
            config.errors = config.errors.filter((error) => error.code !== errorValue.code)
        }
    } else if (gradesOrCategories && gradesOrCategories.length > 0 && config.errors?.find((error) => error.code === errorValue.code)) {
        config.errors = config.errors.filter((error) => error.code !== errorValue.code)
    }
    return config
}

export const validationOfPreOffer = (config: IWorkflow) => {
    validationCommonOfferPromoProviders(config)
    validationCommonOfferProviders(config)
    validationPreOfferDiscounts(config)
    validationOfEmptyGradesOrCategories(config)
    return config
}
export const validationOfPostOffer = (config: IWorkflow) => {
    validationCommonOfferPromoProviders(config)
    validationCommonOfferProviders(config)
    validationOfEmptyGradesOrCategories(config)

    if( config.postOffer?.modules && config.postOffer?.modules.length > 0) {

        if (config.useGradesCategories) {
            config.postOffer?.modules.map((module) => {
                return validationModulesWithUseGradesCategories(module, config.gradesCategories, [Inspections.DataCollection, Inspections.Erase].includes(module.type))
            })
        } else {
            config.postOffer?.modules.map((module) => {
                return validationModulesWithOutUseGradesCategories(module, config.grades)
            })
        }

        config.postOffer?.modules.map((module) => {
            if (module.config.errors && module.config.errors.length > 0) {
                module.errors = [{ code: ValidationErrors.ModuleError, text: getValidtionErrorTitle(ValidationErrors.ModuleError) }]
            } else if ((!module.config.errors || module.config.errors.length === 0) && module.errors) {
                delete (module.errors)
            }
            return module
        })
    }
    return config
}

export const validationOfContentTransfer = (config: IWorkflow) => {
    return config
}

export const validateSelfService = (config: IWorkflow) => {
    if( config.selfService?.followUpQuestions.length == 0) {
        config.selfService.followUpQuestions = [ {
            id: FollowUpQuestionIds.ProblemSolvedQuestion,
            text: strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.PROBLEM_SOLVED.QUESTION,
            type: FollowUpQuestionType.DiagnosticsOrAISolutions,
            responseId: '',
            responses: [{
                id: FollowUpResponseIds.ProblemSolved_Yes,
                text: strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.RESPONSE_YES,
                ruleId: SelfServiceRule.ShowRecommendations_problemsSolved,
                disclaimerId: 0
            },
            {
                id: FollowUpResponseIds.ProblemSolved_No,
                text: strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.RESPONSE_NO,
                ruleId: FollowUpQuestionIds.UnderWarrantyQuestion,
                disclaimerId: 0
            },
            {
                id: FollowUpResponseIds.ProblemSolved_Partial,
                text: strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.RESPONSE_PARTLY,
                ruleId: FollowUpQuestionIds.UnderWarrantyQuestion,
                disclaimerId: 0
            },
            {
                id: FollowUpResponseIds.ProblemSolved_NotSure,
                text: strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.RESPONSE_NOT_SURE,
                ruleId: FollowUpQuestionIds.UnderWarrantyQuestion,
                disclaimerId: 0
            }]
        },
        {
            id: FollowUpQuestionIds.UnderWarrantyQuestion,
            text: strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.WARRANTY.QUESTION,
            type: FollowUpQuestionType.DiagnosticsOrAISolutions,
            responseId: '',
            responses: [{
                id: FollowUpResponseIds.UnderWarranty_Yes,
                text: strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.RESPONSE_YES,
                ruleId: SelfServiceRule.ShowRecommendations_warrantyActive,
                disclaimerId: 0
            },
            {
                id: FollowUpResponseIds.UnderWarranty_No,
                text: strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.RESPONSE_NO,
                ruleId: SelfServiceRule.ShowRecommendations_warrantyNotActive,
                disclaimerId: 0
            },
            {
                id: FollowUpResponseIds.UnderWarranty_NotSure,
                text: strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.RESPONSE_NOT_SURE,
                ruleId: SelfServiceRule.ShowRecommendations_warrantyNotActive,
                disclaimerId: 0
            }]
        },
        {
            id: FollowUpQuestionIds.RepairDeliveryQuestion,
            text: strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.DELIVERY.QUESTION,
            type: FollowUpQuestionType.AcceptOffer,
            responseId: '',
            responses: [{
                id: FollowUpResponseIds.RepairDelivery_BringToStore,
                text: strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.RESPONSE_BRING_TO_STORE,
                ruleId: SelfServiceRule.ShowContactForm,
                disclaimerId: 0
            },
            {
                id: FollowUpResponseIds.RepairDelivery_Pickup,
                text: strings.CONSTRUCTOR.STAGES.SELF_SERVICE.FOLLOW_UP.RESPONSE_PICK_UP_SERVICE,
                ruleId: SelfServiceRule.ShowRANForm,
                disclaimerId: 0
            }]
        }]
    }
    if ( config.temporaryData?.catalogs && config.selfService?.repairOffers?.providers )
    {
        config.selfService?.repairOffers?.providers?.map((p) => {
            
            if (!config.temporaryData?.catalogs?.find((provider) => provider.id === p.catalogId)) {
                // In case catalog assigned to the repair offer privider has been deleted then need to show error message
                p.errors = [{ code: ValidationErrors.DeletedCatalog, text: getValidtionErrorTitle(ValidationErrors.DeletedCatalog) }]
            } 
            else if (p.errors?.find((error) => error.code === ValidationErrors.DeletedCatalog)){
                    // In case deleted catalog was replaced by new one we have to clear error message.
                    p.errors = p.errors.filter(e => e.code !== ValidationErrors.DeletedCatalog)
                 }

            return p
        })
    }
    
    return config;
}

export const compulsoryValidation = (config: IWorkflow) => {

    config.stages?.forEach((stage) => {
        switch (stage.type) {
            case ProcessStages.Identification:
                validationOfEmptyIdentification(config)
                validateSerchInWorkflowCatalog(config)
                validateCustomSearch(config)
                return config
            case ProcessStages.Assessment:
                if (config.useGradesCategories) {
                    config.assessment?.modules?.map((module) => {
                        return validationModulesWithUseGradesCategories(module, config.gradesCategories, [Inspections.DataCollection, Inspections.Erase].includes(module.type))
                    })
                } else {
                    config.assessment?.modules?.map((module) => {
                        return validationModulesWithOutUseGradesCategories(module, config.grades)
                    })
                }
                config.assessment?.modules?.map((module) => {
                    if (module.config.errors && module.config.errors.length > 0) {
                        module.errors = [{ code: ValidationErrors.ModuleError, text: getValidtionErrorTitle(ValidationErrors.ModuleError) }]
                    } else if ((!module.config.errors || module.config.errors.length === 0) && module.errors) {
                        delete (module.errors)
                    }
                    return module
                })
                return validationOfEmptyAssessment(config)

            case ProcessStages.Control:
                if (config.useGradesCategories) {
                    config.control?.modules?.map((module) => {
                        console.log(module)
                        return validationModulesWithUseGradesCategories(module, config.gradesCategories, true)
                    })
                } else {
                    config.control?.modules?.map((module) => {
                        return validationModulesWithOutUseGradesCategories(module, config.grades, true)
                    })
                }
                config.control?.modules?.map((module) => {
                    if (module.config.errors && module.config.errors.length > 0) {
                        module.errors = [{ code: ValidationErrors.ModuleError, text: getValidtionErrorTitle(ValidationErrors.ModuleError) }]
                    } else if ((!module.config.errors || module.config.errors.length === 0) && module.errors) {
                        delete (module.errors)
                    }
                    return module
                })
                return validationOfEmptyControl(config)
            case ProcessStages.PreOffer:
                const result = validationOfPreOffer(config)
                return result
            case ProcessStages.PostOffer:
                return validationOfPostOffer(config)
            case ProcessStages.Contract:
                return validationContractEmailAddress(config)
            case ProcessStages.ContentTransfer:
                return validationOfContentTransfer(config)
            case ProcessStages.SelfService:
                return validateSelfService(config);
        }
    })
    if (config.temporaryData) {
        config.temporaryData.previousGrades = config.grades
    }
    if (config.temporaryData && config.useGradesCategories) {
        config.temporaryData.previousGradesCategories = config.gradesCategories
    }
    checkSkipsErrors(config)
    return config
}

type TProps = {
    answer?: IAnswerOption,
    questions: IQuestion[]
}

type TErrorsDiagnosticsProps = {
    caseItem?: IDiagnosticsPiceaCaseConfig | IDiagnosticsPiceaMobileCaseConfig
    set?: IDiagnosticsPiceaSetConfig | IDiagnosticsPiceaMobileSetConfig
}

type TWorkflowProps = {
    config: IWorkflow
}

type TErrorsVerifyProps = {
    check: ISoftwareCheck
}

export const ResultAnswer: React.FC<TProps> = ({ answer, questions }) => {
    return (
        <Stack horizontal verticalFill verticalAlign="center" tokens={{ childrenGap: 12, padding: "0 0 0 8px" }}>
            {answer?.errors && answer.errors.length > 0 && (
                <Tooltip content={answer.errors[0].text}>
                    <Icon iconName={'Error'} style={{ fontSize: 16, color: SharedColors.red10 }} />
                </Tooltip>
            )}
            {(!answer?.errors || answer?.errors?.length === 0) && answer?.grade !== undefined && (
                <Stack>
                    {answer.grade === "" && (
                        <Text>{strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.RESULT.FAIL}</Text>
                    )}
                    {answer.grade !== "" && (
                        <Text styles={gradeAnswer}>{answer.grade}</Text>
                    )}
                </Stack>

            )}{(!answer?.errors || answer?.errors?.length === 0) && !answer?.next && answer?.grade === undefined && (
                <Text>{strings.CONSTRUCTOR.INSPECTIONS.INTERVIEW.QUESTIONS_CONFIGURATOR.FORM.QUESTION.ANSWER.RESULT.NO_GRADE}</Text>
            )}
            {(!answer?.errors || answer?.errors?.length === 0) && answer?.next && (
                <Text styles={gradeAnswer}>{questions.find((question) => question.index === answer.next)?.title}</Text>
            )}
        </Stack>
    )
}

export const ErrorsRenderDiagnostics: React.FC<TErrorsDiagnosticsProps> = ({ caseItem, set }) => {
    const setError = set?.errors?.find((error) => error.code === ValidationErrors.GradeWasReset)
    return (
        <Stack grow horizontalAlign='end' horizontal verticalAlign='center'>
            {caseItem && caseItem.errors && (
                <Stack horizontalAlign='end' horizontal verticalAlign='center' >
                    <Tooltip content={caseItem.errors[0].text}>
                        <Icon iconName={'Error'} style={{ fontSize: 16, color: SharedColors.red10 }} />
                    </Tooltip>
                </Stack>
            )}
            {set && setError && (
                <Stack horizontalAlign='end' horizontal verticalAlign='center' >
                    <Tooltip content={setError.text}>
                        <Icon iconName={'Error'} style={{ fontSize: 16, color: SharedColors.red10 }} />
                    </Tooltip>
                </Stack>

            )}
        </Stack>
    )
}

export const ErrorsRenderVerify: React.FC<TErrorsVerifyProps> = ({ check }) => {
    const checkError = check.errors?.find((error) => error.code === ValidationErrors.GradeWasReset)
    return (<Stack grow horizontalAlign='end' horizontal verticalAlign='center'>
        {check && checkError && (
            <Stack horizontalAlign='end' horizontal verticalAlign='center' >
                <Tooltip content={checkError.text}>
                    <Icon iconName={'Error'} style={{ fontSize: 16, color: SharedColors.red10 }} />
                </Tooltip>
            </Stack>
        )}
    </Stack>)
}

export const WorkflowErrorsRender: React.FC<TWorkflowProps> = ({ config }) => {
    let errorListRender: JSX.Element = (
        <div style={{ display: "inline" }}>
            <ul style={{ paddingLeft: 16 }}>
                {config.errors?.map((error) => (
                    <li>{error.text}</li>
                ))}
            </ul>
        </div>
    )
    return (
        <Stack tokens={{ padding: '10px 0 20px 0', childrenGap: 4 }}>
            <MessageBar isMultiline={true} overflowButtonAriaLabel="See more" messageBarType={MessageBarType.severeWarning}>
                <b style={{ width: "70%" }}>{strings.CONSTRUCTOR.INSPECTIONS.COMMON.NOTES_ON_SETTINGS_PROCESS}</b> {errorListRender}
            </MessageBar>
        </Stack>
    )
}

const gradeAnswer = {
    root: {
        color: SharedColors.cyanBlue10
    }
}

const theme = getTheme();
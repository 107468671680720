import {
    getTheme, Stack,
    IconButton, DirectionalHint, IContextualMenuProps,
    Text, SharedColors, Icon, PrimaryButton, Image, ImageFit
} from "@fluentui/react"
import { useBoolean } from "@uifabric/react-hooks";
import React, { Dispatch, useState } from "react";
import { actionCreators } from "../../../../../../core/actions/configurator-actions";
import { getIconSymbolsFromString, getPersonaColorsPallete } from "../../../../../../core/scripts/style";
import { IOfferProviderConfig, IGrade } from "@piceasoft/core"
import { strings } from "../../../../../../localization/strings";
import { OfferProviderForm } from "../../../../forms/OfferProviderForm";
import { Tooltip } from "../../../../help/Tooltip";
import { ItemsNotFound } from "../../../../notFound/ItemsNotFound";
import { CustomPanel } from "../../../../panel/CustomPanel";
import { OfferProviderTypes, ICommonOfferConfig } from "@piceasoft/core";
import { onRenderLabel } from "../../../../../renders/onRenderLabel";
import './providerlist.css'

type TProps = {
    onChangeDispatch: Dispatch<any>
    config?: ICommonOfferConfig
    grades?: IGrade[]
    offerProviderCode?: string
}

export const CommonOfferStageOfferProvidersPivot: React.FC<TProps> = (props) => {

    if (!props.config) return null

    const [panelState, setPanelState] = React.useState<{ index: number, item: IOfferProviderConfig }>()
    const [isPanelOpen, { setTrue: showPanel, setFalse: hidePanel }] = useBoolean(false)

    React.useEffect(() => {
        if (props.offerProviderCode) {
            let index = 0
            const providerItem = props.config?.providers?.find((i, localIndex) => {
                index = localIndex;
                return i.code === props.offerProviderCode ? true : false;
            })
            if (providerItem) {
                setPanelState({ index: index, item: providerItem })
            }
        }
    }, [])

    React.useEffect(() => {
        if (panelState && !isPanelOpen) {
            showPanel()
        }
    }, [panelState])

    const onCancel = () => {
        hidePanel();
        setTimeout(() => setPanelState(undefined), 100);
    }

    const onEdit = (item: IOfferProviderConfig, index: number) => {
        setPanelState({ index, item });
    }

    const onAdd = () => {
        showPanel()
    }

    const onDelete = async (index: number) => {

        let newProviders = props.config?.providers?.filter((op, opIndex) => opIndex !== index)
        ?.map(item => {
            // adjust index on rest items in array
            if (item.index > index && item.index >0)
                item.index--;
            return item;
        }) ?? [];

        populateProvidersIndex(newProviders);
        props.onChangeDispatch(actionCreators.commonOffer.onConfigCommit({...props.config, providers: newProviders}));
        onCancel();
    };

    const onCommit = async (item: IOfferProviderConfig) => {
        console.log("onCommit: %O", item)
        let newProviders: IOfferProviderConfig[] = []
        if (panelState?.index !== undefined) {
            // update existing
            newProviders = props.config?.providers?.map((op, aIndex) => {
                if (panelState.index === aIndex) {
                    return item;
                }
                return op;
            }) ?? [];
        } else {
            // new item, update index to max.
            item.index = props.config?.providers?.length ?? 0;
            newProviders = [...(props.config?.providers ?? []), item];
        }

        populateProvidersIndex(newProviders);
        props.onChangeDispatch(actionCreators.commonOffer.onConfigCommit({ ...props.config, providers: newProviders }));
        onCancel();
    };

    const onMoveUp = (indexToMove: number) => {
        // some validation
        if (!props.config?.providers || indexToMove <= 0 || indexToMove >= (props.config?.providers?.length ?? 0)) {
            return;
        }

        // swap objects in array, and update index according

        let newProviders = [...props.config.providers]

        const temp = newProviders[indexToMove]; // item to be moved
        temp.index--;

        newProviders[indexToMove] = newProviders[indexToMove - 1]; // Move the previous item to the current index
        newProviders[indexToMove].index = indexToMove; // update index also
        newProviders[indexToMove - 1] = temp; // Place the moved item in the previous index

        populateProvidersIndex(newProviders);

        props.onChangeDispatch(actionCreators.commonOffer.onConfigCommit({...props.config,providers: newProviders}));
    }

    /** 
     * Adds index values to array objects, based on current array order.
     * Index should be already there, but this more like safeguard for old configs to make sure index is really uptodate for all items
     */
    const populateProvidersIndex = (items: IOfferProviderConfig[]) => {
        items.forEach((item, index) => {
            item.index = index;
        });
    }

    return (
        <>
            {onRenderLabel({        
                label: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.PRIORITY,
                title: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.PRIORITY_TITLE })
            }
            <div style={{borderTop: `1px solid ${theme.palette.neutralLight}`, marginTop: 10, marginBottom: -7 }} />
            {(props.config.providers && props.config.providers.length > 0) && (
                <>
                    <ProvidersList
                        items={props.config.providers}
                        onDelete={(index)=>onDelete(index)}
                        onEdit={(item: IOfferProviderConfig, index) => onEdit(item, index)}
                        onMoveUp={onMoveUp}
                        />
                    <Stack tokens={{ padding: "12px 0px", childrenGap: 16 }}>
                        <Stack.Item>
                            <PrimaryButton onClick={onAdd}>{strings.BUTTONS.TEXT.ADD}</PrimaryButton>
                        </Stack.Item>
                    </Stack>
                </>
            ) || (
                    <ItemsNotFound
                        info={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.NOT_FOUND.INFO}
                        suggestion={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.NOT_FOUND.SUGGESTION}
                        buttonText={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.NOT_FOUND.BUTTON_TEXT}
                        imgSrc={"images/navigation/images/not_found.png"}
                        onClick={showPanel}
                    />
                )}
            <CustomPanel
                isOpen={isPanelOpen}
                onCancel={onCancel}
                noCancelOnDissmiss={true}
                title={panelState ?
                    strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER.EDIT :
                    strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER.NEW
                }
            >
                <OfferProviderForm
                    onSubmit={onCommit}
                    onCancel={onCancel}
                    data={panelState?.item}
                    codes={props.config.providers?.filter(i => i.code !== panelState?.item.code).map(i => i.code) ?? []}

                />
            </CustomPanel>
        </>
    )
}


type TProvidersListProps = {
    items?: IOfferProviderConfig[];
    onEdit: (item: IOfferProviderConfig, arrIndex: number) => void;
    onDelete: (arrIndex: number) => void;
    onMoveUp: (arrIndex: number) => void;
}
const ProvidersList : React.FC<TProvidersListProps> = (props) => {
    return <div style={providersListStyle}>
        {props.items?.map((item, index) => <ProviderRow key={index} arrIndex={index} item={item} onEdit={props.onEdit} onDelete={props.onDelete} onMoveUp={props.onMoveUp} />)}
    </div>
    
}

type TProviderRowProps = {
    arrIndex: number
    item: IOfferProviderConfig;
    onEdit: (item: IOfferProviderConfig, index: number) => void;
    onDelete: (arrIndex: number) => void;
    onMoveUp: (arrIndex: number) => void;
}
const ProviderRow : React.FC<TProviderRowProps> = (props) => {
    const [isHovered, setIsHovered] = useState(false);

    const getProviderIcon = (type: OfferProviderTypes):string | undefined => {
        switch(type) {
            case OfferProviderTypes.Offer: return "Money";
            case OfferProviderTypes.Catalog: return "ProductCatalog";
        }
    }
    const getItemMenuProps = (item: IOfferProviderConfig, arrIndex: number): IContextualMenuProps => {
        return {
            items: [
                {
                    key: `edit-${item.code}`,
                    iconProps: { iconName: 'Edit', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => props.onEdit(item, arrIndex),
                    text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.EDIT,
                },
                {
                    key: `delete-${item.code}`,
                    iconProps: { iconName: 'Delete', style: { color: theme.palette.black, fontSize: 14 } },
                    onClick: () => props.onDelete(arrIndex),
                    text: strings.ORGANIZATION.STRUCTURE.TREE.CONTEXT_MENU.REMOVE,
                }
            ],
            directionalHint: DirectionalHint.bottomRightEdge
        }
    }

    const item = props.item;

    return <div key={`row-${item.code}`} onMouseEnter={()=>setIsHovered(true)} onMouseLeave={()=>setIsHovered(false)} style={providerRowStyle}>

            {/** priority /*/}
            <div style={{...cellStyle, width: 50, paddingLeft: 0}}>
            <Stack.Item grow style={{width: 50}}>
                <Stack verticalFill verticalAlign="center" >
                    <IconButton disabled={props.arrIndex <= 0} iconProps={{ iconName: "Up", className: 'up-icon' }} style={{ height: 46, width: 46, backgroundColor: 'transparent' }}
                        onClick={(ev) => { ev.stopPropagation(); console.log("onmm: " + props.arrIndex); props.onMoveUp(props.arrIndex) }}
                    />
                </Stack>
            </Stack.Item>
            </div>

            <div className={`provider-list-item ${item.errors && item.errors?.length > 0 ? 'error-row' : ''}`} style={{border: `1px solid ${theme.palette.neutralLight}`}} onClick={() => props.onEdit(item, props.arrIndex)} >

            {/** type /*/}
            <div style={{...cellStyle, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: 120 }}>
                {item.imageSrc && (
                    <Stack horizontalAlign='center'>
                        <Image src={item.imageSrc} width={40} height={40} imageFit={ImageFit.cover} style={{ borderRadius: 20 }} />
                    </Stack>
                ) || (
                        <Stack verticalAlign="center" horizontalAlign="center" style={{ width: 40, height: 40, borderRadius: 20, backgroundColor: getPersonaColorsPallete[item.name[0].toLowerCase()] }}>
                            <Text variant="large" style={{ color: "#fff", fontSize: 16, fontWeight: 100, paddingBottom: 2 }}>
                                {getIconSymbolsFromString(item.name)}
                            </Text>
                        </Stack>
                    )}
                <Icon style={{ fontSize: 32 }} iconName={getProviderIcon(item.type)}
                />
            </div>

            {/** name /*/}
            <div style={{...cellStyle, minWidth: 150, maxWidth: 400, flex: 3, flexDirection: 'column'}}>
                <Text variant="medium" nowrap style={{ color: theme.palette.black, fontWeight: 600}}>{item.name}</Text>
                <Text variant="small" style={{ color: SharedColors.gray30, maxHeight: 50, overflowY: 'hidden' }}>{item.description}</Text>
            </div>

            {/** code /*/}
            <div style={{...cellStyle, maxWidth: 270, flex: 1}}>
                <Text variant="medium" nowrap style={{ color: SharedColors.gray30, fontWeight: 500 }}>{item.code}</Text>
                <Text variant="medium" nowrap style={{ color: SharedColors.gray30 }}>{item.endpoint}</Text>
            </div>


            {/** commandbar /*/}
            <div style={{...cellStyle, width: 70, marginLeft: 'auto'}}  onClick={(ev) => ev.stopPropagation()}>
                {item.errors && item.errors.length > 0 && (
                    <Stack.Item>
                        <Stack verticalAlign="center" verticalFill>
                            <Tooltip content={strings.CONSTRUCTOR.INSPECTIONS.COMMON.ERRORS.DELETED_CATALOG}>
                                <Icon iconName={'Error'} style={{ fontSize: 16, color: SharedColors.red10 }} />
                            </Tooltip>
                        </Stack>
                    </Stack.Item>
                )}
                <Stack.Item>
                    <IconButton size={16} menuIconProps={{ iconName: "MoreVertical", className: 'provider-menu-icon' }} className="provider-menu-icon" style={{color: isHovered ? "black" : "transparent"}}  menuProps={getItemMenuProps(item, props.arrIndex)} />
                </Stack.Item>
            </div>
            
            </div>

        </div>
}


const theme = getTheme();

const providersListStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    gap: 4
}

const providerRowStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
};

const cellStyle: React.CSSProperties = {
    display: 'flex',
    padding: '11px'
}


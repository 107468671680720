import {
  ProcessStages,
  IdentificationMethods,
  ManualGradingModes,
  ServiceTypes,
} from "@piceasoft/core";
import { DiagnosticsModes } from "@piceasoft/core";
import { InterviewModes } from "@piceasoft/core";
import { PhotographicModes } from "@piceasoft/core";
import { SoftwareModes } from "@piceasoft/core";

import { AIGradingModes } from "@piceasoft/core";
import { DataCollectionModes } from "@piceasoft/core";
import { EraseModes, Inspections } from "@piceasoft/core";

export enum Experience {
  Retail = 1 || "Retail experience",
  ECom = 2 || "E-commerce experience",
  Mobile = 3 || "Mobile experience",
  MobileSDK = 9 || "MobileSDK experience",
}

/**
 * Check Process Stage Access
 * @param experience
 * @param processStage
 * @returns
 */
export const CheckProcessStageAccess = (
  experience: number,
  processStage: ProcessStages
) => {
  return !!ExperienceAndServiceTypeVisibilityArray.find(
    (step) =>
      step.processStage === processStage &&
      step.experiences &&
      step.experiences.includes(experience)
  );
};

/**
 * Check Process Stage Section Option Access
 * @param experience
 * @param processStage
 * @param section
 * @param option
 * @param serviceType optional: disable the feature for the given service type.
 * @returns
 */
export const CheckProcessStageSectionOptionAccess = (
  experience: number,
  processStage: ProcessStages,
  section: string,
  option: string,
  serviceType?: number
): boolean => {
  let is_enabled = ExperienceAndServiceTypeVisibilityArray.find((step) => {
    if (step.processStage === processStage) {
      return step.sections.some((s_section) => {
        if (s_section.section === section && s_section.options) {
          return s_section.options.some((s_option) => {
            if (
              s_option.option === option &&
              s_option.experiences &&
              s_option.experiences.includes(experience)
            ) {
              if(serviceType && s_option.disableServiceType && s_option.disableServiceType.includes(serviceType)) {
                return false;
              }
              return true;
            } else {
              return false;
            }
          });
        } else {
          return false;
        }
      });
    }
    return false;
  });
  if (is_enabled) {
    return true;
  }
  return false;
};

/**
 * Chec kWorkflow Settings Access
 * @param experience
 * @param option
 * @returns
 */
export const CheckWorkflowSettingsAccess = (
  experience: number,
  option: string
): boolean => {
  return !!WorkFlowParameters.find(
    (s_option) =>
      s_option.option === option &&
      s_option.experiences &&
      s_option.experiences.includes(experience)
  );
};

export const WorkFlowParameters = [
  {
    option: "This is control workflow",
    experiences: [Experience.Retail],
  },
  {
    option: "Use grade categories",
    experiences: [Experience.Retail],
  },
  {
    option: "Use discount catalogs",
    experiences: [Experience.Retail]
  },
  {
    option: "Filter devices by category",
    experiences: [
      Experience.Retail,
      Experience.ECom,
      Experience.Mobile,
      Experience.MobileSDK,
    ],
  },
  {
    option: "Default catalog",
    experiences: [
      Experience.Retail,
      Experience.ECom,
      Experience.Mobile,
      Experience.MobileSDK,
    ],
  },
  {
    option: "Document template",
    experiences: [
      Experience.Retail,
      Experience.ECom,
      Experience.Mobile,
      Experience.MobileSDK,
    ],
  },
];

export interface IExperienceAndServiceTypeVisibilityArray {
  workflowStep: string,
  sections: {
    section: string,
    options?: {
      option: string,
      experiences: Experience[],
      disableServiceType?: ServiceTypes[],
    }[],
    experiences?: Experience[],
    inspections?: {
      module: string,
      experiences:Experience [],
    }[]
  }[],
  processStage: ProcessStages,
  experiences: Experience[],
}

export const ExperienceAndServiceTypeVisibilityArray: IExperienceAndServiceTypeVisibilityArray[] = [
  {
    workflowStep: "Resources availability check",
    sections: [],
    processStage: ProcessStages.Resources,
    experiences: [Experience.Retail],
  },
  {
    workflowStep: "Landing",
    sections: [],
    processStage: ProcessStages.Landing,
    experiences: [Experience.Mobile],
  },
  {
    workflowStep: "Content transfer",
    sections: [
      {
        section: "Step UI",
        options: [
          {
            option: "Use custom step title",
            experiences: [Experience.Retail],
          },
          {
            option: "Use custom step header",
            experiences: [Experience.Retail],
          },
          {
            option: "Use custom step information",
            experiences: [Experience.Retail],
          },
        ],
      },
      {
        section: "Buttons",
        options: [
          {
            option: "Custom Next button caption",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom hint text",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom Cancel button caption",
            experiences: [Experience.Retail],
          },
        ],
      },
    ],
    processStage: ProcessStages.ContentTransfer,
    experiences: [Experience.Retail],
  },
  {
    workflowStep: "Device identification",
    processStage: ProcessStages.Identification,
    sections: [
      {
        section: "Step UI",
        options: [
          {
            option: "Use custom step title",
            experiences: [Experience.Retail],
          },
          {
            option: "Use custom step header",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Use custom step information",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
        ],
      },
      {
        section: "Buttons",
        options: [
          {
            option: "Custom Next button caption",
            experiences: [Experience.Retail, Experience.ECom, Experience.MobileSDK, Experience.Mobile],
          },
          {
            option: "Custom hint text",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom Cancel button caption",
            experiences: [Experience.Retail, Experience.ECom, Experience.MobileSDK, Experience.Mobile],
          },
          {
            option: "Custom Back button caption",
            experiences: [Experience.Retail],
          },
        ],
      },
      {
        section: "Confirmation",
        experiences: [Experience.Retail],
        options: [
          {
            option: "Use step confirmation",
            experiences: [Experience.Retail],
          },
          {
            option: "Use custom confirmation header",
            experiences: [Experience.Retail],
          },
          {
            option: "Use custom confirmation information",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom Confirm button caption",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom Cancel button caption",
            experiences: [Experience.Retail],
          },
          {
            option: "Use step cancel reasons",
            experiences: [Experience.Retail],
          },
        ],
      },
      {
        section: "Device Identifiers",
        options: [
          {
            option: "Require device identifiers",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Use a custom title",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Use custom information",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Requires IMEI",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Requires IMEI2",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Requires serial number",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
        ],
      },
      {
        section: "Device groups",
        options: [
          {
            option: "Allowed device groups (from the workflow catalog)",
            experiences: [Experience.Retail],
          },
        ],
      },
      {
        section: "Additional",
        options: [
          {
            option: "Use device group as SKU code",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Skip device identification summary page",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
        ],
      },
    ],
    experiences: [
      Experience.Retail,
      Experience.ECom,
      Experience.Mobile,
      Experience.MobileSDK,
    ],
  },
  {
    workflowStep: "Pre-offer, campaigns",
    processStage: ProcessStages.PreOffer,
    sections: [
      {
        section: "Step UI",
        options: [
          {
            option: "Use custom step title",
            experiences: [Experience.Retail],
          },
          {
            option: "Use custom step header",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Use custom step information",
            experiences: [Experience.Retail],
          },
        ],
      },
      {
        section: "hasOffer",
        options: [
          {
            option: "Custom message text",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom information text",
            experiences: [Experience.Retail],
          },
        ],
      },
      {
        section: "noOffer",
        options: [
          {
            option: "Custom message text",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Custom information text",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
        ],
      },
      {
        section: "notAcceptable",
        options: [
          {
            option: "Custom message text",
            experiences: [Experience.Retail],
          },
        ],
      },
      {
        section: "Campaigns/Assign providers",
        options: [
          {
            option: "Assign campaign providers",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
        ],
      },
      {
        section: "Campaigns/UI settings",
        options: [
          {
            option: "Allow campaigns",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Custom trade-up device title",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom Campaign button caption",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom Campaign button hint header text",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom Campaign panel information",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom Campaign panel search input placeholder",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom Campaign button hint text",
            experiences: [Experience.Retail],
          },
        ],
      },
      {
        section: "Offer providers",
        options: [
          {
            option: "Offer providers",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
        ],
      },
      {
        section: "Common offer settings",
        options: [
          {
            option: "Custom trade-in device title",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Do not stop workflow if no offer found",
            experiences: [Experience.Retail],
          },
        ],
      },
      {
        section: "Additional",
        options: [
          {
            option: "Show device analogs",
            experiences: [Experience.Retail],
          },
          {
            option: "Allow analog selection",
            experiences: [Experience.Retail],
          },
          {
            option: "Show offer prices",
            experiences: [Experience.Retail],
          },
          {
            option: "Show the best price only",
            experiences: [Experience.Retail],
          },
        ],
      },
      {
        section: "Buttons",
        options: [
          {
            option: "Custom Next button caption",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Custom hint text",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom Cancel button caption",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
        ],
      },
      {
        section: "Confirmation",
        options: [
          {
            option: "Use step confirmation",
            experiences: [Experience.Retail],
          },
          {
            option: "Use custom confirmation header",
            experiences: [Experience.Retail],
          },
          {
            option: "Use custom confirmation information",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom Confirm button caption",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom Cancel button caption",
            experiences: [Experience.Retail],
          },
          {
            option: "Use step cancel reasons",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
        ],
      },
    ],
    experiences: [
      Experience.Retail,
      Experience.ECom,
      Experience.Mobile,
      Experience.MobileSDK,
    ],
  },
  {
    workflowStep: "Device check",
    processStage: ProcessStages.Assessment,
    sections: [
      {
        section: "Step UI",
        options: [
          {
            option: "Use custom step title",
            experiences: [Experience.Retail],
          },
          {
            option: "Use custom step header",
            experiences: [Experience.Retail],
          },
          {
            option: "Use custom step information",
            experiences: [Experience.Retail],
          },
        ],
      },
      {
        section: "Buttons",
        options: [
          {
            option: "Custom Next button caption",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Custom hint text",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom Cancel button caption",
            experiences: [Experience.Retail, Experience.ECom, Experience.Mobile, Experience.MobileSDK],
          },
        ],
      },
      {
        section: "Common settings",
        options: [
          {
            option: "Skip summary",
            experiences: [
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
        ],
      },
      {
        section: "Confirmation",
        experiences: [Experience.Retail],
        options: [
          {
            option: "Use step confirmation",
            experiences: [Experience.Retail],
          },
          {
            option: "Use custom confirmation header",
            experiences: [Experience.Retail],
          },
          {
            option: "Use custom confirmation information",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom Confirm button caption",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom Cancel button caption",
            experiences: [Experience.Retail],
          },
        ],
      },
      {
        section: "Cancel reasons",
        experiences: [Experience.Retail],
        options: [
          {
            option: "Use step cancel reasons",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
        ],
      },
      {
        section: "Device Identifiers",
        options: [
          {
            option: "Require device identifiers",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Use a custom title",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Use custom information",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Requires IMEI",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Requires IMEI2",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Requires serial number",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
        ],
      },
      {
        section: "Additional",
        options: [
          {
            option: "Hide dependent module",
            experiences: [Experience.Retail],
          },
          {
            option: "Hide skipped modules",
            experiences: [Experience.Retail],
          },
          {
            option: "Collapse completed modules",
            experiences: [Experience.Retail],
          },
        ],
      },
      {
        section: "Modules",
        inspections: [
          {
            module: "Custom question: Sequence",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            module: "Custom question: List",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            module: "Custom question: Manual",
            experiences: [Experience.Retail],
          },
          {
            module: "Photos: Collection",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            module: "Photos: Free",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            module: "AI Grading",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            module: "Diagnostics: PiceaMobile",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            module: "Diagnostics: Piceasoft",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            module: "Diagnostics: Web-based",
            experiences: [
              Experience.ECom,
              Experience.Mobile
            ],
          },
          {
            module: "Verify: Piceasoft",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            module: "Verify: PiceaMobile",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            module: "Data Collection",
            experiences: [Experience.Retail],
          },
          {
            module: "Eraser",
            experiences: [Experience.Retail],
          },
          {
            module: "Grade Selection",
            experiences: [
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
        ],
      },
      {
        section: "Module options",
        options: [
          {
            option: "Dependencies",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Skip",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Autorun",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Reset state at skip",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
        ],
      },
    ],
    experiences: [
      Experience.Retail,
      Experience.ECom,
      Experience.Mobile,
      Experience.MobileSDK,
    ],
  },
  {
    workflowStep: "Post-offer, campaigns",
    processStage: ProcessStages.PostOffer,
    sections: [
      {
        section: "Step UI",
        options: [
          {
            option: "Custom post-offer stage subtitle",
            experiences: [Experience.Retail, Experience.ECom],
          },
          {
            option: "Custom information for the post-offer stage",
            experiences: [Experience.Retail, Experience.ECom],
          },
        ],
      },
      {
        section: "Buttons",
        options: [
          {
            option: "Custom Next button caption",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Custom hint text",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom Cancel button caption",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
        ],
      },
      {
        section: "Confirmation",
        experiences: [Experience.Retail],
        options: [
          {
            option: "Use step confirmation",
            experiences: [Experience.Retail],
          },
          {
            option: "Use custom confirmation header",
            experiences: [Experience.Retail],
          },
          {
            option: "Use custom confirmation information",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom Confirm button caption",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom Cancel button caption",
            experiences: [Experience.Retail],
          },
        ],
      },
      {
        section: "Cancel reasons",
        options: [
          {
            option: "Use step cancel reasons",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
        ],
      },
      {
        section: "Campaigns/Assign providers",
        experiences: [Experience.Retail],
        options: [
          {
            option: "Assign campaign providers",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
        ],
      },
      {
        section: "Campaigns/UI settings",
        experiences: [Experience.Retail],
        options: [
          {
            option: "Allow campaigns",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Custom trade-up device title",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom Campaign button caption",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom Campaign button hint header text",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom Campaign panel information",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom Campaign panel search input placeholder",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom Campaign button hint text",
            experiences: [Experience.Retail],
          },
        ],
      },
      {
        section: "Offer providers",
        experiences: [Experience.Retail],
        options: [
          {
            option: "Offer providers",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
        ],
      },
      {
        section: "Common offer settings",
        experiences: [Experience.Retail],
        options: [
          {
            option: "Custom trade-in device title",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Do not stop workflow if no offer found",
            experiences: [Experience.Retail],
          },
        ],
      },
      {
        section: "Additional",
        experiences: [Experience.Retail],
        options: [
          {
            option: "Select best offer autommatically",
            experiences: [Experience.Retail, Experience.ECom],
          },
        ],
      },
    ],
    experiences: [
      Experience.Retail,
      Experience.ECom,
      Experience.Mobile,
      Experience.MobileSDK,
    ],
  },
  {
    workflowStep: "Print form UI",
    processStage: ProcessStages.Contract,
    sections: [
      {
        section: "Step UI",
        options: [
          {
            option: "Use custom step title",
            experiences: [Experience.Retail],
          },
          {
            option: "Use custom step header",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Use custom step information",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Custom edit button text",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
        ],
      },
      {
        section: "Buttons",
        options: [
          {
            option: "Custom Next button caption",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Custom hint text",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom Cancel button caption",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
        ],
      },
      {
        section: "Confirmation",
        options: [
          {
            option: "Use step confirmation",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Use custom confirmation header",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Use custom confirmation information",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Custom Confirm button caption",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Custom Cancel button caption",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
        ],
      },
      {
        section: "Cancel reasons",
        options: [
          {
            option: "Use step cancel reasons",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
        ],
      },
      {
        section: "Additional",
        options: [
          {
            option: "Enable marketing consent request",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Consent is mandatory",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Custom text for consent request",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Send workflows document template to customer emailt",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Use DaData",
            experiences: [Experience.Retail],
          },
        ],
      },
      {
        section: "Data fields",
        experiences: [Experience.Retail],
        options: [
          {
            option: "Name",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Phone",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Email",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Passport data: Passport number",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Passport data: Date of issue",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Passport data: Issuer",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Passport data: Issuer code",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Passport data: Date of birth",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Passport data: Place of birth",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Passport data: Address",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Operator",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Custom",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Attachment",
            experiences: [Experience.Retail],
          },
        ],
      },
    ],
    experiences: [
      Experience.Retail,
      Experience.ECom,
      Experience.Mobile,
      Experience.MobileSDK,
    ],
  },
  {
    workflowStep: "Control",
    processStage: ProcessStages.Control,
    sections: [],
    experiences: [Experience.Retail],
  },
  {
    workflowStep: "Finish step",
    processStage: ProcessStages.Result,
    sections: [
      {
        section: "Step UI",
        options: [
          {
            option: "Use custom step title",
            experiences: [Experience.Retail],
          },
          {
            option: "Use custom step header",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Use custom step information",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Step warning message",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Secondary information",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
        ],
      },
      {
        section: "Buttons",
        options: [
          {
            option: "Custom Next button caption",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Show contract button",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
            disableServiceType: [ServiceTypes.SelfService],
          },
          {
            option: "Custom Print button icon",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom Print button caption",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Custom Print button tooltip text",
            experiences: [Experience.Retail],
          },
        ],
      },
      {
        section: "Confirmation",
        options: [
          {
            option: "Use step confirmation",
            experiences: [Experience.Retail],
          },
          {
            option: "Use custom confirmation header",
            experiences: [Experience.Retail],
          },
          {
            option: "Use custom confirmation information",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom Confirm button caption",
            experiences: [Experience.Retail],
          },
          {
            option: "Custom Cancel button caption",
            experiences: [Experience.Retail],
          },
        ],
      },
      {
        section: "Cancel reasons",
        options: [
          {
            option: "Use step cancel reasons",
            experiences: [Experience.Retail],
          },
        ],
      },
      {
        section: "Barcodes",
        options: [
          {
            option: "Show the device barcode",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Device barcode title",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Show IMEI barcode",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Show IMEI2 barcode",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "IMEI barcode title",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Show SKU barcode",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "SKU barcode title",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Show the serial number barcode",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Serial number barcode title",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Show the transaction barcode",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
          {
            option: "Transaction barcode title",
            experiences: [
              Experience.Retail,
              Experience.ECom,
              Experience.Mobile,
              Experience.MobileSDK,
            ],
          },
        ],
      },
    ],
    experiences: [
      Experience.Retail,
      Experience.ECom,
      Experience.Mobile,
      Experience.MobileSDK,
    ],
  },
  {
    workflowStep: "SelfService",
    processStage: ProcessStages.SelfService,
    sections: [
      {
        section: "Buttons",
        options: [
          {
            option: "Custom Continue button caption",
            experiences: [Experience.Mobile],
          },
          {
            option: "Custom Cancel button caption",
            experiences: [Experience.Mobile],
          },
        ],
      },
      {
        section: "Repair offer",
        options: [
          {
            option: "Custom Recommendations title",
            experiences: [Experience.Mobile],
          },
          {
            option: "Custom repair offer and promotions description",
            experiences: [Experience.Mobile],
          },
          {
            option: "Custom promotions description",
            experiences: [Experience.Mobile],
          },
          {
            option: "Show a disclaimer",
            experiences: [Experience.Mobile],
          },
        ],
      },
    ],
    experiences: [Experience.Mobile],
  },
];

/**
 * Check Assesment Module Access
 * @param experience
 * @param inspection
 * @param id
 * @returns
 */
export const CheckAssesmentModuleAccess = (
  experience: Experience,
  inspection: Inspections,
  id: string
): boolean => {
  return !!AssesmentModules.find(
    (s_module) =>
      s_module.inspection === inspection &&
      s_module.id === id &&
      s_module.experiences &&
      s_module.experiences.includes(experience)
  );
};

export const AssesmentModules = [
  {
    module: "Custom question: Sequence",
    experiences: [
      Experience.Retail,
      Experience.ECom,
      Experience.Mobile,
      Experience.MobileSDK,
    ],
    inspection: Inspections.Interview,
    id: InterviewModes.Sequence.toString(),
  },
  {
    module: "Custom question: List",
    experiences: [
      Experience.Retail,
      Experience.ECom,
      Experience.Mobile,
      Experience.MobileSDK,
    ],
    inspection: Inspections.Interview,
    id: InterviewModes.List.toString(),
  },
  {
    module: "Custom question: Manual",
    experiences: [Experience.Retail],
    inspection: Inspections.Interview,
    id: InterviewModes.Manual.toString(),
  },
  {
    module: "Photos: Collection",
    experiences: [
      Experience.Retail,
      Experience.ECom,
      Experience.Mobile,
      Experience.MobileSDK,
    ],
    inspection: Inspections.Photographic,
    id: PhotographicModes.Collection.toString(),
  },
  {
    module: "Photos: Free",
    experiences: [
      Experience.Retail,
      Experience.ECom,
      Experience.Mobile,
      Experience.MobileSDK,
    ],
    inspection: Inspections.Photographic,
    id: PhotographicModes.Free.toString(),
  },
  {
    module: "AI Grading",
    experiences: [
      Experience.Retail,
      Experience.ECom,
      Experience.Mobile,
      Experience.MobileSDK,
    ],
    inspection: Inspections.AIGrading,
    id: AIGradingModes.Default.toString(),
  },
  {
    module: "Diagnostics: PiceaMobile",
    experiences: [
      Experience.Retail,
      Experience.ECom,
      Experience.Mobile,
      Experience.MobileSDK,
    ],
    inspection: Inspections.Diagnostics,
    id: DiagnosticsModes.PiceaMobile.toString(),
  },
  {
    module: "Diagnostics: Piceasoft",
    experiences: [
      Experience.Retail,
      Experience.ECom,
      Experience.Mobile,
      Experience.MobileSDK,
    ],
    inspection: Inspections.Diagnostics,
    id: DiagnosticsModes.Piceasoft.toString(),
  },
  {
    module: "Diagnostics: Web-based",
    experiences: [
      Experience.ECom,
      Experience.Mobile
    ],
    inspection: Inspections.Diagnostics,
    id: DiagnosticsModes.WebBased.toString(),
  },
  {
    module: "Verify: Piceasoft",
    experiences: [
      Experience.Retail,
      Experience.ECom,
      Experience.Mobile,
      Experience.MobileSDK,
    ],
    inspection: Inspections.Software,
    id: SoftwareModes.Piceasoft.toString(),
  },
  {
    module: "Verify: PiceaMobile",
    experiences: [
      Experience.Retail,
      Experience.ECom,
      Experience.Mobile,
      Experience.MobileSDK,
    ],
    inspection: Inspections.Software,
    id: SoftwareModes.PiceaMobile.toString(),
  },
  {
    module: "Data Collection",
    experiences: [Experience.Retail],
    Inspection: Inspections.DataCollection,
    id: DataCollectionModes.Standard.toString(),
  },
  {
    module: "Tools",
    experiences: [Experience.Retail],
    Inspection: Inspections.Erase,
    id: EraseModes.Default.toString(),
  },
  {
    module: "Grade Selection",
    experiences: [Experience.ECom, Experience.Mobile, Experience.MobileSDK],
    Inspection: Inspections.ManualGrading,
    id: ManualGradingModes.Default.toString(),
  },
];

/**
 * Check Device Identification Module access
 * @param experience
 * @param module
 * @returns
 */
export const CheckIdentificationModuleAccess = (
  experience: number,
  module: IdentificationMethods
): boolean =>
  !!DeviceIdentifierModules.find(
    (s_module) =>
      s_module.IdentificationMethod === module &&
      s_module.experiences &&
      s_module.experiences.includes(experience)
  );

export const DeviceIdentifierModules = [
  {
    IdentificationMethod: IdentificationMethods.Global,
    module: "Search in the global catalog",
    experiences: [
      Experience.Retail,
      Experience.ECom,
      Experience.Mobile,
    ],
  },
  {
    module: "PiceaOne WiFi",
    IdentificationMethod: IdentificationMethods.PiceaOne,
    experiences: [Experience.Retail, Experience.ECom],
  },
  {
    module: "PiceaOne USB",
    IdentificationMethod: IdentificationMethods.PiceaUsb,
    experiences: [Experience.Retail],
  },
  {
    module: "Search in the workflow catalog",
    IdentificationMethod: IdentificationMethods.SearchForm,
    experiences: [
      Experience.Retail,
      Experience.ECom,
      Experience.Mobile,
    ],
  },
  {
    module: "Apple by serial",
    IdentificationMethod: IdentificationMethods.Serial,
    experiences: [Experience.Retail],
  },
  {
    module: "Custom search",
    IdentificationMethod: IdentificationMethods.Universal,
    experiences: [Experience.Retail],
  },
  {
    module: "AI device detection",
    IdentificationMethod: IdentificationMethods.Scanner,
    experiences: [
      Experience.Retail,
      Experience.ECom,
      Experience.Mobile,
    ],
  },
  {
    module: "Transaction search",
    IdentificationMethod: IdentificationMethods.Transaction,
    experiences: [Experience.Retail],
  },
  {
    module: "imei",
    IdentificationMethod: IdentificationMethods.Imei,
    experiences: [
      Experience.Retail,
      Experience.ECom,
      Experience.Mobile,
    ],
  },
  {
    module: "sdk",
    IdentificationMethod: IdentificationMethods.SDK,
    experiences: [Experience.MobileSDK],
  },
];

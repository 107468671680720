import * as React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { SpinButton, Stack, getTheme, Text, Icon, PrimaryButton, DefaultButton, StackItem, TooltipHost, DirectionalHint } from '@fluentui/react';
import { DiagnosticCases, ISelfServiceDiagnosticCase, ISelfServiceDiagnosticCaseOptions } from '@piceasoft/core';
import { strings } from '../../../../../../localization/strings';

type TProps = {
    onConfirmCase: (itemId : number, options?: ISelfServiceDiagnosticCaseOptions) => void
    caseElement?: ISelfServiceDiagnosticCase
    options?: ISelfServiceDiagnosticCaseOptions
    handleCancelClick: () => void
    diagnosticType?: number
}

export const SelfServiceDiagnosticCaseOptionsForm: React.FC<TProps> = ({ caseElement, options, onConfirmCase, handleCancelClick, diagnosticType}) => {
    const { control, handleSubmit, reset, formState: { errors } } = useForm<ISelfServiceDiagnosticCaseOptions>({ defaultValues: { ...options } });

    const onSubmit: SubmitHandler<ISelfServiceDiagnosticCaseOptions> = data => {
        if (caseElement) {
            caseElement.options = { ...data }
            onConfirmCase(caseElement.caseId, caseElement.options)
            handleCancelClick()
        }
        handleCancelClick()
    };

    const [errorMessageText, setErrorMessageText] = React.useState('');

    React.useEffect(() => {
        if(!caseElement?.options){
            let opts:ISelfServiceDiagnosticCaseOptions={};
            if(caseElement?.caseId === DiagnosticCases.TC_UPTIME){
                opts.uptime_warning_limit=336
            }
            if(caseElement?.caseId === DiagnosticCases.TC_SLEEPTIME){
                opts.sleep_time_percentage_limit=20
                opts.sleep_time_warning_uptime_limit =24
            }
            if(caseElement?.caseId && [DiagnosticCases.TC_RAM, DiagnosticCases.TC_STORAGE].includes(caseElement.caseId)) {
                opts.limit = 80;
            }
            reset(opts, { keepValues: false })
        }
        reset(options, { keepValues: false })
    }, [options])

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
            <Stack verticalFill style={{ padding: '0 20px' }}>
                <Stack.Item verticalFill style={{ position: 'relative' }}>
                    <Stack>
                        {caseElement?.caseId === DiagnosticCases.TC_UPTIME && (
                            <StackItem>
                                <Controller control={control}
                                    name="uptime_warning_limit"
                                    render={({ field }) =>
                                        <Stack tokens={{ childrenGap: 6 }}>
                                            <Stack.Item>
                                                <Stack horizontal tokens={{ childrenGap: 6 }}>
                                                    <Stack.Item>
                                                        <Text style={{ color: theme.palette.black, fontWeight: 500 }}>{strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.UPTIME_WARNING_LIMIT_LABEL}</Text>
                                                    </Stack.Item>
                                                    <Stack.Item align='end'>
                                                        <TooltipHost
                                                            content={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.UPTIME_WARNING_LIMIT_LABEL}
                                                            directionalHint={DirectionalHint.rightCenter}>
                                                            <Icon iconName="Info" style={{ cursor: 'pointer' }} />
                                                        </TooltipHost>
                                                    </Stack.Item>
                                                </Stack>
                                            </Stack.Item>
                                            <SpinButton {...field}
                                                defaultValue='336'
                                                value={field.value !== undefined && String(field.value) !== '' ? String(field.value) : '336'}
                                                title={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.UPTIME_WARNING_LIMIT_NAME}
                                                placeholder={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.UPTIME_WARNING_LIMIT_PLACEHOLDER}
                                                min={0}
                                                max={Number.MAX_SAFE_INTEGER}
                                                step={1}
                                                onChange={(e, v) => {
                                                    field.onChange(Number(v))
                                                }}
                                            />
                                        </Stack>
                                    }
                                />
                            </StackItem>
                        )}
                        {caseElement?.caseId === DiagnosticCases.TC_SLEEPTIME && (
                            <StackItem>
                                <Controller control={control}
                                    name="sleep_time_percentage_limit"
                                    render={({ field }) =>
                                        <Stack tokens={{ childrenGap: 6 }}>
                                            <Stack.Item>
                                                <Stack horizontal tokens={{ childrenGap: 6 }}>
                                                    <Stack.Item>
                                                        <Text style={{ color: theme.palette.black, fontWeight: 500 }}>{strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.SLEEPTIME_PERCENTAGE_LIMIT_LABEL}</Text>
                                                    </Stack.Item>
                                                    <Stack.Item align='end'>
                                                        <TooltipHost
                                                            content={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.SLEEPTIME_PERCENTAGE_LIMIT_LABEL}
                                                            directionalHint={DirectionalHint.rightCenter}>
                                                            <Icon iconName="Info" style={{ cursor: 'pointer' }} />
                                                        </TooltipHost>
                                                    </Stack.Item>
                                                </Stack>
                                            </Stack.Item>
                                            <SpinButton {...field}
                                                defaultValue='20'
                                                value={field.value !== undefined && String(field.value) !== '' ? String(field.value) : '20'}
                                                title={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.SLEEPTIME_PERCENTAGE_LIMIT_LABEL}
                                                placeholder={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.SLEEPTIME_PERCENTAGE_LIMIT_PLACEHOLDER}
                                                min={0}
                                                max={100}
                                                step={1}
                                                onChange={(e, v) => {
                                                    field.onChange(Number(v))
                                                }}
                                            />
                                        </Stack>
                                    }
                                />
                                <Controller control={control}
                                    name="sleep_time_warning_uptime_limit"
                                    render={({ field }) =>
                                        <Stack tokens={{ childrenGap: 6 }}>
                                            <Stack.Item>
                                                <Stack horizontal tokens={{ childrenGap: 6 }}>
                                                    <Stack.Item>
                                                        <Text style={{ color: theme.palette.black, fontWeight: 500 }}>{strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.SLEEPTIME_WARNING_UPTIME_LIMIT_LABEL}</Text>
                                                    </Stack.Item>
                                                    <Stack.Item align='end'>
                                                        <TooltipHost
                                                            content={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.SLEEPTIME_WARNING_UPTIME_LIMIT_LABEL}
                                                            directionalHint={DirectionalHint.rightCenter}>
                                                            <Icon iconName="Info" style={{ cursor: 'pointer' }} />
                                                        </TooltipHost>
                                                    </Stack.Item>
                                                </Stack>
                                            </Stack.Item>
                                            <SpinButton {...field}
                                                defaultValue='24'
                                                value={field.value !== undefined && String(field.value) !== '' ? String(field.value) : '24'}
                                                title={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.SLEEPTIME_WARNING_UPTIME_LIMIT_LABEL}
                                                placeholder={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.SLEEPTIME_WARNING_UPTIME_LIMIT_PLACEHOLDER}
                                                min={0}
                                                max={Number.MAX_SAFE_INTEGER}
                                                step={1}
                                                onChange={(e, v) => {
                                                    field.onChange(Number(v))
                                                }}
                                            />
                                        </Stack>
                                    }
                                />
                            </StackItem>
                        )}
                        {(caseElement?.caseId === DiagnosticCases.TC_RAM || caseElement?.caseId === DiagnosticCases.TC_STORAGE) && (
                            <StackItem>
                                <Controller control={control}
                                    name="limit"
                                    render={({ field }) =>
                                        <Stack tokens={{ childrenGap: 6 }}>
                                            <Stack.Item>
                                                <Stack horizontal tokens={{ childrenGap: 6 }}>
                                                    <Stack.Item>
                                                        <Text style={{ color: theme.palette.black, fontWeight: 500 }}>{strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.LIMIT_LABEL}</Text>
                                                    </Stack.Item>
                                                    <Stack.Item align='end'>
                                                        <TooltipHost 
                                                            content={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.LIMIT_LABEL}
                                                            directionalHint={DirectionalHint.rightCenter}>
                                                            <Icon iconName="Info" style={{ cursor: 'pointer' }} />
                                                        </TooltipHost>
                                                    </Stack.Item>
                                                </Stack>
                                            </Stack.Item>    
                                            <SpinButton {...field}
                                                defaultValue='80'
                                                value={field.value !== undefined && String(field.value) !== '' ? String(field.value): '80'}
                                                title={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.LIMIT_NAME}
                                                placeholder={strings.CONSTRUCTOR.INSPECTIONS.DIAGNOSTICS.MODE_CONFIGURATOR.TEST.TEST_RUN_OPTIONS.LIMIT_NAME_PLACEHOLDER}
                                                min={0}
                                                max={100}
                                                step={1}
                                                onChange={(e, v) => {
                                                    field.onChange(v)
                                                }}
                                            />
                                        </Stack>    
                                    }
                                />
                            </StackItem>
                        )}
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8, padding: '20px 0' }}>
                        <PrimaryButton onClick={handleSubmit(onSubmit)} disabled={errorMessageText ? true : false} text={strings.BUTTONS.TEXT.CONFIRM} />
                        <DefaultButton text={strings.BUTTONS.TEXT.CANCEL} onClick={() => handleCancelClick()} />
                    </Stack>
                </Stack.Item>
            </Stack>
        </form>
    )
}

const theme = getTheme();
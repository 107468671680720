import { Stack, ScrollablePane, IDropdownOption, Text, ChoiceGroup, ProgressIndicator, TextField } from "@fluentui/react";
import React, { Dispatch } from "react";
import { useSelector } from "react-redux";
import { actionCreators } from "../../../../../../../core/actions/configurator-actions";
import { portalApi } from "../../../../../../../core/api/api";
import { IStore } from "../../../../../../../core/store";
import { DeviceTypes } from "@piceasoft/core";
import { strings } from "../../../../../../../localization/strings";
import { Section } from "../../../../../decorations/Section";
import { SearchResultsWithTagPicker } from "../../../../../search/SearchResultsWithTagPicker";
import { SomethingWentWrong } from "../../../../../somethingWentWrong/SomethingWentWrong";
import { ParameterItem } from "../../../../components/ParameterItem";
import { IIdentificationSearchFormConfig } from "@piceasoft/core";

type TProps = {
    index: number
    onChangeDispatch: Dispatch<any>
}

export const SearchCommonSettingsPivot: React.FC<TProps> = (props) => {
    const config = useSelector((s: IStore) => s.configurator.identification.items.find(i => i.index === props.index) as IIdentificationSearchFormConfig)

    const [error, setError] = React.useState<string>()
    const [manufacturers, setManufacturers] = React.useState<string[]>()

    const typesOptions: IDropdownOption[] = [
        {
            key: DeviceTypes.DEVICE_TYPE_MOBILE,
            text: strings.CONSTRUCTOR.IDENTIFICATION.SEARCH_FORM.PIVOTS.COMMON.TYPES.MOBILE
        },        
        {
            key: DeviceTypes.DEVICE_TYPE_WATCH,
            text: strings.CONSTRUCTOR.IDENTIFICATION.SEARCH_FORM.PIVOTS.COMMON.TYPES.WATCH
        },
    ]

    React.useEffect(() => {
        if (config.manufacturers) {
            getManufacturers()
        }
    }, [config.manufacturers])

    const getManufacturers = async () => {
        setError(undefined)
        const result = await portalApi.organization.devices.getManufacturers()
        console.log(result)
        if (result.successed) {
            setTimeout(() => {
                setManufacturers(result.data)
            }, 500)
        }
        if (result.errors && result.errors.length > 0) {
            setError(result.errors[0].description)
        }
    }

    const onIncludeManufacturer = (manufacturer: string) => {
        console.log('onIncludeManufacturer')
        props.onChangeDispatch(actionCreators.identification.editModule({
            ...config,
            manufacturers: {
                ...config.manufacturers,
                include: config.manufacturers?.include ? [...config.manufacturers?.include?.filter(i => i !== manufacturer.trim()), manufacturer.trim()] : [manufacturer.trim()]
            }
        } as IIdentificationSearchFormConfig))
    }

    const onExcludeManufacturer = (manufacturer: string) => {
        console.log('onExcludeManufacturer')
        props.onChangeDispatch(actionCreators.identification.editModule({
            ...config,
            manufacturers: {
                ...config.manufacturers,
                exclude: config.manufacturers?.exclude ? [...config.manufacturers?.exclude?.filter(i => i !== manufacturer.trim()), manufacturer.trim()] : [manufacturer.trim()]
            }
        } as IIdentificationSearchFormConfig))
    }

    const onRemoveIncludedManufacturer = (manufacturer: string) => {
        props.onChangeDispatch(actionCreators.identification.editModule({
            ...config,
            manufacturers: {
                ...config.manufacturers,
                include: config.manufacturers?.include?.filter(i => i !== manufacturer.trim())
            }
        } as IIdentificationSearchFormConfig))
    }

    const onRemoveExcludedManufacturer = (manufacturer: string) => {
        props.onChangeDispatch(actionCreators.identification.editModule({
            ...config,
            manufacturers: {
                ...config.manufacturers,
                exclude: config.manufacturers?.exclude?.filter(i => i !== manufacturer.trim())
            }
        } as IIdentificationSearchFormConfig))
    }

    // const getTextFromItem = (item: ITag): string => {
    //     return item.name;
    // };

    // const onGetExcluded = React.useCallback((filterText: string, tagList?: ITag[]): ITag[] => {
    //     return config.manufacturers?.exclude?.map(i => { return { key: i, name: i } }) as ITag[];
    // }, []);

    // const onGetIncluded = React.useCallback((filterText: string, tagList?: ITag[]): ITag[] => {
    //     return config.manufacturers?.include?.map(i => { return { key: i, name: i } }) as ITag[];
    // }, []);

    return (
        <Stack.Item verticalFill style={{ position: 'relative' }}>
            {!error && (
            <ScrollablePane>
                <Section title={strings.CONSTRUCTOR.IDENTIFICATION.COMMON.PIVOTS.UI.HEADER} max flat>
                    <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.SEARCH_FORM.PIVOTS.COMMON.MANUFACTURERS_TITLE}
                        useToggle
                        toggleEnabled
                        toggleChecked={config.manufacturers?.include || config.manufacturers?.exclude ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                includeManufacturers: true,
                                manufacturers: config.manufacturers?.include || config.manufacturers?.exclude ? {} : {
                                  include: [],
                                }
                            } as IIdentificationSearchFormConfig))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.SEARCH_FORM.PIVOTS.COMMON.MANUFACTURERS_DESCRIPTION}
                    >
                        {(config.manufacturers?.include || config.manufacturers?.exclude) && (
                            <Stack tokens={{ childrenGap: 8 }}>
                                <ChoiceGroup
                                    selectedKey={config.manufacturers?.include ? "include" : config.manufacturers?.exclude ? "exclude" : ""}
                                    options={[
                                        { text: strings.CONSTRUCTOR.IDENTIFICATION.SEARCH_FORM.PIVOTS.COMMON.MANUFACTURERS_INCLUDED, key: "include", styles: { field: { fontSize: 12 } } },
                                        { text: strings.CONSTRUCTOR.IDENTIFICATION.SEARCH_FORM.PIVOTS.COMMON.MANUFACTURERS_EXCLUDED, key: "exclude", styles: { field: { fontSize: 12 } } }
                                    ]}
                                    onChange={(ev, opt) => opt && props.onChangeDispatch(actionCreators.identification.editModule({
                                        ...config,
                                        manufacturers: opt.key === "include" ? {
                                          include: config.manufacturers?.exclude,
                                        } : opt.key === "exclude" ? {
                                          exclude: config.manufacturers?.include
                                        } : {},
                                        includeManufacturers: opt.key === "include"
                                    } as IIdentificationSearchFormConfig))
                                    }
                                />
                                {manufacturers && (
                                    <SearchResultsWithTagPicker
                                        items={manufacturers?.map(i => { return { key: i, name: i } }) ?? []}
                                        inputProps={{ 'aria-label': 'Manufacturers Picker', placeholder: strings.CONSTRUCTOR.IDENTIFICATION.SEARCH_FORM.PIVOTS.COMMON.MANUFACTURERS_PLACEHOLDER }}
                                        selectionAriaLabel={config.includeManufacturers ? "Included manufacturers" : "Excluded manufacturers"}
                                        onItemSelected={i => i && (config.includeManufacturers ? onIncludeManufacturer(i.name) : onExcludeManufacturer(i.name)) || null}
                                        onRemoveSelectedItem={i => config.includeManufacturers ? onRemoveIncludedManufacturer(i.name) : onRemoveExcludedManufacturer(i.name)}
                                        selectedItems={config.includeManufacturers ?
                                            (config.manufacturers.include?.map(i => { return { key: i, name: i } }) ?? []) :
                                            (config.manufacturers.exclude?.map(i => { return { key: i, name: i } }) ?? [])
                                        }
                                        pickerSuggestionsProps={{
                                            suggestionsHeaderText: strings.CONSTRUCTOR.IDENTIFICATION.SEARCH_FORM.PIVOTS.COMMON.PICKER_SUGGESTIONS_HEADER,
                                            noResultsFoundText: strings.CONSTRUCTOR.IDENTIFICATION.SEARCH_FORM.PIVOTS.COMMON.PICKER_NO_RESULTS_TEXT,
                                        }}
                                    />
                                ) || (
                                        <TextField placeholder={strings.SPINNERS.DATA_IS_LOADING} disabled={true} />
                                    )}
                            </Stack>
                        )}
                    </ParameterItem>
                    <ParameterItem title={strings.CONSTRUCTOR.IDENTIFICATION.SEARCH_FORM.PIVOTS.COMMON.TYPES_TITLE}
                        useToggle
                        toggleEnabled
                        toggleChecked={config.types !== undefined ? true : false}
                        toggleOnChange={() => {
                            props.onChangeDispatch(actionCreators.identification.editModule({
                                ...config,
                                types: config.types !== undefined ? undefined : []
                            } as IIdentificationSearchFormConfig))
                        }}
                        description={strings.CONSTRUCTOR.IDENTIFICATION.SEARCH_FORM.PIVOTS.COMMON.TYPES_DESCRIPTION}
                        useDropdown
                        dropdownMultiselect
                        dropdownEnabled={config.types !== undefined ? true : false}
                        dropdownSelectedKeys={config.types}
                        dropdownOnChange={(opt) => props.onChangeDispatch(actionCreators.identification.editModule({
                            ...config,
                            types: opt?.selected ? (config.types ? [...config.types, opt.key] : [opt.key]) : config.types?.filter(i => i !== opt?.key)
                        } as IIdentificationSearchFormConfig))}
                        dropdownPlaceholder={strings.CONSTRUCTOR.IDENTIFICATION.SEARCH_FORM.PIVOTS.COMMON.TYPES_PLACEHOLDER}
                        dropdownOptions={typesOptions}
                    />
                </Section>
            </ScrollablePane>
            )|| (
                <SomethingWentWrong action={getManufacturers} />
            )}
        </Stack.Item >
    )
}
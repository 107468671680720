import { Stack } from "@fluentui/react"
import React, { Dispatch } from "react";
import { useSelector } from "react-redux";
import { actionCreators } from "../../../../../core/actions/configurator-actions";
import { IStore } from "../../../../../core/store";
import { strings } from "../../../../../localization/strings";
import { ParameterItem } from "../../components/ParameterItem";
import { CheckProcessStageSectionOptionAccess } from "../../helpers/evisibility";
import { IStageConfig, ProcessStages, IStageConfigUI } from "@piceasoft/core";

type TProps = {
    config: IStageConfig<IStageConfigUI>
    onChangeDispatch: Dispatch<any>
    stage: ProcessStages
    withoutNextTeaching?: boolean
    withoutNext?: boolean
    withBack?: boolean
    channel: number
}

export const StageCommonButtonsSettingsPivot: React.FC<TProps> = ({ onChangeDispatch, config, stage, children, withoutNextTeaching, withoutNext, withBack, channel }) => {

    return (
        <Stack style={{ padding: "0px 0px" }}>
            { !withoutNext && (
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.UI.BUTTONS.NEXT.TEXT_TITLE}
                noSeparator
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(channel, stage, 'Buttons', 'Custom Next button caption')}
                toggleChecked={config.ui?.buttons?.next?.text !== undefined ? true : false}
                toggleOnChange={() => {
                    console.log('changed')
                    onChangeDispatch(actionCreators.editStage(stage, {
                        ...config,
                        ui: {
                            ...config.ui,
                            buttons: {
                                ...config.ui?.buttons,
                                next: {
                                    ...config.ui?.buttons?.next,
                                    text: config.ui?.buttons?.next?.text !== undefined ? undefined : ""
                                }
                            }
                        }
                    } as IStageConfig<IStageConfigUI>
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.UI.BUTTONS.NEXT.TEXT_DESCRIPTION}
                useTextField
                textFieldEnabled={config.ui?.buttons?.next?.text !== undefined ? true : false}
                textFieldValue={config.ui?.buttons?.next?.text}
                textFieldOnChange={(val) => onChangeDispatch(actionCreators.editStage(stage, {
                    ...config,
                    ui: {
                        ...config.ui,
                        buttons: {
                            ...config.ui?.buttons,
                            next: {
                                ...config.ui?.buttons?.next,
                                text: val
                            }
                        }
                    }
                } as IStageConfig<IStageConfigUI>
                ))
                }
            />)}
            { !withoutNext && !withoutNextTeaching && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.UI.BUTTONS.NEXT.TEACHING_TITLE}
                    useToggle
                    toggleEnabled={CheckProcessStageSectionOptionAccess(channel, stage, 'Buttons', 'Custom hint text')}
                    toggleChecked={config.ui?.buttons?.next?.teaching !== undefined ? true : false}
                    toggleOnChange={() => {
                        onChangeDispatch(actionCreators.editStage(stage, {
                            ...config,
                            ui: {
                                ...config.ui,
                                buttons: {
                                    ...config.ui?.buttons,
                                    next: {
                                        ...config.ui?.buttons?.next,
                                        teaching: config.ui?.buttons?.next?.teaching !== undefined ? undefined : ""
                                    }
                                }
                            }
                        } as IStageConfig<IStageConfigUI>
                        ))
                    }}
                    description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.UI.BUTTONS.NEXT.TEACHING_DESCRIPTION}
                    useTextField
                    textFieldEnabled={config.ui?.buttons?.next?.teaching !== undefined ? true : false}
                    textFieldValue={config.ui?.buttons?.next?.teaching}
                    textFieldOnChange={(val) => onChangeDispatch(actionCreators.editStage(stage, {
                        ...config,
                        ui: {
                            ...config.ui,
                            buttons: {
                                ...config.ui?.buttons,
                                next: {
                                    ...config.ui?.buttons?.next,
                                    teaching: val
                                }
                            }
                        }
                    } as IStageConfig<IStageConfigUI>
                    ))
                    }
                />
            )}
            {stage !== ProcessStages.Result && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.UI.BUTTONS.CANCEL.TEXT_TITLE}
                    useToggle
                    toggleEnabled={CheckProcessStageSectionOptionAccess(channel, stage, 'Buttons', 'Custom Cancel button caption')}
                    toggleChecked={config.ui?.buttons?.cancel?.text !== undefined ? true : false}
                    toggleOnChange={() => {
                        onChangeDispatch(actionCreators.editStage(stage, {
                            ...config,
                            ui: {
                                ...config.ui,
                                buttons: {
                                    ...config.ui?.buttons,
                                    cancel: {
                                        ...config.ui?.buttons?.cancel,
                                        text: config.ui?.buttons?.cancel?.text !== undefined ? undefined : ""
                                    }
                                }
                            }
                        } as IStageConfig<IStageConfigUI>
                        ))
                    }}
                    description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.UI.BUTTONS.CANCEL.TEXT_DESCRIPTION}
                    useTextField
                    textFieldEnabled={config.ui?.buttons?.cancel?.text !== undefined ? true : false}
                    textFieldValue={config.ui?.buttons?.cancel?.text}
                    textFieldOnChange={(val) => onChangeDispatch(actionCreators.editStage(stage, {
                        ...config,
                        ui: {
                            ...config.ui,
                            buttons: {
                                ...config.ui?.buttons,
                                cancel: {
                                    ...config.ui?.buttons?.cancel,
                                    text: val
                                }
                            }
                        }
                    } as IStageConfig<IStageConfigUI>
                    ))
                    }
                />
            )}
            {withBack && (
                <ParameterItem title={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.UI.BUTTONS.BACK.TEXT_TITLE}
                    useToggle
                    toggleEnabled={CheckProcessStageSectionOptionAccess(channel, stage, 'Buttons', 'Custom Back button caption')}
                    toggleChecked={config.ui?.buttons?.back?.text !== undefined ? true : false}
                    toggleOnChange={() => {
                        onChangeDispatch(actionCreators.editStage(stage, {
                            ...config,
                            ui: {
                                ...config.ui,
                                buttons: {
                                    ...config.ui?.buttons,
                                    back: {
                                        ...config.ui?.buttons?.back,
                                        text: config.ui?.buttons?.back?.text !== undefined ? undefined : ""
                                    }
                                }
                            }
                        } as IStageConfig<IStageConfigUI>
                        ))
                    }}
                    description={strings.CONSTRUCTOR.STAGES.COMMON.PIVOTS.UI.BUTTONS.BACK.TEXT_DESCRIPTION}
                    useTextField
                    textFieldEnabled={config.ui?.buttons?.back?.text !== undefined ? true : false}
                    textFieldValue={config.ui?.buttons?.back?.text}
                    textFieldOnChange={(val) => onChangeDispatch(actionCreators.editStage(stage, {
                        ...config,
                        ui: {
                            ...config.ui,
                            buttons: {
                                ...config.ui?.buttons,
                                back: {
                                    ...config.ui?.buttons?.back,
                                    text: val
                                }
                            }
                        }
                    } as IStageConfig<IStageConfigUI>
                    ))
                    }
                />
            )}
            {children}
        </Stack>
    )
}
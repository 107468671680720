import { Dispatch } from "react";
import { Stack } from "@fluentui/react"
import { ISelfServiceWorkflowConfig, IRepairOfferConfig, ProcessStages } from "@piceasoft/core";
import { ParameterItem } from "../../../components/ParameterItem";
import { CheckProcessStageSectionOptionAccess } from "../../../helpers/evisibility";
import { actionCreators } from "../../../../../../core/actions/configurator-actions";
import { strings } from "../../../../../../localization/strings";

type TProps = {
    config?: ISelfServiceWorkflowConfig
    onChangeDispatch: Dispatch<any>
    stage: ProcessStages
    channel: number
}

export const SelfServiceRecommendationsSettingsPivot: React.FC<TProps> = ({ ...props }) => {

    const maxTitleCharacters = 100
    const maxDescriptionCharacters = 300

    return (
        <Stack style={{ padding: "0px 0px" }}>
            <ParameterItem title={strings.CONSTRUCTOR.STAGES.SELFSERVICE.PIVOTS.REPAIROFFERS.UI.RECOMMENDATIONS.CUSTOMTITLE.TITLE}
                noSeparator
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel, props.stage, 'Repair offer', 'Custom Recommendations title')}
                toggleChecked={props.config?.repairOffers.ui?.recommendations?.customTitle !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.selfService.editRepairOfferConfigs({
                        ...props.config?.repairOffers,
                        ui: {
                            ...props.config?.repairOffers.ui,
                            recommendations: {
                                ...props.config?.repairOffers.ui?.recommendations,
                                customTitle: props.config?.repairOffers.ui?.recommendations?.customTitle !== undefined ? undefined : ""
                            }
                        }
                    } as IRepairOfferConfig
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.SELFSERVICE.PIVOTS.REPAIROFFERS.UI.RECOMMENDATIONS.CUSTOMTITLE.TITLE_DESCRIPTION}
                useTextField
                textFieldEnabled={props.config?.repairOffers.ui?.recommendations?.customTitle !== undefined ? true : false}
                textFieldValue={props.config?.repairOffers.ui?.recommendations?.customTitle}
                textFieldMaxLength={maxTitleCharacters}
                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.selfService.editRepairOfferConfigs({
                    ...props.config?.repairOffers,
                    ui: {
                        ...props.config?.repairOffers.ui,
                        recommendations: {
                            ...props.config?.repairOffers.ui?.recommendations,
                            customTitle: val
                        }
                    }
                } as IRepairOfferConfig
                ))}
            />

            <ParameterItem title={strings.CONSTRUCTOR.STAGES.SELFSERVICE.PIVOTS.REPAIROFFERS.UI.RECOMMENDATIONS.CUSTOMOFFERDESCRIPTION.TITLE}
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel, props.stage, 'Repair offer', 'Custom repair offer and promotions description')}
                toggleChecked={props.config?.repairOffers.ui?.recommendations?.customOfferDescription !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.selfService.editRepairOfferConfigs({
                        ...props.config?.repairOffers,
                        ui: {
                            ...props.config?.repairOffers.ui,
                            recommendations: {
                                ...props.config?.repairOffers.ui?.recommendations,
                                customOfferDescription: props.config?.repairOffers.ui?.recommendations?.customOfferDescription !== undefined ? undefined : ""
                            }
                        }
                    } as IRepairOfferConfig
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.SELFSERVICE.PIVOTS.REPAIROFFERS.UI.RECOMMENDATIONS.CUSTOMOFFERDESCRIPTION.TITLE_DESCRIPTION}
                useTextField
                textFieldEnabled={props.config?.repairOffers.ui?.recommendations?.customOfferDescription !== undefined ? true : false}
                textFieldValue={props.config?.repairOffers.ui?.recommendations?.customOfferDescription}
                textFieldMaxLength={maxDescriptionCharacters}
                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.selfService.editRepairOfferConfigs({
                    ...props.config?.repairOffers,
                    ui: {
                        ...props.config?.repairOffers.ui,
                        recommendations: {
                            ...props.config?.repairOffers.ui?.recommendations,
                            customOfferDescription: val
                        }
                    }
                } as IRepairOfferConfig
                ))}
            />

            <ParameterItem title={strings.CONSTRUCTOR.STAGES.SELFSERVICE.PIVOTS.REPAIROFFERS.UI.RECOMMENDATIONS.CUSTOMPROMOTIONDESCRIPTION.TITLE}
                useToggle
                toggleEnabled={CheckProcessStageSectionOptionAccess(props.channel, props.stage, 'Repair offer', 'Custom promotions description')}
                toggleChecked={props.config?.repairOffers.ui?.recommendations?.customPromotionDescription !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.selfService.editRepairOfferConfigs({
                        ...props.config?.repairOffers,
                        ui: {
                            ...props.config?.repairOffers.ui,
                            recommendations: {
                                ...props.config?.repairOffers.ui?.recommendations,
                                customPromotionDescription: props.config?.repairOffers.ui?.recommendations?.customPromotionDescription !== undefined ? undefined : ""
                            }
                        }
                    } as IRepairOfferConfig
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.SELFSERVICE.PIVOTS.REPAIROFFERS.UI.RECOMMENDATIONS.CUSTOMPROMOTIONDESCRIPTION.TITLE_DESCRIPTION}
                useTextField
                textFieldEnabled={props.config?.repairOffers.ui?.recommendations?.customPromotionDescription !== undefined ? true : false}
                textFieldValue={props.config?.repairOffers.ui?.recommendations?.customPromotionDescription}
                textFieldMaxLength={maxDescriptionCharacters}
                textFieldOnChange={(val) => props.onChangeDispatch(actionCreators.selfService.editRepairOfferConfigs({
                    ...props.config?.repairOffers,
                    ui: {
                        ...props.config?.repairOffers.ui,
                        recommendations: {
                            ...props.config?.repairOffers.ui?.recommendations,
                            customPromotionDescription: val
                        }
                    }
                } as IRepairOfferConfig
                ))}
            />

            <ParameterItem title={strings.CONSTRUCTOR.STAGES.SELFSERVICE.PIVOTS.REPAIROFFERS.UI.RECOMMENDATIONS.DISCLAIMER.TITLE}
                useToggle
                toggleEnabled={props.config?.disclaimers && props.config?.disclaimers.length > 0 && CheckProcessStageSectionOptionAccess(props.channel, props.stage, 'Repair offer', 'Show a disclaimer') }
                toggleChecked={props.config?.repairOffers.disclaimerId !== null ? true : false}
                toggleOnChange={() => {
                    props.onChangeDispatch(actionCreators.selfService.editRepairOfferConfigs({
                        ...props.config?.repairOffers,
                        disclaimerId: props.config?.repairOffers.disclaimerId !== null ? null : -1
                    } as IRepairOfferConfig
                    ))
                }}
                description={strings.CONSTRUCTOR.STAGES.SELFSERVICE.PIVOTS.REPAIROFFERS.UI.RECOMMENDATIONS.DISCLAIMER.TITLE_DESCRIPTION}
                useDropdown={props.config?.repairOffers.disclaimerId !== null ? true : false}
                dropdownLabel={strings.CONSTRUCTOR.STAGES.SELFSERVICE.PIVOTS.REPAIROFFERS.UI.RECOMMENDATIONS.DISCLAIMER.DROPDOWN.LABEL}
                dropdownEnabled={props.config?.disclaimers && props.config?.disclaimers.length > 0}
                dropdownSelectedKey={props.config?.repairOffers.disclaimerId}
                dropdownOnChange={(opt) => props.onChangeDispatch(actionCreators.selfService.editRepairOfferConfigs({
                    ...props.config?.repairOffers,
                    disclaimerId: opt?.key
                } as IRepairOfferConfig
                ))}
                dropdownPlaceholder={strings.CONSTRUCTOR.STAGES.SELFSERVICE.PIVOTS.REPAIROFFERS.UI.RECOMMENDATIONS.DISCLAIMER.DROPDOWN.PLACEHOLDER}
                dropdownOptions={(props.config?.disclaimers ?? []).map((i) => ({ key: i.id, text: `${i.id} ${i.name}` }))}
                dropdownTooltipTitle={strings.CONSTRUCTOR.STAGES.SELFSERVICE.PIVOTS.REPAIROFFERS.UI.RECOMMENDATIONS.DISCLAIMER.DROPDOWN.TITLE}
                renderLabel={true}
                isRequired={true}
            />
        </Stack>
    )
}
import { FontSizes, FontWeights, getTheme, IStackStyles, Stack } from '@fluentui/react';
import * as React from 'react';
import System from './System';

type FooterProps = {
    code?: string
    point?: string
    supportTel?: string
}

const Footer: React.FC<FooterProps> = (props) => {
    return (
        <Stack horizontal verticalAlign="center" styles={footerStyles} tokens={{ childrenGap: 4 }}>
            <Stack.Item>
                <System />
            </Stack.Item>
            {/* <Stack.Item>
                <Tutorial />
            </Stack.Item> */}
            <Stack.Item grow styles={{ root: { fontSize: FontSizes.small } }}>
                <span> </span>
            </Stack.Item>
            {/* <Stack.Item>
                <Support />
            </Stack.Item> */}
        </Stack>
    )
}

export default Footer

const theme = getTheme();
const footerStyles: IStackStyles = {
    root: {
        fontSize: FontSizes.large,
        fontWeight: FontWeights.semibold,
        zIndex: 20,
        backgroundColor: theme.palette.white,
        boxShadow: "rgba(0, 0, 0, .08) 0 -1px 0",
        height: 28
    }
};

import * as React from 'react'
import { IDropdownOption } from "@fluentui/react";
import { useSelector } from 'react-redux'
import { ISoftwarePiceaConfig, IStore } from '../../../../../../../../core/store'
import { IGrade, SoftwareModes, SoftwareResultDisplayModes } from "@piceasoft/core"
import { strings } from '../../../../../../../../localization/strings'
import { Section } from '../../../../../../decorations/Section'
import { ParameterItem } from '../../../../../components/ParameterItem'

export type TPiceaSettingsConfiguratorModePivotProps = {
    config: ISoftwarePiceaConfig
    mode: SoftwareModes
    gradesCategory?: string
    errorSoftwareMessage?: string
    onModeConfirm: (config: ISoftwarePiceaConfig) => void
    withoutGrades?: boolean
}

export const PiceaSettingsConfiguratorModePivot: React.FC<TPiceaSettingsConfiguratorModePivotProps> = (props) => {

    const grades = (props.gradesCategory
        ? useSelector((s: IStore) => s.configurator.gradesCategories?.find(i => i.code === props.gradesCategory))?.grades
        : useSelector((s: IStore) => s.configurator.grades)) as IGrade[]

    console.log(props.gradesCategory)
    console.log(grades)
    
    return (
        <Section flat max>
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.SOFTWARE.MODE_CONFIGURATOR.ALLOW_FAIL_TITLE}
                useToggle
                noSeparator
                toggleEnabled
                toggleChecked={props.config.allowFail}
                toggleOnChange={() => {
                    props.onModeConfirm({
                        ...props.config,
                        allowFail: props.config.allowFail !== undefined ? undefined : true
                    })
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.SOFTWARE.MODE_CONFIGURATOR.ALLOW_FAIL_DESCRIPTION}
            />
            {!props.withoutGrades && (
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.SOFTWARE.MODE_CONFIGURATOR.DOWN_GRADE_TITLE}
                useToggle={true}
                toggleEnabled
                toggleChecked={props.config.downGrade !== undefined ? true : false}
                toggleOnChange={() => {
                    props.onModeConfirm({
                        ...props.config,
                        downGrade: props.config.downGrade !== undefined ? undefined : ""
                    })
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.SOFTWARE.MODE_CONFIGURATOR.DOWN_GRADE_DESCRIPTION}
                useDropdown={props.config.downGrade !== undefined ? true : false}
                dropdownEnabled={grades && grades.length > 0}
                dropdownSelectedKey={props.config.downGrade}
                dropdownOnChange={(opt) => props.onModeConfirm({
                    ...props.config,
                    downGrade: opt?.key as string
                })}
                dropdownPlaceholder={(grades && grades.length > 0) ? strings.CONSTRUCTOR.INSPECTIONS.SOFTWARE.MODE_CONFIGURATOR.DOWN_GRADE_PLACEHOLDER : strings.CONSTRUCTOR.INSPECTIONS.SOFTWARE.MODE_CONFIGURATOR.DOWN_GRADE_PLACEHOLDER_NO_GRADES}
                dropdownOptions={(grades ?? []).map(i => ({ key: i.code, text: `${i.code} ${i.name}` }))}
                errorMessage={props.errorSoftwareMessage}
            />
            )}
            <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.SOFTWARE.MODE_CONFIGURATOR.ALWAYS_INSTALL_MDM_TITLE}
                useToggle
                noSeparator
                toggleEnabled
                toggleChecked={props.config.alwaysMdmInstall}
                toggleOnChange={() => {
                    props.onModeConfirm({
                        ...props.config,
                        alwaysMdmInstall: props.config.alwaysMdmInstall !== undefined ? undefined : true
                    })
                }}
                description={strings.CONSTRUCTOR.INSPECTIONS.SOFTWARE.MODE_CONFIGURATOR.ALWAYS_INSTALL_MDM_DESCRIPTION}
            />
            { SoftwareModes.PiceaMobile === props.mode && 
                <ParameterItem title={strings.CONSTRUCTOR.INSPECTIONS.SOFTWARE.MODE_CONFIGURATOR.SHOW_RESULTS_TITLE}
                    useDropdown
                    dropdownEnabled
                    dropdownOptions={ getResultDisplayOptions()}
                    dropdownOnChange={ (option?: IDropdownOption) => {
                        option && props.onModeConfirm({
                            ...props.config,
                            resultDisplayOption: option.key as SoftwareResultDisplayModes
                        })
                    }}
                    dropdownSelectedKey={ (undefined === props.config.resultDisplayOption) ? SoftwareResultDisplayModes.DisplayInBoth : props.config.resultDisplayOption}
            />}
        </Section>
    )
}

const getResultDisplayOptions = ():IDropdownOption[] => {
    const options: IDropdownOption[] = [
        { key: SoftwareResultDisplayModes.DisplayInMobileApp, text: strings.CONSTRUCTOR.INSPECTIONS.SOFTWARE.MODE_CONFIGURATOR.SHOW_RESULTS_APP},
        { key: SoftwareResultDisplayModes.DisplayInWebApp, text: strings.CONSTRUCTOR.INSPECTIONS.SOFTWARE.MODE_CONFIGURATOR.SHOW_RESULTS_WEB},
        { key: SoftwareResultDisplayModes.DisplayInBoth, text: strings.CONSTRUCTOR.INSPECTIONS.SOFTWARE.MODE_CONFIGURATOR.SHOW_RESULTS_BOTH},
    ];
    return options;
}
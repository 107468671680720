import { Dropdown, getTheme, IDropdownOption, Link, PrimaryButton, Spinner, Stack, Text, TextField, Toggle } from "@fluentui/react"
import { useBoolean } from "@uifabric/react-hooks"
import * as React from "react"
import { SecondaryButton } from "../../../../../components/shared/buttons/SecondaryButton"
import { ParameterItem } from "../../../../../components/shared/configurator/components/ParameterItem"
import { Section } from "../../../../../components/shared/decorations/Section"
import { portalApi } from "../../../../../core/api/api"
import { IApplicationOptions } from "@piceasoft/core";
import { strings } from "../../../../../localization/strings"

type TProps = {
    disabled: boolean
    settingsOptions: IApplicationOptions
    updateOptions: (options: IApplicationOptions) => void
    isFetching?: boolean
}

export const StructureElementSettings: React.FC<TProps> = ({ disabled, settingsOptions, updateOptions, isFetching }) => {

    const [options, setOptions] = React.useState<IApplicationOptions>(settingsOptions)
    const [dropDownOptions, setDropDownOptions] = React.useState<IDropdownOption[]>([])

    React.useEffect(() => {
        getBrandingsThemes()
    }, []);

    const getBrandingsThemes = async () => {
        const result = await portalApi.organization.branding.getBrandings()
        setDropDownOptions
        const options: IDropdownOption[] = []
        result.data?.forEach((elem) => {
            if (elem.id) {
                const option = { key: elem.id, text: elem.name }
                options.push(option)
            }
        })
        setDropDownOptions(options)
    }

    return (
        <>
            <Stack tokens={{ childrenGap: 24 }}>
                <Section max title={strings.ORGANIZATION.OPTIONS.APPLICATION_TITLE} description={strings.ORGANIZATION.OPTIONS.APPLICATION_DESCRIPTION}>
                    <Stack>
                        <Stack.Item styles={{ root: { borderTop: `1px solid ${theme.palette.neutralLight}` } }}>
                            <Stack horizontal tokens={{ padding: 20 }}>
                                <Stack.Item styles={{ root: { minWidth: 140 } }}>
                                    <Toggle onText={strings.BUTTONS.TOGGLE_ON} offText={strings.BUTTONS.TOGGLE_OFF}
                                        disabled={disabled}
                                        checked={options.help?.enabled}
                                        onChange={() => setOptions({ ...options, help: { ...options.help, enabled: !options.help?.enabled } })}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <Stack tokens={{ childrenGap: 16 }}>
                                        <Stack styles={{ root: { maxWidth: 400 } }}>
                                            <Text style={{ fontWeight: 600, color: theme.palette.black }}>{strings.ORGANIZATION.OPTIONS.HELP.TITLE}</Text>
                                            <Text variant="small" style={{ color: theme.palette.neutralSecondary }}>
                                                {strings.ORGANIZATION.OPTIONS.HELP.DESCRIPTION}
                                            </Text>
                                        </Stack>
                                        <Stack.Item styles={{ root: { maxWidth: 320 } }}>
                                            <TextField
                                                disabled={disabled || !options.help?.enabled}
                                                placeholder={strings.ORGANIZATION.OPTIONS.HELP.LABEL_PLACEHOLDER}
                                                value={options.help?.label}
                                                onChange={(ev, value) => setOptions({ ...options, help: { ...options.help, label: value ?? "" } })}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>

                        <Stack.Item styles={{ root: { borderTop: `1px solid ${theme.palette.neutralLight}` } }}>
                            <Stack horizontal tokens={{ padding: 20 }}>
                                <Stack.Item styles={{ root: { minWidth: 140 } }}>
                                    <Toggle onText={strings.BUTTONS.TOGGLE_ON} offText={strings.BUTTONS.TOGGLE_OFF}
                                        disabled={disabled}
                                        checked={options.support?.enabled}
                                        onChange={() => setOptions({ ...options, support: { ...options.support, enabled: !options.support?.enabled } })}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <Stack tokens={{ childrenGap: 16 }}>
                                        <Stack styles={{ root: { maxWidth: 400 } }}>
                                            <Text style={{ fontWeight: 600, color: theme.palette.black }}>{strings.ORGANIZATION.OPTIONS.SUPPORT.TITLE}</Text>
                                            <Text variant="small" style={{ color: theme.palette.neutralSecondary }}>
                                                {strings.ORGANIZATION.OPTIONS.SUPPORT.DESCRIPTION}
                                            </Text>
                                        </Stack>
                                        <Stack.Item styles={{ root: { maxWidth: 320 } }}>
                                            <TextField
                                                disabled={disabled || !options.support?.enabled}
                                                // label={strings.ORGANIZATION.OPTIONS.SUPPORT.LABEL_LABEL}
                                                // title={strings.ORGANIZATION.OPTIONS.SUPPORT.LABEL_TITLE}
                                                placeholder={strings.ORGANIZATION.OPTIONS.SUPPORT.LABEL_PLACEHOLDER}
                                                value={options.support?.label}
                                                onChange={(ev, value) => setOptions({ ...options, support: { ...options.support, label: value ?? "" } })}
                                            />
                                        </Stack.Item>
                                        <Stack.Item styles={{ root: { maxWidth: 320 } }}>
                                            <TextField
                                                disabled={disabled || !options.support?.enabled}
                                                // label={strings.ORGANIZATION.OPTIONS.SUPPORT.JIVO_ID_LABEL}
                                                // title={strings.ORGANIZATION.OPTIONS.SUPPORT.JIVO_ID_TITLE}
                                                placeholder={strings.ORGANIZATION.OPTIONS.SUPPORT.JIVO_ID_PLACEHOLDER}
                                                value={options.support?.jivo_id}
                                                onChange={(ev, value) => setOptions({ ...options, support: { ...options.support, jivo_id: value ?? "" } })}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>

                        <Stack.Item styles={{ root: { borderTop: `1px solid ${theme.palette.neutralLight}` } }}>
                            <Stack horizontal tokens={{ padding: 20 }}>
                                <Stack.Item styles={{ root: { minWidth: 140 } }}>
                                    <Toggle onText={strings.BUTTONS.TOGGLE_ON} offText={strings.BUTTONS.TOGGLE_OFF}
                                        disabled={disabled}
                                        checked={options.search?.enabled}
                                        onChange={() => setOptions({ ...options, search: { ...options.search, enabled: !options.search?.enabled } })}
                                    />
                                </Stack.Item>
                                <Stack>
                                    <Text style={{ fontWeight: 600, color: theme.palette.black }}>{strings.ORGANIZATION.OPTIONS.SEARCH.TITLE}</Text>
                                    <Text variant="small" style={{ color: theme.palette.neutralSecondary }}>
                                        {strings.ORGANIZATION.OPTIONS.SEARCH.DESCRIPTION}
                                    </Text>
                                </Stack>
                            </Stack>
                        </Stack.Item>

                        <Stack.Item styles={{ root: { borderTop: `1px solid ${theme.palette.neutralLight}` } }}>
                            <Stack horizontal tokens={{ padding: 20 }}>
                                <Stack.Item styles={{ root: { minWidth: 140 } }}>
                                    <Toggle onText={strings.BUTTONS.TOGGLE_ON} offText={strings.BUTTONS.TOGGLE_OFF}
                                        disabled={disabled}
                                        checked={options.history?.enabled}
                                        onChange={() => setOptions({ ...options, history: { ...options.history, enabled: !options.history?.enabled } })}
                                    />
                                </Stack.Item>
                                <Stack>
                                    <Text style={{ fontWeight: 600, color: theme.palette.black }}>{strings.ORGANIZATION.OPTIONS.HISTORY.TITLE}</Text>
                                    <Text variant="small" style={{ color: theme.palette.neutralSecondary }}>
                                        {strings.ORGANIZATION.OPTIONS.HISTORY.DESCRIPTION}
                                    </Text>
                                </Stack>
                            </Stack>
                        </Stack.Item>
                        <Stack.Item styles={{ root: { borderTop: `1px solid ${theme.palette.neutralLight}` } }}>
                            <ParameterItem title={strings.ORGANIZATION.OPTIONS.BRANDING.TITLE}
                                useToggle={true}
                                toggleEnabled = {!disabled}
                                toggleChecked={options.branding ? true : false}
                                toggleOnChange={() => setOptions({ ...options, branding: options.branding ? undefined : {} })}
                                useDropdown
                                dropdownEnabled={options.branding && !disabled ? true : false}
                                dropdownSelectedKey={options.branding?.brandingId}
                                dropdownOnChange={(option) => setOptions({ ...options, branding: { ...options.branding, brandingId: option?.key as number } })}
                                dropdownPlaceholder={strings.ORGANIZATION.OPTIONS.BRANDING_PLACEHOLDER}
                                dropdownOptions={dropDownOptions}
                            />
                        </Stack.Item>

                        {/* <Stack.Item styles={{ root: { borderTop: `1px solid ${theme.palette.neutralLight}` } }}>
                            <Stack horizontal tokens={{ padding: 20 }}>
                                <Stack.Item styles={{ root: { minWidth: 140 } }}>
                                    <Toggle onText={strings.BUTTONS.TOGGLE_ON} offText={strings.BUTTONS.TOGGLE_OFF}
                                        disabled={disabled}
                                        checked={options.warehouse?.enabled}
                                        onChange={() => setOptions({ ...options, warehouse: { ...options.warehouse, enabled: !options.warehouse?.enabled } })}
                                    />
                                </Stack.Item>
                                <Stack>
                                    <Text style={{ fontWeight: 600, color: theme.palette.black }}>{strings.ORGANIZATION.OPTIONS.LOGISTICS.TITLE}</Text>
                                    <Text variant="small" style={{ color: theme.palette.neutralSecondary }}>
                                        {strings.ORGANIZATION.OPTIONS.LOGISTICS.DESCRIPTION}
                                        {" "}
                                        <Link>{strings.ORGANIZATION.OPTIONS.LEARN_MORE}</Link>
                                    </Text>
                                </Stack>
                            </Stack>
                        </Stack.Item>

                        <Stack.Item styles={{ root: { borderTop: `1px solid ${theme.palette.neutralLight}` } }}>
                            <Stack horizontal tokens={{ padding: 20 }}>
                                <Stack.Item styles={{ root: { minWidth: 140 } }}>
                                    <Toggle onText={strings.BUTTONS.TOGGLE_ON} offText={strings.BUTTONS.TOGGLE_OFF}
                                        disabled={disabled}
                                        checked={options.picea?.enabled}
                                        onChange={() => setOptions({ ...options, picea: { ...options.picea, enabled: !options.picea?.enabled } })}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <Stack tokens={{ childrenGap: 16 }}>
                                        <Stack styles={{ root: { maxWidth: 400 } }}>
                                            <Text style={{ fontWeight: 600, color: theme.palette.black }}>{strings.ORGANIZATION.OPTIONS.PICEA.TITLE}</Text>
                                            <Text variant="small" style={{ color: theme.palette.neutralSecondary }}>
                                                {strings.ORGANIZATION.OPTIONS.PICEA.DESCRIPTION}
                                                {" "}
                                                <Link>{strings.ORGANIZATION.OPTIONS.LEARN_MORE}</Link>
                                            </Text>
                                        </Stack>
                                        <Stack.Item styles={{ root: { maxWidth: 320 } }}>
                                            <TextField
                                                disabled={disabled || !options.picea?.enabled}
                                                placeholder={strings.ORGANIZATION.OPTIONS.PICEA.LABEL_PLACEHOLDER}
                                                value={options.picea?.product_id}
                                                onChange={(ev, value) => setOptions({ ...options, picea: { ...options.picea, product_id: value } })} />
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item> */}

                    </Stack>
                </Section>

                <Section max title={strings.ORGANIZATION.OPTIONS.APPLICATION_MAINSCREEN_UI.TITLE} description={strings.ORGANIZATION.OPTIONS.APPLICATION_MAINSCREEN_UI.DESCRIPTION}>
                    <Stack>
                        <Stack.Item styles={{ root: { borderTop: `1px solid ${theme.palette.neutralLight}` } }}>
                            <Stack horizontal tokens={{ padding: 20 }}>
                                <Stack.Item styles={{ root: { minWidth: 140 } }}>
                                    <Toggle onText={strings.BUTTONS.TOGGLE_ON} offText={strings.BUTTONS.TOGGLE_OFF}
                                        disabled={disabled}
                                        checked={options.mainScreen?.title !== undefined ?? true}
                                        onChange={() => setOptions({ ...options, mainScreen: { ...options.mainScreen, title: options.mainScreen?.title !== undefined ? undefined : '' } })}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <Stack tokens={{ childrenGap: 16 }}>
                                        <Stack styles={{ root: { maxWidth: 400 } }}>
                                            <Text style={{ fontWeight: 600, color: theme.palette.black }}>{strings.ORGANIZATION.OPTIONS.APPLICATION_MAINSCREEN_UI.TITLE_TEXT}</Text>
                                            <Text variant="small" style={{ color: theme.palette.neutralSecondary }}>
                                                {strings.ORGANIZATION.OPTIONS.APPLICATION_MAINSCREEN_UI.TITLE_DESCRIPTION}
                                                {" "}
                                            </Text>
                                        </Stack>
                                        <Stack.Item styles={{ root: { maxWidth: 320 } }}>
                                            <TextField
                                                disabled={options.mainScreen?.title !== undefined ? false : true}
                                                value={options.mainScreen?.title}
                                                onChange={(ev, value) => setOptions({ ...options, mainScreen: { ...options.mainScreen, title: options.mainScreen?.title !== undefined ? value : "" } })}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>

                        <Stack.Item styles={{ root: { borderTop: `1px solid ${theme.palette.neutralLight}` } }}>
                            <Stack horizontal tokens={{ padding: 20 }}>
                                <Stack.Item styles={{ root: { minWidth: 140 } }}>
                                    <Toggle onText={strings.BUTTONS.TOGGLE_ON} offText={strings.BUTTONS.TOGGLE_OFF}
                                        disabled={disabled}
                                        checked={options.mainScreen?.subtitle !== undefined ?? true}
                                        onChange={() => setOptions({ ...options, mainScreen: { ...options.mainScreen, subtitle: options.mainScreen?.subtitle !== undefined ? undefined : '' } })}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <Stack tokens={{ childrenGap: 16 }}>
                                        <Stack styles={{ root: { maxWidth: 400 } }}>
                                            <Text style={{ fontWeight: 600, color: theme.palette.black }}>{strings.ORGANIZATION.OPTIONS.APPLICATION_MAINSCREEN_UI.SUBTITLE_TEXT}</Text>
                                            <Text variant="small" style={{ color: theme.palette.neutralSecondary }}>
                                                {strings.ORGANIZATION.OPTIONS.APPLICATION_MAINSCREEN_UI.SUBTITLE_DESCRIPTION}
                                                {" "}
                                            </Text>
                                        </Stack>
                                        <Stack.Item styles={{ root: { maxWidth: 320 } }}>
                                            <TextField
                                                multiline
                                                disabled={options.mainScreen?.subtitle !== undefined ? false : true}
                                                value={options.mainScreen?.subtitle}
                                                onChange={(ev, value) => setOptions({ ...options, mainScreen: { ...options.mainScreen, subtitle: options.mainScreen?.subtitle !== undefined ? value : "" } })}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>

                        <Stack.Item styles={{ root: { borderTop: `1px solid ${theme.palette.neutralLight}` } }}>
                            <Stack horizontal tokens={{ padding: 20 }}>
                                <Stack.Item styles={{ root: { minWidth: 140 } }}>
                                    <Toggle onText={strings.BUTTONS.TOGGLE_ON} offText={strings.BUTTONS.TOGGLE_OFF}
                                        disabled={disabled}
                                        checked={options.mainScreen?.information !== undefined ?? true}
                                        onChange={() => setOptions({ ...options, mainScreen: { ...options.mainScreen, information: options.mainScreen?.information !== undefined ? undefined : '' } })}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <Stack tokens={{ childrenGap: 16 }}>
                                        <Stack styles={{ root: { maxWidth: 400 } }}>
                                            <Text style={{ fontWeight: 600, color: theme.palette.black }}>{strings.ORGANIZATION.OPTIONS.APPLICATION_MAINSCREEN_UI.INFORMATION_TEXT}</Text>
                                            <Text variant="small" style={{ color: theme.palette.neutralSecondary }}>
                                                {strings.ORGANIZATION.OPTIONS.APPLICATION_MAINSCREEN_UI.INFOMATION_DESCRIPTION}
                                                {" "}
                                            </Text>
                                        </Stack>
                                        <Stack.Item styles={{ root: { maxWidth: 320 } }}>
                                            <TextField
                                                multiline
                                                disabled={options.mainScreen?.information !== undefined ? false : true}
                                                value={options.mainScreen?.information}
                                                onChange={(ev, value) => setOptions({ ...options, mainScreen: { ...options.mainScreen, information: options.mainScreen?.information !== undefined ? value : "" } })}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                    </Stack>
                </Section>
                <Stack.Item>
                    <Stack tokens={{ childrenGap: 8 }} horizontal horizontalAlign="start">
                        <SecondaryButton disabled={disabled} text={strings.BUTTONS.TEXT.CANCEL} onClick={() => setOptions(settingsOptions)} />
                        <PrimaryButton disabled={disabled} text={strings.BUTTONS.TEXT.SAVE} onClick={() => updateOptions(options)} />
                        {isFetching && (
                            <Spinner />
                        )}
                    </Stack>
                </Stack.Item>
            </Stack>
        </>
    )
}

const theme = getTheme();

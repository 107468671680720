import { useEffect } from "react"
import { Stack, TextField, PrimaryButton, Slider, Checkbox } from "@fluentui/react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { strings } from "../../../../../../../../localization/strings"
import { onRenderLabel } from "../../../../../../../renders/onRenderLabel"
import { SecondaryButton } from "../../../../../../buttons/SecondaryButton"
import { getSliderLabel } from "../../../../common/helpers/fuctions"

import type { FieldSettingsData } from "../../dataCollectionTypes"
import { CSHHelpLink } from "../../../../../../help/CSHHelp"

type TProps = {
    field?: FieldSettingsData
    fieldIds: string[]
    onConfirm: (item: FieldSettingsData) => void
}

export const FieldSettingsForm: React.FC<TProps> = ({ field, fieldIds, onConfirm }) => {
    const {
        control,
        handleSubmit,
        reset,
        getValues,
        formState: { errors }
    } = useForm<FieldSettingsData>({ defaultValues: { ...field, } });

    const onSubmit: SubmitHandler<FieldSettingsData> = data => {
        verifySettingData(data);
        onConfirm(data);
    };
    
    useEffect(() => {
        reset(field, { keepValues: false })
    }, [field])
    
    const verifySettingData = (data: FieldSettingsData) => {
        
        if(data.mask === "") data.mask = undefined;

        if(data.label === "") data.label = undefined;

        if(data.placeholder === "") data.placeholder = undefined;
    };


    return ( 
        field ? (
            <form
                onSubmit={handleSubmit(onSubmit)}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    height: "100%",
                    boxSizing: "border-box"
                }}>
                    <Stack tokens={{ padding: 20, childrenGap: 16 }}>
                        {field.type === 'custom' &&
                            <Controller
                                control={control}
                                name="id"
                                rules={{
                                    required: strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FORM.KEY_REQUIRED,
                                    validate: (value) => {
                                        if (!field.isNewCustomField) return true
                                        return !fieldIds.find(k => k === value) ||
                                        strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FORM.KEY_VALIDATE
                                    }
                                }}
                                render={({ field }) =>
                                    <TextField {...field}
                                        required
                                        onRenderLabel={onRenderLabel}
                                        label={strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FORM.KEY_LABEL}
                                        title={strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FORM.KEY_TITLE}
                                        placeholder={strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FORM.KEY_PLACEHOLDER}
                                        errorMessage={errors.id?.message}
                                />
                                }
                            />
                        }
                        <Controller
                            control={control}
                            name="label"
                            render={({ field }) =>
                                <TextField {...field}
                                    onRenderLabel={onRenderLabel}
                                    label={strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FORM.LABEL_LABEL}
                                    title={strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FORM.LABEL_TITLE}
                                    placeholder={strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FORM.LABEL_PLACEHOLDER}
                                    errorMessage={errors.label?.message}
                                />
                            }
                        />
                        {(field.type === 'masked' || field.type === 'custom') &&
                            (
                                <Controller
                                    control={control}
                                    name="mask"
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            onRenderLabel={onRenderLabel}
                                            label={strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FORM.MASK_LABEL}
                                            title={strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FORM.MASK_TITLE}
                                            placeholder={strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FORM.MASK_PLACEHOLDER}
                                            errorMessage={errors.mask?.message}
                                        />
                                    }    
                                />        
                            )}
                        <Controller
                            control={control}
                            name="placeholder"
                            render={({ field }) =>
                                <TextField {...field}
                                    onRenderLabel={onRenderLabel}
                                    label={strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FORM.PLACEHOLDER_LABEL}
                                    title={strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FORM.PLACEHOLDER_TITLE}
                                    placeholder={strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FORM.LABEL_PLACEHOLDER}
                                    errorMessage={errors.label?.message}
                                />
                            }
                        />
                        {field.type !== 'common' && !field.hideSlider && (
                            <Stack style={{ maxWidth: 350 }}>
                                <Slider
                                    label={getSliderLabel(getValues("minLength"), getValues("maxLength"))}
                                    showValue={false}
                                    ranged
                                    disabled={field.disableSlider}
                                    lowerValue={getValues("minLength")}
                                    value={getValues("maxLength")}
                                    onChange={(val, range) => {
                                        reset({
                                            ...getValues(),
                                            minLength: range ? range[0] : undefined,
                                            maxLength: range ? range[1] : undefined
                                        })
                                    }}
                                    min={0}
                                    max={50}
                                />
                            </Stack>
                        )}
                        {field.type === 'custom' && (
                            <Controller
                                control={control}
                                name={"required"}
                                render={({ field }) =>
                                    <Stack.Item>
                                        {onRenderLabel({
                                            title: strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FORM.REQUIRED_TITLE,
                                            label: strings.CONSTRUCTOR.INSPECTIONS.DATACOLLECTION.STANDARD.FORM.REQUIRED_LABEL
                                        })}
                                        <Checkbox
                                            checked={field.value}
                                            onChange={field.onChange}
                                        />
                                    </Stack.Item>
                                }
                            />
                        )}
                    </Stack>
                    <Stack>
                        <Stack horizontal grow horizontalAlign="end" tokens={{ padding: 20, childrenGap: 16 }}>
                            <CSHHelpLink articleid="1133" alignment="left"></CSHHelpLink>
                            <SecondaryButton iconProps={{ iconName: "Undo" }} onClick={() => reset(field)} />
                            <PrimaryButton onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                        </Stack>
                    </Stack>
            </form>
        ) : <></>
    ) 
}
import React from "react"
import { portalApi } from "../../../../core/api/api"
import { ICatalogItem, IService } from "../../../../core/store"
import { IGrade, IResponseError } from "@piceasoft/core"
import { IRequestResult } from "../../../../core/store/typings/IResponseResult"
import { TServicePivotState } from "../ServiceConfigurationDetails"
import { ServiceConfigurationConfigurationPivot } from "./pivots/ServiceConfigurationConfigurationPivot"
import { ServiceConfigurationHelpPivot } from "./pivots/ServiceConfigurationHelpPivot"
import { ServiceConfigurationInformationPivot } from "./pivots/ServiceConfigurationInformationPivot"
import { ServiceConfigurationSettingsPivot } from "./pivots/ServiceConfigurationSettingsPivot"
import { validateSerchInWorkflowCatalog } from "../../../../screens/services/Service/ServiceConfigurationDetails/ValidationInspectionsModules"

export type TServicePivotBasedStateProps = {
    state: TServicePivotState
    data: IService
    onDelete: () => void
    retryInfo: () => void
    setInformation: (information: string) => void
    onImportWorkflow: () => void
    updateService: (data: IService) => Promise<IRequestResult<IService>>
    isFetching: boolean
    infoFetching: boolean
    resetInfoWasSaved: () => void
    infoWasSaved: boolean
    generateIcon?: boolean
}

export type TGrade = IGrade & { isInCatalog?: boolean }

export const ServiceConfigurationPivotBasedState: React.FC<TServicePivotBasedStateProps> = (props) => {

    const [catalogItemsState, setCatalogItemsState] = React.useState<{ fetched: boolean, data?: ICatalogItem[], errors?: IResponseError[] }>()

    const [grades, setGrades] = React.useState<TGrade[]>()    

    React.useEffect(() => {
        processGrades()
    }, [])

    React.useEffect(() => {
        processGrades()
        props.data.workflow.catalogId = props.data.catalogId;
        validateSerchInWorkflowCatalog(props.data.workflow);
    }, [props.data.catalogId])    

    React.useEffect(() => {
        if (!props.data.workflow?.useGradesCategories && catalogItemsState?.fetched) {
            let newGrades: TGrade[] = []
            props.data.workflow?.grades.forEach(g => {
                const catalogGrade = props.data.catalogId ? catalogItemsState?.data?.find(i => i.prices.find(ip => ip.grade === g.code)) : true
                newGrades.push({ ...g, isInCatalog: catalogGrade ? true : false })
            })

            // console.log(catalogItemsState.data)
            // console.log(newGrades)
            setGrades(newGrades)
        }
    }, [catalogItemsState])

    const processGrades = () => {
        setGrades(undefined)
        if (!props.data.workflow?.useGradesCategories && props.data.catalogId) {
            getCatalogItems(props.data.catalogId)
        } else {
            setGrades([])
            setCatalogItemsState({ fetched: true })
        }
    }

    const getCatalogItems = async (catalogId: string) => {
        setCatalogItemsState({ fetched: false })
        const result = await portalApi.organization.catalogs.getCatalogItems(catalogId)

        if (result.successed) {
            setCatalogItemsState({ fetched: true, data: result.data })
        } else {
            setCatalogItemsState({ fetched: true, errors: result.errors })
        }
    }

    switch (props.state) {
        case "information": return <ServiceConfigurationInformationPivot
            data={props.data}
            onDelete={props.onDelete}
            grades={grades}
        />
        case "settings": return <ServiceConfigurationSettingsPivot
            data={props.data}
            updateService={props.updateService}
            isFetching={props.isFetching}
            generateIcon={props.generateIcon}
            grades={grades}
        />
        case "configuration": return <ServiceConfigurationConfigurationPivot
            workflow={props.data.workflow}
            channel={ props.data.channel}
            deviceCheckSolution={props.data.deviceCheckSolution}
            serviceType={props.data.serviceType}
            isRemoved={props.data.removed}
            serviceId={props.data.id as number}
            refreshService={props.retryInfo}
            onImportWorkflow={props.onImportWorkflow} 
            catalogId={props.data.catalogId}
            serviceName={props.data.name}
        />
        case "help": return (
            <ServiceConfigurationHelpPivot
                disabled={props.data.removed}
                information={props.data.information}
                setInformation={props.setInformation}
                isFetching={props.isFetching}
                infoWasSaved={props.infoWasSaved}
                resetInfoWasSaved={props.resetInfoWasSaved}
            />
        )
    }
}

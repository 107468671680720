import {
    getTheme, Stack, TextField, Text, Separator, PrimaryButton,
    ChoiceGroup, IChoiceGroupOption, ScrollablePane, Sticky, MessageBar,
    MessageBarType, getFocusStyle, IComboBoxOption, Icon, IDropdownOption,
    mergeStyleSets, SharedColors, Dropdown, Toggle, Spinner
} from '@fluentui/react'
import * as React from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { strings } from '../../../localization/strings'
import { onRenderLabel } from '../../renders/onRenderLabel'
import { CommissionType, CatalogTypes, IOfferProviderConfig } from '@piceasoft/core'
import { SecondaryButton } from '../buttons/SecondaryButton'
import { ChooseIcon } from '../chooseIcon/ChooseIcon'
import { portalApi } from '../../../core/api/api'
import { IRequestResult } from '../../../core/store/typings/IResponseResult'
import SuggestedSearch, { focusElement, handleTextToListKeyPress } from '../search/SuggestedSearch'
import { useBoolean, useId } from '@uifabric/react-hooks'
import { escapeRegExp } from '../../../assets/ts/utils/regex'
import { ICatalog, IStore } from '../../../core/store'
import { prepareCurrencyOptions } from '../../../core/helpers/currency'
import { useDispatch, useSelector } from 'react-redux'
import { OfferProviderFormGradesMapArray } from './OfferProviderFormGradesMapArray'
import { OfferProviderTypes, IResponseError } from '@piceasoft/core'
import { CSHHelpLink } from '../help/CSHHelp'
import { OfferProviderCustomFields } from './OfferProviderCustomFields'
import { actionCreators } from '../../../core/actions/configurator-actions'
type TProps = {
    onSubmit: (item: IOfferProviderConfig) => void
    onCancel: () => void
    codes: string[]
    data?: IOfferProviderConfig
}

export const OfferProviderForm: React.FC<TProps> = (props) => {

    console.log("OfferProviderForm: %O", props.data)
    const dispatch = useDispatch();
    const organizationCurrency = useSelector((s: IStore) => s.workplace.organization?.currency);
    const currency = prepareCurrencyOptions();
    const catalogType = useSelector((s:IStore) => s.configurator.useDiscountCatalogs ? CatalogTypes.GradeDiscount : CatalogTypes.Tradein)

    const [providerType, setProviderType] = React.useState<OfferProviderTypes>(props.data?.type ?? OfferProviderTypes.Offer);
    const [showNotifyField, { toggle: toggleNotifyField }] = useBoolean(props.data?.notifyEndpoint ? true : false);

    const providerTypes: IChoiceGroupOption[] = [
        { key: OfferProviderTypes.Offer.toString(), text: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.TYPES.OFFER.LABEL, iconProps: { iconName: "Money" }, styles: { root: { display: 'flex' } } },
        { key: OfferProviderTypes.Catalog.toString(), text: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.TYPES.CATALOG.LABEL, iconProps: { iconName: "ProductCatalog" }, styles: { root: { display: 'flex' } } },
    ]

    const [file, setFile] = React.useState<File>()
    const [isFetchingIcon, setIsFetchingIcon] = React.useState(false)
    const [isIconChanged, setIsIconChanged] = React.useState(false)
    const [iconErrors, setIconErrors] = React.useState<IResponseError[]>([])
    const [fileSrc, setFileSrc] = React.useState(props.data?.imageSrc)

    const [catalogsState, setCatalogsState] = React.useState<{ fetched: boolean, error?: string, data?: Array<ICatalog> }>({ fetched: false })

    const [catalogs, setCatalogs] = React.useState<IComboBoxOption[]>()

    const catalogHostId = useId("catalog-host-id")
    const catalogTextId = useId("catalog-text-id")
    const catalogCalloutListId = "catalog-callout-id"

    const [showCommissions, setShowCommissions] = React.useState(props.data?.commissionType ? true : false);
    const [commissionType, setCommissionType] = React.useState<CommissionType | undefined>(props.data?.commissionType);

    const { control, handleSubmit, getValues, resetField, setValue, formState: { errors }, clearErrors } = 
        useForm<IOfferProviderConfig>(
            { defaultValues: 
                { ...props.data,
                    currency: providerType === OfferProviderTypes.Offer ? 
                    (props.data?.currency ?? organizationCurrency) : undefined 
                } 
            });

    const onSubmit: SubmitHandler<IOfferProviderConfig> = async data => {
        console.log("onSubmit, data: %O", data)
        if(catalogsState.data){
            dispatch(actionCreators.previousCommonOfferProviders.save(catalogsState.data));
        }

        let icon = props.data?.imageSrc
        let errors: IResponseError[] = []

        if (icon !== fileSrc) {
            setIsFetchingIcon(true)
            setIconErrors([])
            let uploadResult: IRequestResult<string> = { successed: false }
            if (file) {
                uploadResult = await portalApi.settings.organization.uploadFile('offer-provider-icon', file, undefined, true);
                if (uploadResult.errors && uploadResult.errors.length > 0) {
                    errors = uploadResult.errors;
                }
            } else {
                uploadResult.successed = true;
            }

            if (uploadResult.successed) {
                icon = uploadResult.data
                setIsIconChanged(false)
            }
            setIsFetchingIcon(false)
        }

        if (errors.length === 0) {
            if(data.type !== providerType) {
                if(providerType === OfferProviderTypes.Catalog) {
                    if(data.endpoint) {
                        data.endpoint = "";
                    }
                    if(data.notifyEndpoint) {
                        data.notifyEndpoint = "";
                    }
                    data.currency = undefined;
                    data.user = undefined;
                    data.password = undefined;
                } else if(providerType === OfferProviderTypes.Offer) {
                    if(data.catalogId) {
                        data.catalogId = "";
                    }
                }
            }

            props.onSubmit({ ...data, type: providerType, imageSrc: icon ?? "" });
        } else {
            setIconErrors(errors)
        }

    };

    React.useEffect(() => {
        getCatalogs()
    }, [])

    React.useEffect(() => {
        if (catalogsState.fetched && catalogsState.data) {
            setCatalogs(getCatalogsOptions(catalogsState.data))
            // Validate if current catalog exists.
            // If catalog doesn't exist then reset catalog field and block saving form without selecting new catalog.
            const catalogId = getValues().catalogId;
            const catalogIndex = catalogsState.data.findIndex(c => c.id === catalogId)
            if (catalogIndex < 0){
                resetField("catalogId", {defaultValue: null})
            }
        }
    }, [catalogsState])

    React.useEffect(() => {
        if (providerType === OfferProviderTypes.Offer) {
            if (!getValues().currency) {
                setValue("currency", props.data?.currency ?? organizationCurrency)
            }
        } else {
            setValue("currency", undefined)
        }
    }, [providerType])

    const getCatalogs = async () => {
        setCatalogsState({ fetched: false })
        const result = await portalApi.organization.catalogs.getCatalogsByCatalogType( catalogType)
        // Filter only active catalogs. Deleted catalogs are excluded.
        setCatalogsState({ error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined, fetched: true, data: result.data?.filter(c => !c.removed) })
    }

    React.useEffect(() => {
        setFileSrc(props.data?.imageSrc)
        setFile(undefined)
    }, [props.data?.imageSrc])

    React.useEffect(() => {
        if (iconErrors.length > 0) {
            setIconErrors([])
        }
    }, [fileSrc])

    const allowedFileTypes = ["image/jpeg", "image/tiff", "image/png", "image/bmp", "image/gif", "image/svg+xml"]

    const addFile = (upFiles: FileList) => {
        console.log(upFiles)
        const checkedUpFiles = [...Array.from(upFiles).filter(upFile => allowedFileTypes.includes(upFile.type))]
        console.log(checkedUpFiles)
        if (checkedUpFiles && checkedUpFiles.length > 0) {
            setIsIconChanged(true)
            setFileSrc(URL.createObjectURL(checkedUpFiles[0]))
            setFile(checkedUpFiles[0])
        }
    }

    const resetImageSrc = () => {
        if (fileSrc) {
            URL.revokeObjectURL(fileSrc)
            setFileSrc(props.data?.imageSrc)
            setFile(undefined)
            setIsIconChanged(false)
        }
    }

    const deleteImageSrc = () => {
        if (fileSrc) {
            setFileSrc(undefined)
            setIsIconChanged(true)
        }
    }

    const onToggleNotify = () => {
        if (showNotifyField) {
            setValue("notifyEndpoint", "");
        }
        toggleNotifyField();
    }

    const getCatalogsOptions = (catalogs: ICatalog[]): IDropdownOption[] => catalogs.map(c => ({ key: c.id, text: c.name } as IDropdownOption))

    const prepareCellStyle = (item?: IComboBoxOption) => {
        const currentColor = item?.selected ? theme.palette.themeLighter : theme.semanticColors.bodyDivider
        const classNames = mergeStyleSets({
            itemCell: [
                getFocusStyle(theme, { inset: -1, outlineColor: theme.palette.themePrimary, borderColor: theme.palette.themePrimary, }),
                {
                    padding: 5,
                    boxSizing: 'border-box',
                    background: item?.selected && theme.palette.themeLighter,
                    borderBottom: `1px solid ${currentColor}`,
                    display: 'flex',
                    selectors: {
                        '&:hover': { background: currentColor }
                    },
                },
            ]
        });
        return classNames
    }

    const onRenderCatalogSuggestionsCell = (item?: IComboBoxOption, index?: number) => {
        return (
            <div className={prepareCellStyle(item).itemCell}
                data-is-focusable={true}
                id={`${catalogCalloutListId}-${index as number}`}
                onKeyDown={(ev: React.KeyboardEvent<HTMLElement>) => handleTextToListKeyPress(ev, catalogs?.length ?? 0, catalogTextId, catalogCalloutListId, () => setValue("catalogId", item?.key as string), index, item?.text, catalogHostId)}
                tabIndex={-1}
                onClick={() => {
                    console.log(index)
                    setValue("catalogId", item?.key as string)
                    focusElement(`${catalogCalloutListId}-${index as number}`, catalogHostId)
                }}
            >
                <Stack horizontal grow tokens={{ padding: 2, childrenGap: 18 }}>
                    <Stack horizontalAlign="center" verticalAlign="center" style={{ width: 14 }}>
                        {item?.selected && <Icon style={{ fontSize: 10, fontWeight: 600 }} iconName="Accept" />}
                    </Stack>
                    <Stack.Item>
                        <Text block nowrap variant="medium" style={{ color: SharedColors.gray40 }}>{item?.text}</Text>
                    </Stack.Item>
                </Stack>
            </div >
        );
    }

    const prepareFilteredOptions = (items: IComboBoxOption[], filterValue?: string, selectedKey?: string): IComboBoxOption[] => {
        return items.filter(i =>
            escapeRegExp(i.text.toLowerCase())
                .match(new RegExp(`\w*${escapeRegExp(filterValue?.toLowerCase() ?? "")}\w*`)) != null)
            .map((i, index) => {
                return ({ key: i.key, text: i.text, selected: i.key.toString() === selectedKey } as IComboBoxOption)
            })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "100%", boxSizing: "border-box" }}>
            <Stack verticalFill>
                <Stack.Item verticalFill style={{ position: 'relative' }}>
                    <ScrollablePane>
                        {iconErrors.length > 0 && (
                            <Sticky>
                                <MessageBar messageBarType={MessageBarType.error}>
                                    <Stack tokens={{ childrenGap: 2 }}>
                                        {iconErrors.map(i => <Text>{i.description}</Text>)}
                                    </Stack>
                                </MessageBar>
                            </Sticky>
                        )}
                        <Stack verticalFill tokens={{ padding: "0px 20px 20px 20px", childrenGap: 16 }}>
                            <Controller
                                control={control}
                                name={"code"}
                                rules={{
                                    required: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.CODE_REQUIRED,
                                    validate: (value) => {
                                        if (props.data && props.data.code === value) {
                                            return true;
                                        }
                                        return props.codes.find(k => k === value) ?
                                            strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.PROVIDER_CODE_VALIDATION :
                                            true
                                    }
                                }}
                                render={({ field }) =>
                                    <TextField
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        style={{ width: 350 }}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.CODE_LABEL}
                                        placeholder={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.CODE_PLACEHOLDER}
                                        errorMessage={errors.code?.message}
                                    />
                                }
                            />
                            <Controller
                                control={control}
                                name={"name"}
                                rules={{
                                    required: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.NAME_REQUIRED,
                                }}
                                render={({ field }) =>
                                    <TextField
                                        required
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        style={{ width: 350 }}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.NAME_LABEL}
                                        placeholder={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.NAME_PLACEHOLDER}
                                        errorMessage={errors.name?.message}
                                    />
                                }
                            />
                            <Stack.Item>
                                {onRenderLabel({
                                    required: true,
                                    label: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.TYPE.LABEL,
                                    title: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.TYPE.TITLE
                                })}
                                <ChoiceGroup
                                    required
                                    selectedKey={providerType.toString()}
                                    onChange={(ev, opt) => opt && setProviderType(Number.parseInt(opt.key) as OfferProviderTypes)}
                                    options={providerTypes}
                                    key={`resultType-${providerType}`}
                                />
                            </Stack.Item>
                            {providerType === OfferProviderTypes.Offer && (
                                <Controller
                                    name="currency"
                                    control={control}
                                    rules={{
                                        required: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.CURRENCY_REQUIRED,
                                    }}
                                    render={({ field }) =>
                                        <Dropdown
                                            selectedKey={field.value}
                                            required
                                            onChange={(ev, opt) => field.onChange(opt?.key)}
                                            onBlur={field.onBlur}
                                            onRenderLabel={onRenderLabel}
                                            label={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.CURRENCY_LABEL}
                                            placeholder={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.CURRENCY_PLACEHOLDER}
                                            options={currency}
                                            errorMessage={errors.currency?.message}
                                        />}
                                />
                            )}
                            <Controller
                                control={control}
                                name={"description"}
                                render={({ field }) =>
                                    <TextField
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        style={{ width: 350 }}
                                        onRenderLabel={onRenderLabel}
                                        label={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.DESCRIPTION_LABEL}
                                        placeholder={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.DESCRIPTION_PLACEHOLDER}
                                        errorMessage={errors.description?.message}
                                    />
                                }
                            />
                            {providerType === OfferProviderTypes.Offer && (
                                <Controller
                                    control={control}
                                    name={"endpoint"}
                                    rules={{
                                        required: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.ENDPOINT_REQUIRED,
                                    }}
                                    render={({ field }) =>
                                        <TextField
                                            required
                                            value={field.value}
                                            onChange={field.onChange}
                                            onBlur={field.onBlur}
                                            style={{ width: 350 }}
                                            onRenderLabel={onRenderLabel}
                                            label={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.ENDPOINT_LABEL}
                                            placeholder={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.ENDPOINT_PLACEHOLDER}
                                            errorMessage={errors.endpoint?.message}
                                        />
                                    }
                                />
                            )}
                            {providerType === OfferProviderTypes.Offer && (
                                <Controller
                                    control={control}
                                    name={"user"}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            value={field.value}
                                            onChange={field.onChange}
                                            onBlur={field.onBlur}
                                            style={{ width: 350 }}
                                            onRenderLabel={onRenderLabel}
                                            label={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.USER_LABEL}
                                            placeholder={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.USER_PLACEHOLDER}
                                            errorMessage={errors.user?.message}
                                        />
                                    }
                                />
                            )}
                            {providerType === OfferProviderTypes.Offer && (
                                <Controller
                                    control={control}
                                    name={"password"}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            value={field.value}
                                            onChange={field.onChange}
                                            onBlur={field.onBlur}
                                            style={{ width: 350 }}
                                            onRenderLabel={onRenderLabel}
                                            label={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.PASSWORD_LABEL}
                                            placeholder={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.PASSWORD_PLACEHOLDER}
                                            errorMessage={errors.password?.message}
                                        />
                                    }
                                />
                            )}
                            {providerType === OfferProviderTypes.Offer && (
                                <Toggle checked={showNotifyField} onChange={onToggleNotify} label={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.NOTIFY_TOOGLE_LABLE} />
                            )}
                            {providerType === OfferProviderTypes.Offer && showNotifyField && (
                                <Controller
                                    control={control}
                                    name={"notifyEndpoint"}
                                    // rules={{
                                    //     required: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.ENDPOINT_REQUIRED,
                                    // }}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            value={field.value}
                                            onChange={field.onChange}
                                            onBlur={field.onBlur}
                                            style={{ width: 350 }}
                                            onRenderLabel={onRenderLabel}
                                            label={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.NOTIFY_ENDPOINT_LABEL}
                                            title={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.NOTIFY_ENDPOINT_TITLE}
                                            placeholder={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.NOTIFY_ENDPOINT_PLACEHOLDER}
                                            errorMessage={errors.endpoint?.message}
                                        />
                                    }
                                />
                            )}
                            {providerType === OfferProviderTypes.Catalog && (
                                <Controller
                                    control={control}
                                    name={"catalogId"}
                                    rules={{
                                        required: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.CATALOG_REQUIRED,
                                    }}
                                    render={({ field }) =>
                                        <SuggestedSearch
                                            type="dropdown-search"
                                            inputBoxId={catalogTextId}
                                            hostId={catalogHostId}
                                            required
                                            onRenderLabel={onRenderLabel}
                                            label={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.CATALOG_LABEL}
                                            title={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.CATALOG_TITLE}
                                            inputBoxPlaceholder={!catalogs ? strings.SPINNERS.DATA_IS_GETTING : catalogs.length > 0 ? (strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.CATALOG_PLACEHOLDER) : strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.NO_CATALOGS_PLACEHOLDER}
                                            onClickSuggestionCell={(item) => field.onChange(item ? item.key as string : undefined)}
                                            searchRequest={catalogs ? catalogs.find(i => i.key === field.value)?.text : strings.SPINNERS.DATA_IS_GETTING}
                                            suggestionsListWidth={440}
                                            disabled={!catalogs || catalogs.length === 0}
                                            suggestions={catalogs ?? []}
                                            suggestionsListId={catalogCalloutListId}
                                            isSearchEqualTheOneSuggestion={false}
                                            onRenderSuggestionCell={onRenderCatalogSuggestionsCell}
                                            prepareFilteredItems={prepareFilteredOptions}
                                            selectedKey={getValues().catalogId}
                                            errorMessage={errors.catalogId?.message}
                                        />
                                    }
                                />
                            )}
                            <Controller
                                name="allowPromotions"
                                control={control}
                                render={({ field }) =>
                                    <Toggle
                                        checked={field.value}
                                        onChange={(ev, checked) => field.onChange(checked)}
                                        onBlur={field.onBlur}
                                        label={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.ALLOW_PROMOTIONS_LABEL}
                                        offText={strings.BUTTONS.TOGGLE_NO}
                                        onText={strings.BUTTONS.TOGGLE_YES}
                                    />
                                }
                            />

                            <Toggle
                                checked={showCommissions}
                                onChange={(e, checked) => {
                                    if (!checked) {
                                        // claar type also
                                        setCommissionType(undefined);
                                        setValue("commissionValue", undefined); 
                                        setValue("commissionType", undefined);
                                    }
                                    setShowCommissions(!!checked);
                                }}
                                label={ onRenderLabel ({
                                    label: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.COMMISSIONS_LABEL,
                                    title: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.COMMISSIONS_TITLE} )}
                                offText={strings.BUTTONS.TOGGLE_NO}
                                onText={strings.BUTTONS.TOGGLE_YES}
                            />

                            {(showCommissions) && (
                                <Controller
                                    name="commissionType"
                                    rules={{ 
                                        validate: value => value !== undefined || strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.COMMISSION_TYPE_REQUIRED
                                      }}
                                    control={control}
                                    render={({ field }) =>
                                        <Dropdown
                                            selectedKey={field.value}
                                            required
                                            onChange={(event, option) => {
                                                field.onChange(option ? option.key : undefined);
                                                setCommissionType(option ? Number(option.key) : undefined);
                                                // @ts-expect-error Seems that string is needed instead of undefined to actually clean the value
                                                setValue("commissionValue", ""); 
                                                clearErrors("commissionValue");
                                            }}
                                            onBlur={field.onBlur}
                                            onRenderLabel={onRenderLabel}
                                            label={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.COMMISSION_TYPE_LABEL}
                                            placeholder={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.COMMISSION_TYPE_PLACEHOLDER}
                                            options={[
                                                {key: CommissionType.Percentage, text: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.COMMISSION_TYPE_REVENUE_SHARE},
                                                {key: CommissionType.Value, text: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.COMMISSION_TYPE_FIXED_FEE}]}
                                            errorMessage={ errors.commissionType?.message }
                                        />
                                    }
                                />

                            )}
                            {(commissionType != null) && (
                                <Controller
                                 name="commissionValue"
                                 rules={{ 
                                    required: commissionType === CommissionType.Percentage ? strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.REVENUE_SHARE_REQUIRED : strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.FIXED_FEE_REQUIRED,
                                    ...(commissionType === CommissionType.Percentage && { max: { value: 100, message: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.REVENUE_SHARE_REQUIRED } }),
                                    ...(commissionType === CommissionType.Value && { max: undefined })
                                  }}
                                 control={control}
                                 render={({ field }) =>
                                    <TextField
                                        required
                                        value={field.value === undefined || field.value === null ? '' : String(field.value).toLocaleString()}
                                        onChange={(ev, val) => val ? (/^\d*\.?\d{0,2}$/.test(val) && field.onChange(val)) : field.onChange(null)}
                                        onKeyDown={keyGuardNumericOnly}
                                        style={{ width: 350 }}
                                        onRenderLabel={onRenderLabel}
                                        label={ commissionType === CommissionType.Percentage ? strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.COMMISSION_TYPE_REVENUE_SHARE : strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.COMMISSION_TYPE_FIXED_FEE}
                                        title={ commissionType === CommissionType.Percentage ? strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.REVENUE_SHARE_TITLE : strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.FIXED_FEE_TITLE}
                                        placeholder={ commissionType === CommissionType.Percentage ? strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.REVENUE_SHARE_PLACEHOLDER : strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.FIXED_FEE_PLACEHOLDER}
                                        errorMessage={ errors.commissionValue?.message}
                                    />
                                }
                                />
                            )}

                            <Stack>
                                <Separator />
                                {onRenderLabel({
                                    title: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.ICON_TITLE,
                                    label: strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.ICON_LABEL
                                })}
                                <ChooseIcon
                                    acceptTypeMask={"image/*"}
                                    allowedFileTypes={allowedFileTypes}
                                    type="image"
                                    changed={isIconChanged}
                                    resetChanged={() => setIsIconChanged(true)}
                                    imgSrc={getValues().imageSrc}
                                    fileSrc={fileSrc}
                                    itemName={getValues().name}
                                    lable={strings.CONSTRUCTOR.STAGES.COMMON_OFFER.PROVIDER_FORM.ICON_LOAD_LABEL}
                                    description={true}
                                    generateIcon
                                    addFile={addFile}
                                    deleteImageSrc={deleteImageSrc}
                                    resetImageSrc={resetImageSrc}
                                />
                                <Separator />
                            </Stack>
                            {providerType === OfferProviderTypes.Offer && (
                                <OfferProviderFormGradesMapArray {...{ control, defaultValues: props.data, getValues, setValue, errors }} />
                            )}
                            {providerType === OfferProviderTypes.Offer && (
                                <OfferProviderCustomFields {...{ control, defaultValues: props.data, getValues, setValue, errors }} />
                            )}
                        </Stack>
                    </ScrollablePane>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal grow horizontalAlign="end" tokens={{ childrenGap: 8, padding: 20 }}>
                        {isFetchingIcon && (
                            <Spinner />
                        )}
                        <CSHHelpLink alignment='left' articleid='1158'></CSHHelpLink>
                        <PrimaryButton disabled={isFetchingIcon} onClick={handleSubmit(onSubmit)}>{strings.BUTTONS.TEXT.CONFIRM}</PrimaryButton>
                        <SecondaryButton onClick={props.onCancel}>{strings.BUTTONS.TEXT.CANCEL}</SecondaryButton>
                    </Stack>
                </Stack.Item>
            </Stack>
        </form >
    )
}

/**
 * Restrict to use numeric keys, backspace, delete, arrow keys, dot, and other navigation keys
 */
const keyGuardNumericOnly = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!event) return;
    if (
        !(
            (event.keyCode >= 48 && event.keyCode <= 57) || // Numeric keys
            (event.keyCode >= 96 && event.keyCode <= 105) || // Numpad keys
            event.keyCode === 8 || // Backspace
            event.keyCode === 46 || // Delete
            (event.keyCode >= 37 && event.keyCode <= 40) || // Arrow keys
            event.keyCode === 9 || // Tab
            event.keyCode === 13 || // Enter
            event.key === '.' // Dot
        )
    ) {
        event.preventDefault();
    }
}


const theme = getTheme();

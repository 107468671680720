import * as React from 'react';
import { IResultConfig, ISurveyResult, SurveyTypes } from '@piceasoft/core';
import { Icon, getTheme, FontWeights, SharedColors, Stack, Text, css } from '@fluentui/react';
import { strings } from '../../../../../localization/strings';
import { ICustomCsatResponseTitle } from '@piceasoft/core/dist/interfaces/IResultConfig';
import { getTextOrDefault } from '../../../../../core/helpers/common';

type TProps = {
    result: ISurveyResult | undefined,
    config: IResultConfig | undefined
}

const getValueWithOffset = (val: number | undefined, config: IResultConfig | undefined): number | undefined => {
    if (val && config?.userSatisfactionSurvey?.customCsatRatingScale == 4 && val >= 3) {
        return val - 1;
    }
    if (val && config?.userSatisfactionSurvey?.customCsatRatingScale == 3) {
        return val - 1;
    }
    if (val && config?.userSatisfactionSurvey?.npsSurvey) {
        return val - 1;
    }
    else {
        return val;
    }
}

const getSurveyResponseTextByValue = (val: number | undefined, type: SurveyTypes | undefined, config: IResultConfig | undefined): string => {
    if (val == undefined || val == null)
        return strings.TRANSACTION.RESULT.SURVEY.BUTTON;

    if (type == SurveyTypes.Nps) {
        if (val < 6) {
            return strings.TRANSACTION.RESULT.SURVEY.DETRACTORS;
        }
        else if (val < 8) {
            return strings.TRANSACTION.RESULT.SURVEY.PASIVES;
        }
        else {
            return strings.TRANSACTION.RESULT.SURVEY.PROMOTERS;
        }
    }
    else {
        const customResps = config?.userSatisfactionSurvey?.customCsatResponseTitles?.sort((a, b) => (a?.code ?? 0) - (b?.code ?? 0));
       
        let customeRespValue = undefined;
        if (val == 0) {
            customeRespValue = getResponByIndex(0, customResps);
        }
        else if (val == 1) {
            customeRespValue = getResponByIndex(1, customResps);
        }
        else if (val == 2) {
            customeRespValue = getResponByIndex(2, customResps);
        }
        else if (val == 3) {
            customeRespValue = getResponByIndex(3, customResps);
        }
        else {
            customeRespValue = getResponByIndex(4, customResps);
        }

        if(!customeRespValue){
            if(config?.userSatisfactionSurvey?.customCsatRatingScale == 4 && val >=2){
                //skop Neutral item
                 val++;
            } 
            if(config?.userSatisfactionSurvey?.customCsatRatingScale == 3){
                val++;
            }
            if (val == 0) {
                customeRespValue = strings.TRANSACTION.RESULT.SURVEY.RESPONCE_0;
            }
            else if (val == 1) {
                customeRespValue = strings.TRANSACTION.RESULT.SURVEY.RESPONCE_1;
            }
            else if (val == 2) {
                customeRespValue = strings.TRANSACTION.RESULT.SURVEY.RESPONCE_2;
            }
            else if (val == 3) {
                customeRespValue = strings.TRANSACTION.RESULT.SURVEY.RESPONCE_3;
            }
            else {
                customeRespValue = strings.TRANSACTION.RESULT.SURVEY.RESPONCE_4;
            }
        }           
       
        return customeRespValue;
    }
}

const getResponByIndex = (val: number, customResps: ICustomCsatResponseTitle[] | undefined): string | undefined => {
    return (customResps && customResps[val] && customResps[val].title) ? customResps[val].title : undefined;
}

const getSurveyTypeName = (surveyType: SurveyTypes | undefined): string => {
    if (surveyType == SurveyTypes.Nps)
        return strings.TRANSACTION.RESULT.SURVEY.NPS;
    else if (surveyType == SurveyTypes.Csat)
        return strings.TRANSACTION.RESULT.SURVEY.CSAT;
    return "";
}

export const SurveyPivot: React.FC<TProps> = (props) => {
    let offset = 0;

    return (
        <Stack >
            <Stack horizontal grow verticalAlign="center" tokens={{ padding: "12px 24px 12px 12px" }}>
                <Stack.Item grow>
                    <Stack.Item>
                        <Stack.Item style={{ display: 'inline-block' }}>
                            <Icon iconName="SurveyQuestions" style={{ fontSize: 16 }} />
                        </Stack.Item>
                        <Stack.Item style={{ display: 'inline-block', paddingLeft: '5px' }}>
                            <Text variant="small" style={{ fontWeight: 600, color: SharedColors.gray30, fontSize: 16 }}>{strings.TRANSACTION.RESULT.SURVEY.HEADER2}</Text>
                        </Stack.Item>
                    </Stack.Item>
                    <Stack.Item >
                        <Text variant="small" style={{ fontWeight: 600, color: SharedColors.gray30 }}>{strings.TRANSACTION.RESULT.SURVEY.TYPE + " " + getSurveyTypeName(props?.result?.SurveyType)}</Text>
                    </Stack.Item>
                </Stack.Item>
                <Stack.Item>
                    <Text variant="small" style={cusmoStyles.cellLink}>{(props?.result?.Value != undefined ? (getValueWithOffset(props?.result?.Value + 1, props.config)) + " - " : "") + getSurveyResponseTextByValue(getValueWithOffset(props?.result?.Value, props.config), props?.result?.SurveyType, props?.config)}</Text>
                </Stack.Item>
            </Stack>
            <Stack horizontal grow verticalAlign="center" style={{ display: 'table' }} tokens={{ padding: "0px 12px 12px 12px" }}>
                <Stack.Item style={{ display: 'table-row' }}>
                    <Stack.Item style={{ display: 'table-cell', width:"100%" }}>
                        <Stack.Item style={{ display: 'table' }}>
                            <Stack.Item style={{ display: 'table-row' }}>
                                <Stack.Item style={{ ...cusmoStyles.cellStyle, ...cusmoStyles.firstColumn }}>
                                    <Text variant="small" style={{ fontWeight: 600, color: SharedColors.gray30 }}>{strings.TRANSACTION.RESULT.SURVEY.CELL_QUESTION}</Text>
                                </Stack.Item>
                                <Stack.Item style={{ ...cusmoStyles.cellStyle, ...cusmoStyles.secondColumn }}>
                                    <Text variant="small" style={cusmoStyles.cellText}>
                                        {getTextOrDefault(props?.config?.userSatisfactionSurvey?.customSurveyQuestion, props?.result?.SurveyType == SurveyTypes.Nps ? strings.TRANSACTION.RESULT.SURVEY.QUESTION_NPS : strings.TRANSACTION.RESULT.SURVEY.QUESTION)}
                                    </Text>
                                </Stack.Item>
                            </Stack.Item>
                            <Stack.Item style={{ display: 'table-row', height: props?.result?.Value == undefined ? '255px' : undefined }}>
                                <Stack.Item style={{ ...cusmoStyles.cellStyle, ...cusmoStyles.firstColumn }}>
                                    <Text variant="small" style={{ fontWeight: 600, color: SharedColors.gray30 }}>{strings.TRANSACTION.RESULT.SURVEY.CELL_RESPON}</Text>
                                </Stack.Item>
                                <Stack.Item style={{ ...cusmoStyles.cellStyle, ...cusmoStyles.secondColumn, ...{ verticalAlign: !props?.result?.Value ? 'top' : 'middle', paddingTop: !props?.result?.Value ? '10px' : undefined } }}>
                                    <Text variant="small" style={cusmoStyles.cellText}>{(props?.result?.Value != undefined ? (getValueWithOffset(props?.result?.Value + 1, props.config)) + " - " : "") + getSurveyResponseTextByValue(getValueWithOffset(props?.result?.Value, props.config), props?.result?.SurveyType, props?.config)}</Text>
                                </Stack.Item>
                            </Stack.Item>
                            {props?.result?.OpenEndAnswer && props?.result?.Value != undefined && (
                                <><Stack.Item style={{ display: 'table-row' }}>
                                    <Stack.Item style={{ ...cusmoStyles.cellStyle, ...cusmoStyles.firstColumn }}>
                                        <Text variant="small" style={{ fontWeight: 600, color: SharedColors.gray30 }}>{strings.TRANSACTION.RESULT.SURVEY.CELL_END_QUESTION}</Text>
                                    </Stack.Item>
                                    <Stack.Item style={{ ...cusmoStyles.cellStyle, ...cusmoStyles.secondColumn }}>
                                        <Text variant="small" style={cusmoStyles.cellText}>
                                            {getTextOrDefault(props?.config?.userSatisfactionSurvey?.openEndedQuestionTitle, props?.result?.SurveyType == SurveyTypes.Nps ? strings.TRANSACTION.RESULT.SURVEY.END_QUESTION : strings.TRANSACTION.RESULT.SURVEY.END_QUESTION_CSAT)}
                                        </Text>
                                    </Stack.Item>
                                </Stack.Item>
                                    <Stack.Item style={{ display: 'table-row' }}>
                                        <Stack.Item style={{
                                            ...cusmoStyles.cellStyle, ...cusmoStyles.firstColumn, ...cusmoStyles.lastRaw
                                        }}>
                                            <Text variant="small" style={{ fontWeight: 600, color: SharedColors.gray30 }}>{strings.TRANSACTION.RESULT.SURVEY.CELL_RESPON}</Text>
                                        </Stack.Item>
                                        <Stack.Item style={{ ...cusmoStyles.cellStyle, ...cusmoStyles.secondColumn, ...{ verticalAlign: 'top', paddingTop: '10px' } }}>
                                            <Text variant="small" style={cusmoStyles.cellText}>
                                                <Text variant="small" style={cusmoStyles.cellText}>{props?.result?.OpenEndAnswer}</Text>
                                            </Text>
                                        </Stack.Item>
                                    </Stack.Item>
                                </>
                            )}
                        </Stack.Item>
                    </Stack.Item>
                    <Stack.Item style={{ ...cusmoStyles.cellStyle, ...cusmoStyles.noPadding }}>
                        <Stack.Item style={{ ...cusmoStyles.lastColumn, ...cusmoStyles.borderBottom, display: "flex" }}>
                            <Stack.Item > <Text variant="small" >{strings.TRANSACTION.RESULT.SURVEY.SCALE}</Text></Stack.Item>
                            <Stack.Item style={{marginLeft: "auto", order: 2}}><Text variant="small" style={cusmoStyles.cellLink2}>
                                {(props?.result?.SurveyType == SurveyTypes.Nps ?
                                    "0 - 10" :
                                    "1 - " + (props?.config?.userSatisfactionSurvey?.customCsatRating ? props?.config?.userSatisfactionSurvey?.customCsatRatingScale : "5"))}</Text></Stack.Item>       
                            
                        </Stack.Item>
                        <Stack.Item style={{ paddingTop: "5px" }}>
                            {props?.result?.SurveyType == SurveyTypes.Nps && (<ul style={{ padding: 0, margin: 0, paddingLeft: 20, width: "180px" }}>
                                <li style={cusmoStyles.textLegend2}>
                                    <Text variant="small" style={cusmoStyles.liBoldText}>{strings.TRANSACTION.RESULT.SURVEY.PROMOTERS}</Text>
                                    <Text variant="small" style={cusmoStyles.liText}>{strings.TRANSACTION.RESULT.SURVEY.PROMOTERS_VAL}</Text>
                                </li>
                                <li style={cusmoStyles.textLegend2}>
                                    <Text variant="small" style={cusmoStyles.liBoldText}>{strings.TRANSACTION.RESULT.SURVEY.PASIVES}</Text>
                                    <Text variant="small" style={cusmoStyles.liText}>{strings.TRANSACTION.RESULT.SURVEY.PASIVES_VAL}</Text>
                                </li>
                                <li style={cusmoStyles.textLegend2}>
                                    <Text variant="small" style={cusmoStyles.liBoldText}>{strings.TRANSACTION.RESULT.SURVEY.DETRACTORS}</Text>
                                    <Text variant="small" style={cusmoStyles.liText}>{strings.TRANSACTION.RESULT.SURVEY.DETRACTORS_VAL}</Text>
                                </li>
                            </ul>)}
                            {props?.result?.SurveyType == SurveyTypes.Csat && (<>
                                <Stack.Item style={cusmoStyles.textLegend}>
                                    <Text variant="small" style={cusmoStyles.liText}>{"1 - " + getSurveyResponseTextByValue(0 + offset, SurveyTypes.Csat, props?.config)}</Text>
                                </Stack.Item>
                                <Stack.Item style={cusmoStyles.textLegend}>
                                    <Text variant="small" style={cusmoStyles.liText}>{"2 - " + getSurveyResponseTextByValue(1 + offset, SurveyTypes.Csat, props?.config)}</Text>
                                </Stack.Item>
                                <Stack.Item style={cusmoStyles.textLegend}>
                                    <Text variant="small" style={cusmoStyles.liText}>{"3 - " + getSurveyResponseTextByValue(2 + offset, SurveyTypes.Csat, props?.config)}</Text>
                                </Stack.Item>
                                {props?.config?.userSatisfactionSurvey?.customCsatRatingScale
                                    && props?.config?.userSatisfactionSurvey?.customCsatRatingScale > 3 && (<Stack.Item style={cusmoStyles.textLegend}>
                                        <Text variant="small" style={cusmoStyles.liText}>{"4 - " + getSurveyResponseTextByValue(3, SurveyTypes.Csat, props?.config)}</Text>
                                    </Stack.Item>)}
                                {props?.config?.userSatisfactionSurvey?.customCsatRatingScale
                                    && props?.config?.userSatisfactionSurvey?.customCsatRatingScale > 4 && (<Stack.Item style={cusmoStyles.textLegend}>
                                        <Text variant="small" style={cusmoStyles.liText}>{"5 - " + getSurveyResponseTextByValue(4, SurveyTypes.Csat, props?.config)}</Text>
                                    </Stack.Item>)}
                            </>
                            )}
                        </Stack.Item>
                    </Stack.Item>
                </Stack.Item>
            </Stack>
        </Stack>
    )
}
const theme = getTheme();
const cusmoStyles = {
    cellStyle: { display: 'table-cell', height: 40, borderTop: `1px solid #EDEBE9`, verticalAlign: 'top', lineHeight: '40px', paddingLeft: "10px", paddingRight: "5px" },
    cellText: { fontWeight: 600, color: SharedColors.gray30 },
    cellLink: { fontWeight: 600, color: theme.palette.themePrimary, paddingLeft: "50px", fontSize: 16 },
    cellLink2: { fontWeight: 600, color: theme.palette.themePrimary, fontSize: 16 },
    liText: { fontWeight: 600, color: SharedColors.gray30, paddingLeft: "5px" },
    liBoldText: { fontWeight: FontWeights.bold, color: SharedColors.gray30 },
    firstColumn: { width: '185px', minWidth: '185px', borderRight: `1px solid #EDEBE9` },
    secondColumn: { width: '100%', borderRight: `1px solid #EDEBE9`, lineHeight: "normal", verticalAlign: 'middle' },
    lastColumn: { width: '185px' },
    borderBottom: { borderBottom: `1px solid #EDEBE9`, paddingLeft: "10px" },
    textLegend: { lineHeight: "normal", paddingLeft: "5px" },
    textLegend2: { lineHeight: "normal" },
    noPadding: { paddingLeft: "0px", paddingRight: "0px" },
    lastRaw: { height: "170px" }
};


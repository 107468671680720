import { Action, Reducer } from "redux";
import { Experience } from "../../components/shared/configurator/helpers/evisibility";
import { compulsoryValidation } from "../../screens/services/Service/ServiceConfigurationDetails/ValidationInspectionsModules";
import { KnownAction } from "../actions/configurator-actions";
import {
    ISoftwareConfig, ISoftwarePiceaConfig, IWorkflow
} from "../store";
import {
    SoftwareModes, InterviewModes, DiagnosticsModes, IDiagnosticsPiceaConfig, IDiagnosticsPiceaMobileConfig,
    IInspectionModuleConfig, IProcessStageConfig, IPostOfferConfig, IPreOfferConfig,
    IInspectionConfig, IInterviewConfig, IInterviewWithQuestionsConfig, IPhotographicConfig,
    IInspectionConfigUI, Inspections, ProcessStages, IDiagnosticsWebBasedConfig
} from '@piceasoft/core';
import { ICommonOfferConfig } from "@piceasoft/core";
import { IDiagnosticsConfig } from "../store/typings/IDiagnosticsConfig";

export const reducer: Reducer<IWorkflow> = (state: IWorkflow | undefined, incomingAction: Action): IWorkflow => {

    let intermediateState = state
    if (intermediateState === undefined) {
        intermediateState = {
            grades: [],
            temporaryData: {},
            identification: {
                modules: [],
                items: []
            },
            version: 3
        }
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'CONFIGURATOR_RECEIVE':
            if (!action.data) {
                intermediateState = {
                    grades: [],
                    identification: {
                        modules: [],
                        items: []
                    },
                    version: 3
                }
                break;
            }

            if (action.data && action.data.offer && !action.data.preOffer && !action.data.postOffer && !action.data.commonOffer) {
                let preOffer: IPreOfferConfig = {
                    prices: action.data.offer.prices,
                    requireConfirmation: action.data.offer.requireConfirmation,
                    showPricesBeforeAssessment: action.data.offer.showPricesBeforeAssessment,
                    useAnalogs: action.data.offer.useAnalogs,
                    useAnalogsChoice: action.data.offer.useAnalogsChoice,
                    ui: action.data.offer.ui,
                    cancelOptions: action.data.offer.cancelOptions
                }
                let commonOffer: ICommonOfferConfig = {
                    allowDiscount: action.data.offer.allowDiscount,
                    discount: action.data.offer.discount,
                    providers: action.data.offer.providers,
                    withoutPrices: action.data.offer.withoutPrices
                }
                action.data.preOffer = preOffer;
                action.data.commonOffer = commonOffer;

                if (action.data.offer.isAfterAssessment) {
                    let postOffer: IPostOfferConfig = {
                        cancelOptions: action.data.offer.cancelOptions,
                        requireConfirmation: action.data.offer.requireConfirmation,
                        ui: action.data.offer.ui,
                        modules: [],
                        hideDependency: false
                    }
                    action.data.postOffer = postOffer;

                }
                action.data.offer = undefined;
            }
            intermediateState = action.data
            intermediateState = { ...intermediateState, channel: action.channel }

            // BEGIN Convert old case type ('number') Diagnostics module with mode Piceasoft to type {id: number, grade?: string, options?: object}
            let diagnosticsPiceaModules = intermediateState.assessment?.modules?.filter(i => i.type === Inspections.Diagnostics)?.filter(i => (i.config as IDiagnosticsConfig).mode === DiagnosticsModes.Piceasoft)
            let diagnosticsCaseAsNumber = false;
            if (diagnosticsPiceaModules && diagnosticsPiceaModules.length > 0) {
                diagnosticsCaseAsNumber = diagnosticsPiceaModules.find(i => ((i.config as IDiagnosticsConfig).config as IDiagnosticsPiceaConfig).tests.find(i => i.sets.find(i => i.cases.find(i => typeof i === 'number')))) ? true : false;
            }

            if (diagnosticsCaseAsNumber)
                intermediateState = {
                    ...intermediateState,
                    assessment: {
                        ...intermediateState?.assessment,
                        modules: intermediateState?.assessment?.modules?.map(i => {
                            const isDiagnostics = i.type === Inspections.Diagnostics
                            if (isDiagnostics) {
                                const isDiagnosticsPicea = (i.config as IDiagnosticsConfig)?.mode === DiagnosticsModes.Piceasoft
                                const isDiagnosticsPiceaMobile = (i.config as IDiagnosticsConfig)?.mode === DiagnosticsModes.PiceaMobile
                                if (isDiagnosticsPicea) {
                                    const isCasesAsNumber = ((i.config as IDiagnosticsConfig)?.config as IDiagnosticsPiceaConfig)?.tests.find(t => t.sets.find(s => s.cases.find(i => typeof i === 'number')))
                                    if (isCasesAsNumber) {
                                        return ({
                                            ...i,
                                            config: {
                                                ...i.config,
                                                config: {
                                                    ...(i.config as IDiagnosticsConfig).config,
                                                    tests: ((i.config as IDiagnosticsConfig).config as IDiagnosticsPiceaConfig)?.tests.map(t => ({
                                                        ...t,
                                                        sets: t.sets?.map(s => ({
                                                            ...s,
                                                            cases: s.cases?.map(i => ({ id: i as unknown as number }))
                                                        }))
                                                    }))
                                                } as IDiagnosticsPiceaConfig
                                            } as IDiagnosticsConfig
                                        })

                                    }
                                }
                                if (isDiagnosticsPiceaMobile) {
                                    const isCasesAsNumber = ((i.config as IDiagnosticsConfig)?.config as IDiagnosticsPiceaMobileConfig)?.tests.find(t => t.sets.find(s => s.cases.find(i => typeof i === 'number')))
                                    if (isCasesAsNumber) {
                                        return ({
                                            ...i,
                                            config: {
                                                ...i.config,
                                                config: {
                                                    ...(i.config as IDiagnosticsConfig).config,
                                                    tests: ((i.config as IDiagnosticsConfig).config as IDiagnosticsPiceaMobileConfig)?.tests.map(t => ({
                                                        ...t,
                                                        sets: t.sets?.map(s => ({
                                                            ...s,
                                                            cases: s.cases?.map(i => ({ id: i as unknown as number }))
                                                        }))
                                                    }))
                                                } as IDiagnosticsPiceaMobileConfig
                                            } as IDiagnosticsConfig
                                        })
                                    }
                                }
                            }
                            return i
                        })
                    }
                } as IWorkflow
            // END Convert old case type ('number') Diagnostics module with mode Piceasoft to type {id: number, grade?: string, options?: object}

            // BEGIN Convert old check type ('number') Verify module with mode Piceasoft to type {id: number, grade?: string, errors?: }
            let verifyPiceaModules = intermediateState?.assessment?.modules?.filter(module => module.type === Inspections.Software)
            let verifyCheckAsNumber = false
            if (verifyPiceaModules && verifyPiceaModules.length > 0) {
                verifyCheckAsNumber = verifyPiceaModules.find(module => ((module.config as ISoftwareConfig).config as ISoftwarePiceaConfig).checks?.find((check) => typeof check === 'number')) ? true : false
            }
            if (verifyCheckAsNumber) {
                intermediateState = {
                    ...intermediateState,
                    assessment: {
                        ...intermediateState.assessment,
                        modules: intermediateState.assessment?.modules?.map((module) => {
                            const isSoftaware = module.type === Inspections.Software
                            if (isSoftaware) {
                                const isSoftawarePicea = (module.config as ISoftwareConfig)?.mode === SoftwareModes.Piceasoft || (module.config as ISoftwareConfig)?.mode === SoftwareModes.PiceaMobile
                                if (isSoftawarePicea) {
                                    const isCheckAsNumber = ((module.config as ISoftwareConfig)?.config as ISoftwarePiceaConfig)?.checks.find((check) => typeof check === 'number') ? true : false
                                    if (isCheckAsNumber) {
                                        return ({
                                            ...module,
                                            config: {
                                                ...module.config,
                                                config: {
                                                    ...(module.config as ISoftwareConfig).config,
                                                    checks: ((module.config as ISoftwareConfig).config as ISoftwarePiceaConfig)?.checks.map((check) => ({ id: check as unknown as number }))
                                                } as ISoftwarePiceaConfig

                                            } as ISoftwareConfig
                                        })
                                    }
                                }
                            }
                            return module
                        })
                    }
                } as IWorkflow
            }
            // END Convert old check type ('number') Verify module with mode Piceasoft to type {id: number, grade?: string, errors}

            break;

        case 'CONFIGURATOR_VERSION_UPDATE':
            intermediateState = { ...intermediateState, version: action.version }
            break;
        case 'CONFIGURATOR_PARAMETERS_IMEI_LOCKOUT':
            intermediateState = { ...intermediateState, imeiLockout: action.config }
            break;
        case 'CONFIGURATOR_COMMON_OFFER_UPDATE':
            intermediateState = { ...intermediateState, commonOffer: action.commonOffer }
            break;
        case 'CONFIGURATOR_PRE_OFFER_UPDATE':
            intermediateState = { ...intermediateState, preOffer: action.preOffer }
            break;
        case 'CONFIGURATOR_POST_OFFER_UPDATE':
            intermediateState = { ...intermediateState, postOffer: action.postOffer }
            if( intermediateState.postOffer && !intermediateState.postOffer.modules)
                intermediateState.postOffer.modules = [];
            break;
        case 'CONFIGURATOR_RESULT_UPDATE':
            intermediateState = { ...intermediateState, result: action.result }
            break;
        case 'CONFIGURATOR_CONTRACT_UPDATE':
            intermediateState = { ...intermediateState, contract: action.contract }
            break;
        case 'CONFIGURATOR_CONTROL_UPDATE':
            intermediateState = { ...intermediateState, control: action.control }
            break;
        case 'CONFIGURATOR_STAGE_INSPECTION_MODULES_UPDATE':
            switch (action.stage) {
                case ProcessStages.Control:
                    intermediateState = {
                        ...intermediateState, control: {
                            ...intermediateState.control,
                            hideDependency: intermediateState.assessment?.hideDependency ?? false,
                            modules: action.modules ?? [],
                        }
                    }
                    break;
                case ProcessStages.Assessment:
                    intermediateState = {
                        ...intermediateState, assessment: {
                            ...intermediateState.assessment,
                            hideDependency: intermediateState.assessment?.hideDependency ?? false,
                            modules: action.modules ?? [],
                            inspections: intermediateState.assessment?.inspections ?? {}
                        }
                    }
                    break;
                case ProcessStages.PostOffer:
                    intermediateState = {
                        ...intermediateState, postOffer: {
                            ...intermediateState.postOffer,
                            modules: action.modules ?? [],
                            hideDependency: intermediateState.postOffer?.hideDependency ?? false,
                        }
                    }
                    break;
            }
            break;
        case 'CONFIGURATOR_RESOURCES_UPDATE':
            intermediateState = { ...intermediateState, resources: action.resources }
            break;
        case 'CONFIGURATOR_LANDING_UPDATE':
            intermediateState = { ...intermediateState, landing: action.landing }
            break;
        case 'CONFIGURATOR_SELF_SERVICE_UPDATE':
            intermediateState = { ...intermediateState, selfService: action.selfService }
            break;
        case 'CONFIGURATOR_GRADES_UPDATE':
            intermediateState = { ...intermediateState, grades: action.data ?? [] }
            break;
        case 'CONFIGURATOR_PREVIOUS_GRADES_UPDATE':
            intermediateState = { ...intermediateState, temporaryData: { ...intermediateState?.temporaryData, previousGrades: action.data } }
            break;
        case 'CONFIGURATOR_GRADES_CATEGORIES_UPDATE':
            intermediateState.gradesCategories = action.data ?? []
            break;
        case 'CONFIGURATOR_COMMON_OFFER_PROMO_PROVIDER_UPDATE':
            intermediateState = { ...intermediateState, temporaryData: { ...intermediateState?.temporaryData, promotions: action.data }}
            break;
        case 'CONFIGURATOR_COMMON_OFFER_PROVIDER_UPDATE':
            intermediateState = { ...intermediateState, temporaryData: { ...intermediateState?.temporaryData, catalogs: action.data }}
            break;
        case 'CONFIGURATOR_PREVIOUS_GRADES_CATEGORIES_UPDATE':
            intermediateState = { ...intermediateState, temporaryData: { ...intermediateState?.temporaryData, previousGradesCategories: action.data } }
            break;
        case 'CONFIGURATOR_CONTENT_TRANSFER_UPDATE':
            intermediateState = { ...intermediateState, contentTransfer: action.contentTransfer }
            break;
        case 'CONFIGURATOR_IDENTIFICATION_UPDATE':
            intermediateState = { ...intermediateState, identification: action.identification }
            break;
        case 'CONFIGURATOR_IDENTIFICATION_ITEMS_UPDATE':
            intermediateState = { ...intermediateState, identification: { ...intermediateState.identification, items: action.items } }
            break;
        case 'CONFIGURATOR_DEVICE_TYPES_UPDATE':
            intermediateState = { ...intermediateState, deviceTypes: action.data }
            break;
        case 'CONFIGURATOR_ASSESSMENT_UPDATE':
            intermediateState = { ...intermediateState, assessment: action.assessment }
            break;
        case 'CONFIGURATOR_ASSESSMENT_INSPECTION_MODULES_UPDATE':
            intermediateState = {
                ...intermediateState, assessment: {
                    ...intermediateState.assessment,
                    hideDependency: intermediateState.assessment?.hideDependency ?? false,
                    modules: action.modules ?? [],
                    inspections: intermediateState.assessment?.inspections ?? {}
                }
            }
            break;
        case 'CONFIGURATOR_TOGGLE_RESOURCES':
            if (intermediateState.resources?.disabled) {
                intermediateState = { ...intermediateState, resources: { ...intermediateState.resources, disabled: undefined } }
                break;
            }
            if (!intermediateState.resources) {
                intermediateState = { ...intermediateState, resources: { items: [] } }
                break;
            }
            intermediateState = { ...intermediateState, resources: { ...intermediateState.resources, disabled: true } }
            break;
        case 'CONFIGURATOR_TOGGLE_LANDING':
                if (intermediateState.landing?.disabled) {
                    intermediateState = { ...intermediateState, landing: { ...intermediateState.landing, disabled: undefined } }
                    break;
                }
                if (!intermediateState.landing) {
                    intermediateState = { ...intermediateState, landing: { } }
                    break;
                }
                intermediateState = { ...intermediateState, landing: { ...intermediateState.landing, disabled: true } }
                break;
        case 'CONFIGURATOR_TOGGLE_IS_CONTROL_TRANSACTION':
            if (intermediateState.isControlTransaction) {
                intermediateState = { ...intermediateState, isControlTransaction: undefined }
                break;
            }
            intermediateState = { ...intermediateState, isControlTransaction: true }
            break;
        case 'CONFIGURATOR_SET_GRADES_DELIMITER':
            intermediateState = { ...intermediateState, gradesDelimiter: action.data }
            break;
        case 'CONFIGURATOR_TOGGLE_USE_GRADES_CATEGORIES':

            let newState = {
                ...intermediateState,
                grades: [],
                gradesCategories: undefined,
                assessment: intermediateState.assessment && {
                    ...intermediateState.assessment,
                    modules: intermediateState.assessment?.modules?.map(m => cleanModuleFromGrades(m)) ?? [],
                    hideDependency: intermediateState.assessment?.hideDependency ?? false,
                    inspections: {}
                },

            }
            if (intermediateState.useGradesCategories) {
                intermediateState = { ...newState, useGradesCategories: undefined, gradesDelimiter: undefined, useDiscountCatalogs: undefined }
                break;
            }
            intermediateState = { ...newState, useGradesCategories: true }
            break;
        case 'CONFIGURATOR_TOGGLE_USE_DISCOUNT_CATALOGS': {
            let newState = {
                ...intermediateState,
                commonOffer: {...intermediateState.commonOffer, providers:[]}
            }
            if (intermediateState.useDiscountCatalogs) {
                intermediateState = { ...newState, useDiscountCatalogs: undefined }
                break;
            }
            intermediateState = { ...newState, useDiscountCatalogs: true }
            break;
        }
        case 'CONFIGURATOR_TOGGLE_PRE_OFFER':
            if (intermediateState.preOffer?.disabled) {
                intermediateState = { ...intermediateState, preOffer: { ...intermediateState.preOffer, disabled: undefined } }
                break;
            }
            if (!intermediateState.preOffer) {
                intermediateState = { ...intermediateState, preOffer: {}, commonOffer: !intermediateState.commonOffer ? {} : intermediateState.commonOffer }
                if (action.channel && [Experience.Mobile, Experience.ECom].includes(action.channel)) {
                    intermediateState.preOffer = {
                        ...intermediateState.preOffer,
                        showPricesBeforeAssessment: true,
                        showBestPriceOnly: true
                    }
                }
                break;
            }
            intermediateState = { ...intermediateState, preOffer: { ...intermediateState.preOffer, disabled: true } }
            break;
        case 'CONFIGURATOR_TOGGLE_POST_OFFER':
            if (intermediateState.postOffer?.disabled) {
                intermediateState = { ...intermediateState, postOffer: { ...intermediateState.postOffer, disabled: undefined } }
                break;
            }
            if (!intermediateState.postOffer) {
                intermediateState = { ...intermediateState, postOffer: { modules: [], hideDependency: false}, commonOffer: !state?.commonOffer ? {} : state.commonOffer }
                break;
            }
            intermediateState = { ...intermediateState, postOffer: { ...intermediateState.postOffer, disabled: true } }
            if( intermediateState.postOffer && !intermediateState.postOffer.modules)
                intermediateState.postOffer.modules = [];
            break;
        case 'CONFIGURATOR_TOGGLE_ASSESSMENT':
            if (intermediateState.assessment?.disabled) {
                intermediateState = { ...intermediateState, assessment: { ...intermediateState.assessment, disabled: undefined } }
                break;
            }
            if (!intermediateState.assessment) {
                intermediateState = { ...intermediateState, assessment: { hideDependency: false, modules: [], inspections: {} } }
                break;
            }
            intermediateState = { ...intermediateState, assessment: { ...intermediateState.assessment, disabled: true } }
            break;
        case 'CONFIGURATOR_TOGGLE_CONTRACT':
            if (intermediateState.contract?.disabled) {
                intermediateState = { ...intermediateState, contract: { ...intermediateState.contract, disabled: undefined } }
                break;
            }
            if (!intermediateState.contract) {
                intermediateState = { ...intermediateState, contract: {} }
                break;
            }
            intermediateState = { ...intermediateState, contract: { ...intermediateState.contract, disabled: true } }
            break;
        case 'CONFIGURATOR_TOGGLE_CONTROL':
            if (intermediateState.control?.disabled) {
                intermediateState = { ...intermediateState, control: { ...intermediateState.control, disabled: undefined } }
                break;
            }
            if (!intermediateState.control) {
                intermediateState = { ...intermediateState, control: { modules: [], hideDependency: false } }
                break;
            }
            intermediateState = { ...intermediateState, control: { ...intermediateState.control, disabled: true } }
            break;
        case 'CONFIGURATOR_TOGGLE_RESULT':
            if (intermediateState.result?.disabled) {
                intermediateState = { ...intermediateState, result: { ...intermediateState.result, disabled: undefined } }
                break;
            }
            if (!intermediateState.result) {
                intermediateState = { ...intermediateState, result: {} }
                break;
            }
            intermediateState = { ...intermediateState, result: { ...intermediateState.result, disabled: true } }
            break;
        case 'CONFIGURATOR_IDENTIFICATION_RECEIVE_METHOD':
            break;
        case 'CONFIGURATOR_IDENTIFICATION_REMOVE_METHOD':
            break;
        case 'CONFIGURATOR_PROMO_DATA_UPDATE':
            intermediateState = { ...intermediateState, temporaryData: { ...intermediateState.temporaryData, promotions: action.promotions, catalogs: action.catalogs } }
            break;
        case 'CONFIGURATOR_TOGGLE_CONTENT_TRANSFER':
            if (intermediateState.contentTransfer?.disabled) {
                intermediateState = { ...intermediateState, contentTransfer: { ...intermediateState.contentTransfer, disabled: undefined } }
                break;
            }
            if (!intermediateState.contentTransfer) {
                intermediateState = { ...intermediateState, contentTransfer: {} }
                break;
            }
            intermediateState = { ...intermediateState, contentTransfer: { ...intermediateState.contentTransfer, disabled: true } }
            break;
    }
    const stages = getWorkflowStages(intermediateState)
    intermediateState = { ...intermediateState, stages: stages }
    return compulsoryValidation(intermediateState)
};

const cleanModuleFromGrades = (module: IInspectionModuleConfig<IInspectionConfig<IInspectionConfigUI>>): IInspectionModuleConfig<IInspectionConfig<IInspectionConfigUI>> => {
    console.log(true)
    const newModule = {
        ...module,
        config: {
            ...module.config,
            dependencyInspections: undefined,
            gradesCategory: undefined,
            skip: undefined
        }
    }
    switch (module.type) {
        case Inspections.Interview: return {
            ...module,
            config: {
                ...module.config,
                ...cleanInterviewConfigMode(module.config as IInterviewConfig)
            } as IInterviewConfig
        }
        case Inspections.Photographic: return {
            ...module,
            config: {
                ...module.config,
                autoApprove: undefined
            } as IPhotographicConfig
        }
        case Inspections.Diagnostics: return {
            ...module,
            config: {
                ...module.config,
                ...cleanDiagnosticsConfigMode(module.config as IDiagnosticsConfig)
            } as IDiagnosticsConfig
        }
        case Inspections.Software: return {
            ...module,
            config: {
                ...module.config,
                ...cleanSoftwareConfigMode(module.config as ISoftwareConfig)
            } as ISoftwareConfig
        }
        default: return module
    }
}

const cleanInterviewConfigMode = (config: IInterviewConfig): IInterviewConfig => {
    if ([InterviewModes.Sequence, InterviewModes.List].includes(config.mode)) {
        return {
            ...config,
            config: {
                ...config.config,
                questions: ((config.config) as IInterviewWithQuestionsConfig).questions?.map(q => ({
                    ...q,
                    answers: q.answers.map(a => ({
                        ...a,
                        grade: undefined
                    }))
                }))
            } as IInterviewWithQuestionsConfig
        }
    }
    return config
}

const cleanDiagnosticsConfigMode = (config: IDiagnosticsConfig): IDiagnosticsConfig => {
    const isDiagnosticsPiceaMobile = config?.mode === DiagnosticsModes.PiceaMobile
    const isDiagnosticsWebBased = config?.mode === DiagnosticsModes.WebBased
    if (isDiagnosticsPiceaMobile) {
        return {
            ...config,
            config: {
                ...config.config,
                sets: (config.config as IDiagnosticsPiceaMobileConfig).sets?.map(s => ({
                    ...s,
                    grade: ''
                })),
                tests: (config.config as IDiagnosticsPiceaMobileConfig).tests?.map(t => ({
                    ...t,
                    sets: t.sets.map(s => ({
                        ...s,
                        grade: ''
                    })),
                }))
            } as IDiagnosticsPiceaMobileConfig
        }
    }
    if( isDiagnosticsWebBased) {
        return {
            ...config,
            config: {
                ...config.config,
                sets: (config.config as IDiagnosticsWebBasedConfig).sets?.map(s => ({
                    ...s,
                    grade: ''
                })),
                tests: (config.config as IDiagnosticsWebBasedConfig).tests?.map(t => ({
                    ...t,
                    sets: t.sets.map(s => ({
                        ...s,
                        grade: ''
                    })),
                }))
            } as IDiagnosticsWebBasedConfig
        }
    }
    return {
        ...config,
        config: {
            ...config.config,
            sets: (config.config as IDiagnosticsPiceaConfig).sets?.map(s => ({
                ...s,
                grade: ''
            })),
            tests: (config.config as IDiagnosticsPiceaConfig).tests?.map(t => ({
                ...t,
                sets: t.sets.map(s => ({
                    ...s,
                    grade: ''
                })),
            }))
        } as IDiagnosticsPiceaConfig
    }
}

const cleanSoftwareConfigMode = (config: ISoftwareConfig): ISoftwareConfig => {
    return {
        ...config,
        config: {
            ...config.config,
            downGrade: undefined
        } as ISoftwarePiceaConfig
    }
}

export const getWorkflowStages = (w: IWorkflow): IProcessStageConfig[] | undefined => {
    let stages: IProcessStageConfig[] = []
    if (w.landing) {
        stages.push({ index: stages ? stages?.length : 0, type: ProcessStages.Landing })
    }
    if (w.resources) {
        stages.push({ index: stages ? stages?.length : 0, type: ProcessStages.Resources })
    }
    if (w.identification) {
        stages.push({ index: stages ? stages?.length : 0, type: ProcessStages.Identification })
    }
    if (w.selfService) {
        stages.push({ index: stages ? stages?.length : 0, type: ProcessStages.SelfService })
    }
    if (w.contentTransfer) {
        stages.push({ index: stages ? stages?.length : 0, type: ProcessStages.ContentTransfer })
    }
    if (w.preOffer) {
        stages.push({ index: stages ? stages?.length : 0, type: ProcessStages.PreOffer })
    }
    if (w.assessment) {
        stages.push({ index: stages ? stages?.length : 0, type: ProcessStages.Assessment })
    }
    if (w.postOffer) {
        stages.push({ index: stages ? stages?.length : 0, type: ProcessStages.PostOffer })
    }
    if (w.contract) {
        stages.push({ index: stages ? stages?.length : 0, type: ProcessStages.Contract })
    }
    if (w.control) {
        stages.push({ index: stages ? stages?.length : 0, type: ProcessStages.Control })
    }
    if (w.result) {
        stages.push({ index: stages ? stages?.length : 0, type: ProcessStages.Result })
    }
    return stages.length > 0 ? stages : undefined;
}
